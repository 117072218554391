import React, { useReducer } from "react";
import GenericJacket from "../../assets/GenericJacket.png";
import { useNavigate } from "react-router-dom";
import { NoItemCard } from "../general/misc/NoItemCard";
import { useState, useEffect } from "react";
import { IoCloseSharp, IoCloseCircleOutline } from "react-icons/io5";
import { BiCloset } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";

export const AdderCard = ({
	className = "",
	type = "",
	onClick = () => null,
}) => {
	return (
		<div
			className={`${className} flex text-gray-400  w-full h-full justify-center relative rounded-md px-4 py-2 items-center mb-2`}
		>
			<div
				onClick={onClick}
				className={`flex cursor-pointer border-dotted border-2 border-gray-400 items-center justify-center w-full ${
					type == "clothingPicker"
						? "h-20 sm:h-20 md:h-22 lg:h-22 xl:h-22 2xl:h-28"
						: type == "big"
						  ? "h-64 md:h-72 lg:h-80 xl:h-90"
						  : "h-32 sm:h-32 md:h-48 lg:h-64 xl:h-72 2xl:h-72"
				} rounded-md`}
			>
				<IoAdd size={32} />
			</div>
		</div>
	);
};
