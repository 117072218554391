import React, { useContext } from "react";
import { IoLogoLinkedin } from "react-icons/io5";
import { AppContext } from "../../../contexts/App";
import { Link } from "react-router-dom";

const year = new Date().getFullYear();

export const Footer = () => {
	const [state, dispatch] = useContext(AppContext);
	return (
		<footer
			className={`flex text-sm text-gray-700 font-light ${
				state.footer ? "" : "hidden"
			}`}
		>
			<div className="flex self-end border-t border-gray-400 w-full flex-col items-center py-1 md:py-2 md:px-8 md:items-start">
				<div className="flex flex-row items-center">
					<div
						className="cursor-pointer"
						onClick={() =>
							(window.location.href =
								"https://www.linkedin.com/company/outfitorg/")
						} // goofy ah hack because React scuffed xddd
					>
						<IoLogoLinkedin size={18} />
					</div>
					<div class="ml-2 font-bold text-blue-700 hover:text-blue-500">
						<Link to="/privacy">Privacy Policy</Link>
					</div>
				</div>
				<div>© {year} outfitorg.com</div>
			</div>
		</footer>
	);
};
