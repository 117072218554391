/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState } from "react";
import { CONFIG } from "../../config";
import { defaultOutfitDimensionGrid } from "../../contexts/Outfits/AddOutfit/AddOutfitReducer";
import { scaledLeftRight } from "../../helpers/cssHelper";
import { PieceSpacer } from "../general/misc/PieceSpacer";

const boxClass = `flex pointer-events-none cursor-pointer select-none rounded-md`;

const PieceSpacerWrapper = ({
	children,
	piece = null,
	type = "",
	noPosition = false,
	noSpacing = false,
	contentHidden = false,
	contentClassname = "",
}) => {
	const [opacity, setOpacity] = useState(false);
	return (
		<PieceSpacer
			type={type}
			widthScale={piece.widthScale}
			heightScale={piece.heightScale}
			topScale={noPosition ? 0 : piece.topScale}
			leftScale={noPosition ? 0 : piece.leftScale}
			zIndex={piece.zIndex}
			spacing={noSpacing ? 0 : piece.spacing}
			empty={piece.ClothingId == null}
			key={piece.position}
			className={`${boxClass}`}
		>
			{!contentHidden &&
				(piece.Clothing ? (
					<img
						onLoad={() => setOpacity(true)}
						className={`${contentClassname} ${
							opacity ? "opacity-100" : "opacity-0"
						} transition-opacity duration-300 object-contain pointer-events-none rounded-md w-full h-full`}
						src={`${CONFIG.IMAGE_URL}${piece.Clothing.imageName}`}
					/>
				) : (
					""
				))}
			{children}
		</PieceSpacer>
	);
};

export const OutfitPiece = ({ piece = null, type = "", position = 1 }) => {
	return piece ? (
		<PieceSpacerWrapper
			key={position}
			piece={piece}
			type={type}
			contentHidden={piece.split}
		>
			{Boolean(piece.split) && (
				<div>
					<div
						css={scaledLeftRight(type, -piece.split, true)}
						className="absolute top-0 w-1/2 h-full overflow-hidden"
					>
						<PieceSpacerWrapper
							type={type}
							piece={piece}
							noPosition={true}
							noSpacing={true}
						/>
					</div>
					<div
						css={scaledLeftRight(type, -piece.split, false)}
						className="absolute top-0 w-1/2 h-full overflow-hidden"
					>
						<PieceSpacerWrapper
							type={type}
							piece={piece}
							noPosition={true}
							noSpacing={true}
							contentClassname={"relative top-0 -left-1/2"}
						/>
					</div>
				</div>
			)}
		</PieceSpacerWrapper>
	) : (
		<PieceSpacer
			type={type}
			widthScale={defaultOutfitDimensionGrid[position].widthScale}
		/>
	);
};
