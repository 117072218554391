import React from "react";
import { useState, useEffect, memo } from "react";
import { PaginationControls } from "./PaginationControls";
import { GridDisplayComponent } from "./GridDisplayComponent";
import { ViewMenu } from "./ViewMenu";
import { gridTypes } from "../../../helpers/gridTypes";
import { gridClasses } from "../../../helpers/gridTypes";
import { FilterComponent } from "../filter/FilterComponent";
import { addParam, removeParam } from "../../../helpers/searchParamsHelper";
import { useSearchParams } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import { requestHandler } from "../../../helpers/requests";
import { Spinner } from "../universal/Spinner";
import { NoItemCard } from "../misc/NoItemCard";
import { ViewerContext } from "../../../contexts/Generic";

export const ItemsView = memo(
	({
		context = ViewerContext, // needed for context variables/reducer functions
		local = false, // false = use context variables for filter/sort/page, otherwise use searchParams
		type = "clothing", // diff. types have diff. grid rows/columns & items per page
		fetchItemsFunc = requestHandler.getClothing, // used to get items from backend
		cost = () => {
			// defines cost string
			return "";
		},
		add = "", // link to add new item
		card = () => {
			// function which takes in an item and returns a card
			return <NoItemCard />;
		},
		onLoad = () => {}, // custom function to run once items are finished loading - by default does nothing
		excludeCategoryFilter = false,
		includeMetricTypes = false,
		noFilters = false,
		fetchReqItems = null, // additional items to add to fetch requests
		className = "",
		itemNames = { single: "item", plural: "items" },
		searchPlaceholder = "Search...",
	}) => {
		const [contextState, dispatch] = React.useContext(context);
		useErrorHandler(contextState.error);
		const [searchParams, setParams] = useSearchParams();

		const search = local ? contextState.search : searchParams.get("search");
		const category = local
			? contextState.category
			: searchParams.get("category");
		const sort = local ? contextState.sort : searchParams.get("sort");
		const owned = local ? contextState.owned : searchParams.get("owned");

		const [localParams, setLocalParams] = useState({
			search: search,
			category: category,
			owned: owned,
		});

		/* Determines what items are to be displayed */
		const allItems = contextState.allItems;

		/* Variables for pagination */
		const curPage = local
			? contextState.page
			: searchParams.get("page")
			  ? parseInt(searchParams.get("page"))
			  : 1;

		useEffect(() => {
			/* Set options */

			dispatch({ type: "set_type", value: type });
			if (local) {
				dispatch({ type: "set_local" });
			}
			if (excludeCategoryFilter) {
				dispatch({ type: "set_excludeCategoryFilter" });
			}
			if (includeMetricTypes) {
				dispatch({ type: "set_includeMetricTypes" });
			}
		}, []);

		useEffect(() => {
			/* Get categories from db */
			requestHandler.getCategories().then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							dispatch({
								type: "set_categories",
								categories: data.categories,
							});
						});
					} else {
						dispatch({ type: "set_error", error: response });
					}
				},
				(error) => dispatch({ type: "set_error", error: error })
			);
		}, []);

		useEffect(() => {
			const formData = new FormData();
			formData.append("itemsPerPage", gridTypes[type].itemsPerPage);

			if (local) {
				if (contextState.category) {
					formData.append("category", contextState.category);
				}
				if (contextState.search != "") {
					formData.append("search", contextState.search);
				}
				if (contextState.sort) {
					formData.append("sort", contextState.sort);
				}
				if (contextState.owned) {
					formData.append("owned", contextState.owned);
				}
				formData.append("page", contextState.page);
			} else {
				searchParams.forEach((value, key) =>
					formData.append(key, value)
				);
			}

			if (fetchReqItems) {
				// add optional request options (mainly for lookbooks)
				Object.entries(fetchReqItems).forEach(([key, value]) => {
					formData.append(key, value);
				});
			}

			if (
				localParams.search != search ||
				localParams.category != category ||
				localParams.owned != owned
			) {
				local
					? dispatch({ type: "set_page", page: 1 })
					: setParams(addParam(searchParams, "page", 1));
				setLocalParams({
					category: category,
					search: search,
					owned: owned,
				});
			} else {
				fetchItems(formData);
			}
		}, [
			curPage,
			sort,
			category,
			search,
			owned,
			localParams,
			fetchReqItems,
		]);

		const fetchItems = (formData) => {
			fetchItemsFunc(formData).then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							const items = data.items;
							const count = data.count;
							const cost = data.cost;
							const totalPages = data.totalPages;
							dispatch({ type: "set_all_items", items: items });
							dispatch({ type: "set_count", count: count });
							dispatch({ type: "set_cost", cost: cost });
							dispatch({
								type: "set_totalPages",
								totalPages: totalPages,
							});
							let localStorageFields;
							try {
								localStorageFields = JSON.parse(
									localStorage.getItem("displayFields")
								);
								console.log(localStorageFields);
								if (
									localStorageFields &&
									localStorageFields.length
								) {
									dispatch({
										type: "set_displayFields",
										fields: localStorageFields,
									});
								}
							} catch (err) {
								console.log("No stored fields");
							}

							let displayFields;
							if (localStorageFields) {
								displayFields = localStorageFields;
							} else {
								displayFields = contextState.displayFields;
							}

							if (includeMetricTypes && sort) {
								if (
									!displayFields.includes(sort.split("-")[0])
								) {
									let newFields = [
										...displayFields,
										sort.split("-")[0],
									];
									dispatch({
										type: "set_displayFields",
										fields: newFields,
									});
									localStorage.setItem(
										"displayFields",
										JSON.stringify(newFields)
									);
								}
							}

							if (!local) {
								window.scrollTo(0, 0); // for UX
							}
							onLoad();
						});
					} else {
						dispatch({ type: "set_error", error: response });
					}
				},
				(error) => dispatch({ type: "set_error", error: error })
			);
		};

		const gridClass = gridTypes[type].gridClass;

		return !contextState.allItems ||
			!contextState.categories ||
			local == null ||
			!type ? (
			<Spinner />
		) : (
			<div
				className={`${className} flex flex-col flex-grow w-full relative`}
			>
				<FilterComponent context={context} />

				<ViewMenu
					context={context}
					allItems={allItems}
					cost={cost}
					add={add}
					noFilters={noFilters}
					itemNames={itemNames}
					searchPlaceholder={searchPlaceholder}
				/>

				<GridDisplayComponent
					gridClass={gridClass}
					type={type}
					items={allItems}
					itemNamePlural={itemNames.plural}
					displayFields={contextState.displayFields}
					card={card}
					add={add}
				/>

				{curPage <= contextState.totalPages &&
					contextState.totalPages != 1 && (
						<PaginationControls context={context} />
					)}
			</div>
		);
	}
);
