import React from "react";
import { Container } from "../components/general/universal/Container";
import { EditClothingComponent } from "../components/clothing/EditClothingComponent";
import { ClothingProvider } from "../contexts/Clothing";

const EditClothing = () => {
	return (
		<ClothingProvider>
			<Container showFooter={false}>
				<EditClothingComponent />
			</Container>
		</ClothingProvider>
	);
};

export default EditClothing;
