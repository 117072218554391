import { ClothingCard } from "./ClothingCard";
import { ItemsView } from "../general/itemsview/ItemsView";
import { useEffect, useContext } from "react";
import { ViewerContext } from "../../contexts/Generic";
import { ViewerProvider } from "../../contexts/Generic";
import { NoItemCard } from "../general/misc/NoItemCard";
import { memo } from "react";

export const ViewClothingComponent = memo(
	({
		local = false,
		type = "clothing",
		onCardClick = null,
		includeMetricTypes = true,
		add = "",
		cardFunc = (piece, displayFields) => {
			return (
				<ClothingCard
					type={type}
					onCardClick={onCardClick}
					key={piece.id}
					piece={piece}
					displayFields={displayFields}
					cardCheck={cardCheck}
					cardCheckFunc={cardCheckFunc}
				/>
			);
		},
		cardCheck = (piece) => {
			return false;
		},
		cardCheckFunc = () => {},
		onLoad = () => {},
		className = "",
	}) => {
		const costFunc = (cost) => {
			return `, Total Value: $${
				cost ? parseFloat(cost).toFixed(2) : "0.00"
			}`;
		};

		return (
			<ViewerProvider>
				<ItemsView
					local={local}
					className={className}
					cost={costFunc}
					card={cardFunc}
					add={add}
					includeMetricTypes={includeMetricTypes}
					type={type}
					itemNames={{ single: "piece", plural: "pieces" }}
					searchPlaceholder="Search by name or brand..."
					onLoad={onLoad}
				/>
			</ViewerProvider>
		);
	}
);
