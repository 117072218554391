/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { PieceAdder } from "./PieceAdder";
import { memo, useContext } from "react";
import { Slider } from "@mui/material";
import { AddOutfitContext } from "../../../../contexts/Outfits/AddOutfit";
import { PieceSpacer } from "../../../general/misc/PieceSpacer";
import { cardHeightFunc } from "../../../../helpers/cssHelper";
import { PieceAdders } from "./PieceAdders";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export const PieceAdderGrid = memo(
	({ className = "", defaultOutfitPieces = null, zoom = 0 }) => {
		return (
			<div
				className={`${className} flex flex-col rounded-md items-center justify-center`}
			>
				<div
					css={cardHeightFunc("pieceAdder", zoom)}
					className={`flex items-center justify-center overflow-hidden border border-gray-400 rounded-md `}
				>
					<PieceAdders defaultOutfitPieces={defaultOutfitPieces} />
				</div>

				{/*<div className={`flex pt-2 font-light text-md justify-center `}>
        {outfitState.name ? (
          outfitState.name
        ) : (
          <span className="text-gray-400">Outfit Name</span>
        )}
        </div>*/}
			</div>
		);
	}
);

export const PieceAdderGridWrapper = memo(({ className = "" }) => {
	const [outfitState, dispatch] = useContext(AddOutfitContext);
	return <PieceAdderGrid className={className} zoom={outfitState.zoom} />;
});
