import React from "react";
import { Container } from "../components/general/universal/Container";
import { ViewLookbooksComponent } from "../components/lookbooks/ViewLookbooksComponent";
import { LookbookViewProvider } from "../contexts/Lookbooks/ViewLookbook";
import { LookbookViewContext } from "../contexts/Lookbooks/ViewLookbook";
import { defaultPadding } from "../helpers/classNames";

const ViewLookbooks = () => {
	return (
		<Container>
			<ViewLookbooksComponent type={"lookbooks"} add={"/addLookbook"} />
		</Container>
	);
};

export default ViewLookbooks;
