import update from "immutability-helper";

export const LookbookViewReducer = (state, action) => {
	switch (action.type) {
		/* Generic */
		case "set_all_items":
			return {
				...state,
				allItems: action.items,
			};
		case "set_categories":
			return {
				...state,
				categories: action.categories,
			};
		case "set_showFilter":
			return {
				...state,
				showFilter: !state.showFilter,
			};
		case "set_error": {
			return {
				...state,
				error: action.error,
			};
		}
		case "set_loading": {
			return {
				...state,
				loading: action.loading,
			};
		}
		case "set_count": {
			return {
				...state,
				count: action.count,
			};
		}
		case "set_cost": {
			return {
				...state,
				cost: parseFloat(action.cost),
			};
		}
		case "set_itemsPerPage": {
			return {
				...state,
				itemsPerPage: action.itemsPerPage,
			};
		}

		case "set_totalPages": {
			return {
				...state,
				totalPages: action.totalPages,
			};
		}

		case "set_local": {
			return {
				...state,
				local: true,
			};
		}

		case "set_local_default": {
			return {
				...state,
				local: false,
			};
		}

		case "set_category": {
			return {
				...state,
				category: action.category,
			};
		}

		case "remove_category": {
			return {
				...state,
				category: null,
			};
		}

		case "set_sort": {
			return {
				...state,
				sort: action.sort,
			};
		}

		case "set_search": {
			return {
				...state,
				search: action.search,
			};
		}

		case "set_page": {
			return {
				...state,
				page: action.page,
			};
		}

		case "reset": {
			return {
				...state,
				category: null,
				search: "",
				sort: null,
				page: 1,
			};
		}

		/* Add Lookbook Specific */
		case "add_outfit": {
			return update(state, { outfits: { $push: [action.outfit] } });
		}
		case "remove_outfit": {
			var index = state.outfits.findIndex(
				(lookbookOutfit) => lookbookOutfit.id == action.outfit.id
			);
			if (index == undefined) {
				return;
			} else {
				return update(state, { outfits: { $splice: [[index, 1]] } });
			}
		}

		default:
			return state;
	}
};

export const initialState = {
	/* Generic */
	allItems: null,
	categories: null,
	showFilter: false,
	error: null,
	loading: false,
	count: 0,
	cost: 0,
	itemsPerPage: 4,
	totalPages: 0,
	defaultSort: "cost-desc",
	sort: null,
	search: "",
	category: null,
	page: 1,
	local: null,

	/* View Lookbook Specific */
	defaultSort: "name-asc",
	excludeCategoryFilter: true,
	excludeCostSort: true,
};
