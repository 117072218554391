/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect } from "react";
import { useState, useContext, memo } from "react";
import { IoAdd } from "react-icons/io5";
import { CONFIG } from "../../../../config";
import { AddOutfitContext } from "../../../../contexts/Outfits/AddOutfit";
import {
	cssFunc,
	scaledHeight,
	scaledLeftRight,
} from "../../../../helpers/cssHelper";
import { PieceSpacer } from "../../../general/misc/PieceSpacer";
import { defaultOutfitDimensionGrid } from "../../../../contexts/Outfits/AddOutfit/AddOutfitReducer";

const PieceSpacerWrapper = ({
	children,
	beforeContent,
	preview = false,
	piece = { id: -69 },
	position = 1,
	dimensions = defaultOutfitDimensionGrid[1],
	dispatch = () => null,
	zoom = 0,
	contentHidden = false,
	noPosition = false,
	className = "",
	contentClassName = "",
}) => {
	const onImageLoad = (e) => {
		dispatch({
			type: "set_ratio",
			position: position,
			value: e.target.naturalWidth / e.target.naturalHeight,
		});
		setOpacity(true);
		setFirstLoad(false);
	};

	const [opacity, setOpacity] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);

	return (
		<PieceSpacer
			type="pieceAdder"
			widthScale={dimensions.widthScale}
			heightScale={
				firstLoad && dimensions.heightScale
					? dimensions.heightScale
					: null // use
			}
			topScale={noPosition ? 0 : dimensions.topScale}
			leftScale={noPosition ? 0 : dimensions.leftScale}
			zIndex={dimensions.zIndex}
			empty={piece.id < 0}
			key={position}
			zoom={zoom}
			className={className}
		>
			{beforeContent}
			<div
				className={`${contentHidden ? "opacity-0" : ""} w-full h-full`}
			>
				{piece.id > 0 ? (
					<img
						className={`${contentClassName} ${
							opacity ? "opacity-100" : "opacity-0"
						} transition-opacity duration-300 object-contain pointer-events-none select-none rounded-md w-full h-full`}
						src={
							piece.static
								? piece.imageName
								: `${CONFIG.IMAGE_URL}${piece.imageName}` // piece.static is used to pass static images to the pieceAdderGrid for the homepage
						}
						onLoad={onImageLoad}
					/>
				) : (
					<div
						className={`${contentClassName} flex w-full h-full ${
							preview ? "hidden" : ""
						} justify-center items-center`}
					>
						<IoAdd size={18} />
					</div>
				)}
			</div>
			{children}
		</PieceSpacer>
	);
};

export const PieceAdder = memo(
	({
		position = 1,
		positionState = 1,
		preview = false,
		piece = { id: -69 },
		dimensions = defaultOutfitDimensionGrid[1],
		dispatch = () => null,
		zoom = 0,
		splitEnabled = false,
		className = "",
	}) => {
		const boxClass = ` border ${
			preview ? "border-transparent" : ""
		} cursor-pointer select-none ${
			dimensions.spacing > 0 ? "rounded-b-md" : "rounded-md"
		}`;
		const boxSelectedClass =
			"pointer-events-none border-green-700 text-green-700";
		return (
			<div className={className}>
				{dimensions.spacing > 0 && (
					<div
						onClick={() =>
							dispatch({
								type: "set_position",
								position: position,
							})
						}
						css={scaledHeight(
							"pieceAdder",
							dimensions.spacing,
							zoom,
							dimensions.topScale,
							dimensions.leftScale
						)}
						className={` ${
							positionState == position
								? "bg-blue-700 bg-opacity-60"
								: "bg-gray-400 bg-opacity-50"
						}  ${preview ? "opacity-0" : ""}  ${
							dimensions.spacing < 3
								? "rounded-none"
								: "rounded-t-md"
						} cursor-pointer relative`}
					></div>
				)}
				<PieceSpacerWrapper
					preview={preview}
					position={position}
					dispatch={dispatch}
					zoom={zoom}
					piece={piece}
					dimensions={dimensions}
					contentHidden={splitEnabled}
					beforeContent={
						<div
							onClick={() =>
								dispatch({
									type: "set_position",
									position: position,
								})
							}
							className={`absolute w-full h-full z-10 border  ${boxClass} ${
								position == positionState && !preview
									? boxSelectedClass
									: !preview
									  ? "border-gray-400"
									  : ""
							} ${
								dimensions.spacing > 0
									? "rounded-x-md rounded-b-md"
									: "rounded-md"
							}`}
						/>
					}
				>
					{splitEnabled && (
						<div>
							<div
								css={scaledLeftRight(
									"pieceAdder",
									-dimensions.split,
									true,
									zoom
								)}
								className="absolute top-0 w-1/2 h-full overflow-hidden"
							>
								<PieceSpacerWrapper
									preview={preview}
									position={position}
									piece={piece}
									dispatch={dispatch}
									zoom={zoom}
									dimensions={dimensions}
									noPosition={true}
									className={`${
										position == positionState && !preview
											? "text-green-700"
											: ""
									}`}
								/>
							</div>
							<div
								css={scaledLeftRight(
									"pieceAdder",
									-dimensions.split,
									false,
									zoom
								)}
								className="absolute top-0 w-1/2 h-full overflow-hidden"
							>
								<PieceSpacerWrapper
									preview={preview}
									position={position}
									dispatch={dispatch}
									piece={piece}
									zoom={zoom}
									dimensions={dimensions}
									noPosition={true}
									className={`${
										position == positionState && !preview
											? "text-green-700"
											: ""
									}`}
									contentClassName={
										"relative top-0 -left-1/2"
									}
								/>
							</div>
						</div>
					)}
				</PieceSpacerWrapper>
			</div>
		);
	}
);
