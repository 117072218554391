import React from "react";
import { Container } from "../components/general/universal/Container";
import { NewUserTutorialComponent } from "../components/tutorials/NewUserTutorialComponent";

const NewUserTutorial = () => {
	return (
		<Container absolute={true}>
			<NewUserTutorialComponent />
		</Container>
	);
};

export default NewUserTutorial;
