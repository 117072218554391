import React, { useState, useContext, memo } from "react";
import { ConfirmationModal } from "../../general/misc/ConfirmationModal";
import { ColorButton } from "../../general/misc/ColorButton";
import { Sliders } from "./Sliders";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";
import { ZMenu } from "./ZMenu";

export const SizingMenu = memo(
	({ dispatch = () => null, setClearPieceModal = null }) => {
		return (
			<div className="flex w-full flex-col">
				<Sliders />
				<ZMenu />
				<div className="flex flex-row items-center w-full px-2 lg:px-0 bg-white h-14 lg:h-auto lg:mt-4 self-center">
					<div className="flex w-full gap-2 h-10">
						<ColorButton
							onClick={() =>
								dispatch({
									type: "reset_size",
								})
							}
							className="w-full text-sm lg:text-xs"
							label="Reset Size"
						/>
						<ColorButton
							onClick={() =>
								dispatch({
									type: "reset_spacing",
								})
							}
							className="w-full text-sm lg:text-xs"
							label="Reset Spacing"
						/>
						<ColorButton
							onClick={() =>
								dispatch({
									type: "reset_cords",
								})
							}
							className="w-full text-sm lg:text-xs"
							label="Reset Top/Left"
						/>
						<ColorButton
							onClick={
								setClearPieceModal
									? () => setClearPieceModal(true)
									: () => dispatch({ type: "clear_piece" })
							}
							className="w-full text-sm lg:text-xs"
							type="red"
							label="Clear Piece"
						/>
					</div>
				</div>
			</div>
		);
	}
);

export const SizingMenuWrapper = memo(({ setClearPieceModal = null }) => {
	const [state, dispatch] = useContext(AddOutfitContext);
	return (
		<SizingMenu
			setClearPieceModal={setClearPieceModal}
			dispatch={dispatch}
		/>
	);
});
