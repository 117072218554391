import React from "react";
import { LookbookReducer, initialState } from "./LookbookReducer";

export const LookbookContext = React.createContext({
	state: initialState,
	dispatch: () => null,
});

export const LookbookProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(LookbookReducer, initialState);

	return (
		<LookbookContext.Provider value={[state, dispatch]}>
			{children}
		</LookbookContext.Provider>
	);
};
