import React from "react";
import { Container } from "../components/general/universal/Container";
import { AddWearsComponent } from "../components/clothingAnalytics/AddWearsComponent";

const AddWears = () => {
	return (
		<Container>
			<AddWearsComponent />
		</Container>
	);
};

export default AddWears;
