export const ViewOutfitsReducer = (state, action) => {
	switch (action.type) {
		case "set_all_outfits":
			return {
				...state,
				allOutfits: action.allOutfits,
			};
		case "set_categories":
			return {
				...state,
				categories: action.categories,
			};
		case "set_editing":
			return {
				...state,
				editing: action.editing,
			};
		case "filter_by_category":
			return {
				...state,
				categoryFilter: action.categoryId,
				subCategoryFilter: null,
			};
		case "filter_by_subcategory":
			return {
				...state,
				subCategoryFilter: action.subCategoryId,
			};
		case "set_showFilter":
			return {
				...state,
				showFilter: !state.showFilter,
			};

		default:
			return state;
	}
};

const defaultPiece = { id: -69 };

export const initialState = {
	allOutfits: null,
	categories: null,
	categoryFilter: null,
	subCategoryFilter: null,
	showFilter: false,
	globalSearchTerm: "",
	sortBy: "name_descending",
	pageNum: 0,
	editing: null,
};
