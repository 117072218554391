import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { requestHandler } from "../../helpers/requests";
import { useState, useEffect } from "react";

export const ProtectedRoute = ({ element: Element, ...restOfProps }) => {
	const [cookies, setCookie, removeCookie] = useCookies();
	const auth = cookies.userInfo !== undefined;

	return auth ? Element : <Navigate to="/login" />;
};
