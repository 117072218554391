export const ClothingReducer = (state, action) => {
	switch (action.type) {
		case "set_all_items":
			return {
				...state,
				allItems: action.items,
			};
		case "set_categories":
			return {
				...state,
				categories: action.categories,
			};
		case "set_pageNum":
			return {
				...state,
				pageNum: action.pageNum,
			};
		case "increment_pageNum":
			return {
				...state,
				pageNum: state.pageNum + 1,
			};
		case "decrement_pageNum":
			var pageNum = state.pageNum - 1;
			if (pageNum < 0) pageNum = 0;
			return {
				...state,
				pageNum: pageNum,
			};
		case "set_globalSearchTerm":
			return {
				...state,
				globalSearchTerm: action.globalSearchTerm,
			};
		case "filter_by_category":
			return {
				...state,
				categoryFilter: action.categoryId,
				subCategoryFilter: null,
			};
		case "filter_by_subcategory":
			return {
				...state,
				subCategoryFilter: action.subCategoryId,
			};
		case "set_showFilter":
			return {
				...state,
				showFilter: !state.showFilter,
			};
		case "set_costSort":
			return {
				...state,
				costSort: state.costSort ? !state.costSort : true,
			};
		case "reset_costSort": {
			return {
				...state,
				costSort: null,
			};
		}
		case "set_error": {
			return {
				...state,
				error: action.error,
			};
		}
		case "update_data_url": {
			return {
				...state,
				dataURL: action.url,
			};
		}
		case "set_fileName": {
			return {
				...state,
				fileName: action.fileName,
			};
		}
		case "set_imageChanged": {
			return {
				...state,
				imageChanged: true,
			};
		}

		default:
			return state;
	}
};

export const initialState = {
	/*allItems: null,
  categories: null,
  showFilter: false,
  pageNum: 0,
  categoryFilter: null,
  subCategoryFilter: null,
  globalSearchTerm: "",
  costSort: null,*/
	error: null,
	dataURL: null,
	fileName: null,
	imageChanged: false,
};
