import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

const widthClass = "w-full";

const InternalCarousel = memo(
	// Needs to be in a memo'd wrapper since rerendering resets the gesture handler
	// This way items in the parent component (index bubbles) can update without resetting the gesture
	({
		images = {},
		setOutsideIndex = () => null,
		height = "h-auto",
		width = "w-full",
	}) => {
		const [index, setIndex] = useState(0);
		const numImages = Object.keys(images).length;
		const [{ left }, api] = useSpring(() => ({
			left: "0%",
		}));
		const ref = useRef();
		window.addEventListener("resize", () => {
			api.start({
				left: `-${index * 100}%`,
				immediate: true,
			});
		});

		useEffect(() => {
			api.start({ left: `-${index * 100}%` });
		}, [index]);

		let leftValue = 0; // used for storing the left value at any moment, not a state b/c rerenders will stop gestures

		const moveImage = (newIndex) => {
			if (newIndex < 0) {
				return;
			} else if (newIndex >= numImages) {
				return;
			}
			setIndex(newIndex); // sets internal carousel index
			setOutsideIndex(newIndex); // sets outside orbs & text
		};

		const CarouselGestureHandler = () => {
			const bind = useDrag(
				({ first, down, movement: [mx], velocity, intentional }) => {
					const forward = mx < 0;
					const clientWidth = ref.current
						? ref.current.clientWidth
						: 1;
					let currLeft = index * clientWidth;
					let imageRatio = 0;
					let newIndex = 0;

					imageRatio =
						(currLeft -
							(mx + (forward ? 0.15 : -0.15) * clientWidth)) /
						clientWidth;
					newIndex =
						mx < 0 ? Math.ceil(imageRatio) : Math.floor(imageRatio);

					if (newIndex < 0) {
						newIndex = 0;
					} else if (newIndex >= numImages) {
						newIndex = numImages - 1;
					}
					if (first) {
						api.stop(); // stop transitioning
						leftValue = parseInt(left.get().split("%")[0]); //
					} else if (down) {
						api.start({
							left: `${(mx / clientWidth) * 100 + leftValue}%`,
							immediate: true,
						});
						if (newIndex != index) {
							setOutsideIndex(newIndex);
						}
					} else {
						if (
							newIndex != index // switch image if user dragged the image enough
						) {
							moveImage(newIndex);
						} else if (
							velocity[0] >= 0.25 &&
							(forward
								? !newIndex == numImages - 1
								: !newIndex == 0) // otherwise move image forward/back if velocity is sufficient enough & not on first/last index
						) {
							moveImage(newIndex + (forward ? 1 : -1));
						} else {
							api.start({ left: `-${index * 100}%` }); // reset position
						}
					}
				}
			);

			return (
				<div
					{...bind()}
					className="absolute touch-pan-y top-0 left-0 z-10 w-full h-full cursor-grab"
					ref={ref}
				/>
			);
		};
		return (
			<div className={`flex w-full h-full justify-center select-none`}>
				<div className="hidden sm:flex flex-grow items-center justify-center p-2">
					<div
						onClick={() => moveImage(index - 1)}
						className={`p-2 rounded-full bg-white border ${
							index == 0
								? "border-gray-400 text-gray-400 cursor-not-allowed"
								: "border-black cursor-pointer hover:text-white hover:bg-black"
						} `}
					>
						<IoChevronBack />
					</div>
				</div>
				<div
					className={`flex flex-col ${width} ${height} max-w-[1280px]`}
				>
					<div className="relative w-full h-full border rounded-md overflow-hidden">
						<CarouselGestureHandler />
						<animated.div
							style={{ left }}
							className="flex w-full h-full relative"
						>
							{Object.entries(images).map(([key, value]) => (
								<img
									className="w-full h-full shrink-0 p-0 object-contain"
									key={key}
									src={value}
								/>
							))}
						</animated.div>
					</div>
				</div>
				<div className="hidden sm:flex flex-grow items-center justify-center p-2">
					<div
						onClick={() => moveImage(index + 1)}
						className={`p-2 rounded-full bg-white border ${
							index == numImages - 1
								? "border-gray-400 text-gray-400 cursor-not-allowed"
								: "border-black cursor-pointer hover:text-white hover:bg-black"
						} `}
					>
						<IoChevronForward />
					</div>
				</div>
			</div>
		);
	}
);

export const ImageCarousel = ({
	images = {},
	imageCaptions = null,
	carouselCompleteFunc = () => null,
	carouselHeight = "h-auto",
	carouselWidth = "w-full",
	className = "",
}) => {
	const [index, setIndex] = useState(0);

	const setIndexMemo = useCallback((index) => {
		setIndex(index);
		if (index == Object.keys(images).length - 1) {
			carouselCompleteFunc(true);
		}
	}, []);

	return (
		<div
			className={`${className} flex flex-col ${widthClass} h-full items-center`}
		>
			<InternalCarousel
				images={images}
				imageCaptions={imageCaptions}
				setOutsideIndex={setIndexMemo}
				height={carouselHeight}
				width={carouselWidth}
			/>
			{imageCaptions && (
				<div
					className={`flex w-full select-none justify-center items-center text-center h-32 p-4 font-light text-sm`}
				>
					{imageCaptions[index]}
				</div>
			)}

			<div className={`flex flex-col w-full self-center`}>
				<div className="flex w-full justify-center">
					<div className="flex w-1/2 justify-between items-center mt-8">
						{Object.entries(images).map(([key, value]) => (
							<div
								key={key}
								className={`p-1 sm:p-1.5 rounded-full  ${
									key == index
										? "bg-black"
										: "bg-gray-700 opacity-50"
								} `}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
