import React, { useState, useEffect, memo } from "react";
import { FixedOverlay } from "../general/misc/FixedOverlay";
import { YoutubeIframe } from "./YoutubeIframe";

export const TutorialComponent = memo(
	({
		parentState = false,
		setParentState = () => null,
		type = "clothing",
	}) => {
		const allTutInfo = {
			clothing: {
				src: "https://www.youtube.com/embed/JWgIFRWwAQk",
				mobileSrc: "https://www.youtube.com/embed/B_X8YTIUeKM",
				modalHeader: "Adding Clothing",
			},
			outfits: {
				src: "https://www.youtube.com/embed/fzS6hXY4bBk",
				mobileSrc: "https://www.youtube.com/embed/zB84EYjjpnA",
				modalHeader: "Adding Outfits",
			},
			lookbooks: {
				src: "https://www.youtube.com/embed/vQTXko16luE",
				mobileSrc: "https://www.youtube.com/embed/hLorYJpnX9I",
				modalHeader: "Adding Lookbooks",
			},
		};

		const tutInfo = allTutInfo[type];

		return (
			<FixedOverlay
				modalHeader={tutInfo.modalHeader}
				modalClass="p-4"
				modalWidth="w-[95%]"
				modalHeight="h-[95%] sm:max-h-[95%]"
				parentState={parentState}
				setParentState={setParentState}
				disableScrolling={true}
			>
				<YoutubeIframe
					src={tutInfo.src}
					mobileSrc={tutInfo.mobileSrc}
				/>
			</FixedOverlay>
		);
	}
);
