import React from "react";
import { Container } from "../components/general/universal/Container";
import {
	AddOutfitComponent,
	AddOutfitComponentWrapper,
} from "../components/outfits/addOutfit/AddOutfitComponent";
import { AddOutfitProvider } from "../contexts/Outfits/AddOutfit";
const AddOutfit = () => {
	return (
		<AddOutfitProvider>
			<Container showFooter={false} fixedHeight={false} noPadding={true}>
				<AddOutfitComponentWrapper />
			</Container>
		</AddOutfitProvider>
	);
};

export default AddOutfit;
