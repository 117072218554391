import { useContext, memo } from "react";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";

const ZMenuInner = memo(({ zIndex = 0, dispatch = () => null }) => {
	return (
		<div className="flex flex-col items-center w-full my-2">
			<div className="flex flex-col w-5/6 lg:w-full">
				<label className="font-light text-xs lg:text-base mb-2">
					Layer
				</label>
				<div className="flex w-full gap-2">
					{[...Array(9).keys()].map((i) => {
						return (
							<div
								onClick={() =>
									dispatch({
										type: "change_dimension",
										dimension: "zIndex",
										value: i,
									})
								}
								className={`p-1 flex-grow border rounded-md text-center text-xs lg:text-lg font-light cursor-pointer
		  ${zIndex == i ? "bg-black text-white pointer-events-none" : ""}`}
								key={i + 1}
							>
								{i + 1}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
});

export const ZMenu = () => {
	const [outfitState, dispatch] = useContext(AddOutfitContext);

	return (
		<ZMenuInner
			dispatch={dispatch}
			zIndex={
				outfitState.outfitDimensionGrid[outfitState.position].zIndex
			}
		/>
	);
};
