import React from "react";
import { Container } from "../components/general/universal/Container";
import { GoogleSignupLoginComponent } from "../components/login/GoogleSignupLoginComponent";

const Signup = () => {
	return (
		<Container>
			<GoogleSignupLoginComponent signup={true} />
		</Container>
	);
};

export default Signup;
