import update from "immutability-helper";

export const LookbookReducer = (state, action) => {
	switch (action.type) {
		/* Generic */

		case "set_error": {
			return {
				...state,
				error: action.error,
			};
		}

		/* Pagination */
		case "set_page": {
			return {
				...state,
				page: action.page,
			};
		}

		/* For ImageCropComponent */
		case "update_data_url": {
			return {
				...state,
				dataURL: action.url,
			};
		}
		case "set_fileName": {
			return {
				...state,
				fileName: action.fileName,
			};
		}
		case "set_imageChanged": {
			return {
				...state,
				imageChanged: true,
			};
		}

		/* Add Lookbook Specific */
		case "add_outfit": {
			console.log(
				state.outfits.length,
				state.itemsPerPage,
				(state.outfits.length + 1) / state.itemsPerPage,
				Math.ceil(state.outfits.length + 1 / state.itemsPerPage)
			);
			return update(state, {
				outfits: { $push: [action.outfit] },
				page: {
					$set: [
						Math.ceil(
							(state.outfits.length + 1) / state.itemsPerPage
						),
					],
				},
				totalPages: {
					$set: [
						Math.ceil(
							(state.outfits.length + 1) / state.itemsPerPage
						),
					],
				},
			});
		}
		case "remove_outfit": {
			var index = state.outfits.findIndex(
				(lookbookOutfit) => lookbookOutfit.id == action.outfit.id
			);
			if (index == undefined) {
				return;
			} else {
				return update(state, {
					outfits: { $splice: [[index, 1]] },
					page: {
						$set: [
							Math.ceil(
								(state.outfits.length - 1) / state.itemsPerPage
							),
						],
					},
					totalPages: {
						$set: [
							Math.ceil(
								(state.outfits.length - 1) / state.itemsPerPage
							),
						],
					},
				});
			}
		}
		case "set_outfits": {
			return {
				...state,
				outfits: action.outfits,
			};
		}
		case "reset_outfits": {
			return {
				...state,
				outfits: [],
			};
		}
		case "set_name": {
			return {
				...state,
				name: action.name,
			};
		}
		case "set_lookbook": {
			return {
				...state,
				lookbook: action.lookbook,
			};
		}

		default:
			return state;
	}
};

export const initialState = {
	error: null,
	dataURL: null,
	fileName: null,
	imageChanged: false,
	/* Pagination variables*/
	local: true,
	page: 1,
	itemsPerPage: 4,
	totalPages: 1,
	/* Add Lookbook Specific */
	outfits: [],
	lookbook: null,
	name: "",
};
