import "./index.css";
import { useEffect } from "react";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ProtectedRoute } from "./components/login/ProtectedRoute";
import { NonAuthRoutes, ProtectedRoutes } from "./routes";
import { AppProvider } from "./contexts/App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CONFIG } from "./config";
import Home from "./pages/Home";

function App() {
	const [cookies, setCookie, removeCookie] = useCookies();

	useEffect(() => {
		const localStorageToken = localStorage.getItem("token");
		if (localStorageToken) {
			setCookie("userInfo", localStorageToken, { path: "/" });
		}
	}, []);

	return (
		<GoogleOAuthProvider clientId={CONFIG.GOOGLE_CLIENT_ID}>
			<AppProvider>
				<BrowserRouter>
					<Routes>
						{NonAuthRoutes.map((route) => (
							<Route key="" {...route} />
						))}
						{ProtectedRoutes.map((route) => (
							<Route
								key=""
								path={route.path}
								element={
									<ProtectedRoute element={route.element} />
								}
								exact={route.exact}
							/>
						))}
						<Route path="*" element={<Home />} />
					</Routes>
				</BrowserRouter>
			</AppProvider>
		</GoogleOAuthProvider>
	);
}

export default App;
