import React from "react";
import { AppReducer, initialState } from "./AppReducer";

export const AppContext = React.createContext({
	state: initialState,
	dispatch: () => null,
});

export const AppProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(AppReducer, initialState);

	return (
		<AppContext.Provider value={[state, dispatch]}>
			{children}
		</AppContext.Provider>
	);
};
