import React from "react";
import { useState, useEffect } from "react";
import { requestHandler } from "../../helpers/requests";
import { useErrorHandler } from "react-error-boundary";
import { ColorButton } from "../general/misc/ColorButton";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../general/misc/ConfirmationModal";
import GenericJacket from "../../assets/GenericJacket.png";
import Fade from "react-reveal/Fade";
import { CONFIG } from "../../config";

export const LookbookNameWidget = ({ lookbook = null }) => {
	const [error, setError] = useState();
	useErrorHandler(error);
	const navigate = useNavigate();

	const deleteLookbook = (id) => {
		const formData = new FormData();
		formData.append("id", id);
		requestHandler.deleteLookbook(formData).then(
			(response) => {
				if (response.ok) {
					navigate("/lookbooks");
				} else {
					setError(response);
				}
			},
			(error) => setError(error)
		);
	};

	const [modal, setModal] = useState(false);

	return lookbook ? (
		<div className="flex justify-center mb-4">
			<ConfirmationModal
				itemName=" lookbook"
				modalState={modal}
				setModalState={setModal}
				confirmFunc={() => deleteLookbook(lookbook.id)}
			/>

			<div className="flex flex-grow flex-col items-center text-3xl font-light">
				<div className="flex w-full h-full justify-center relative rounded-md p-2 items-center mb-2">
					<img
						className={`w-full h-48 md:h-64 lg:h-72 xl:h-80 2xl:h-90
            transition-all duration-300  object-contain rounded-md`}
						src={
							lookbook.imageName
								? `${CONFIG.IMAGE_URL}${lookbook.imageName}`
								: GenericJacket
						}
					/>
				</div>
				<div className="mb-2">{lookbook.name}</div>
				<div className="flex flex-row">
					<ColorButton
						className="w-24"
						onClick={() => navigate(`/editLookbook/${lookbook.id}`)}
						type="yellow"
						label="Edit"
					/>
					<ColorButton
						onClick={() => setModal(true)}
						className="w-24 ml-2"
						type="red"
						label="Delete"
					/>
				</div>
			</div>
		</div>
	) : (
		""
	);
};
