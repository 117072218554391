import { useState, useEffect } from "react";
import { LabledTextInput } from "../general/forms/LabledTextInput";
import { requestHandler } from "../../helpers/requests";
import { ColorButton } from "../general/misc/ColorButton";

export const AdminComponent = () => {
	const [password, setPassword] = useState("");
	const [authenticated, setAuthenticated] = useState(false);

	const onSubmit = (event) => {
		event.preventDefault();
		const body = new FormData();
		body.append("password", password);
		requestHandler.checkAdmin(body).then((res) => {
			res.json().then((adminObj) => {
				if (adminObj.admin) {
					setAuthenticated(true);
				}
			});
		});
	};

	const sync = () => {
		requestHandler.sync().then((response) => {
			response.json().then((responseObj) => {
				console.log(responseObj.message);
			});
		});
	};

	const createCategories = () => {
		requestHandler.createCategories().then((response) => {
			response.json().then((responseObj) => {
				console.log(responseObj.message);
			});
		});
	};

	return (
		<div className="flex w-full h-full items-center justify-center">
			{authenticated ? (
				<div className="flex w-full justify-center">
					<ColorButton
						onClick={sync}
						className="mr-2"
						label="Sync DB"
					/>
					<ColorButton
						onClick={createCategories}
						label="Create Categories"
					/>
				</div>
			) : (
				<form onSubmit={onSubmit}>
					<LabledTextInput
						label="Password"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
					/>
				</form>
			)}
		</div>
	);
};
