import React, { useState, useEffect } from "react";
import { ViewOutfitsComponent } from "../outfits/viewOutfits/ViewOutfitsComponent";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import { ColorButton } from "../general/misc/ColorButton";
import { ViewClothingComponent } from "../clothing/ViewClothingComponent";
import { PiecesComponent } from "./PiecesComponent";
import { IoCloseSharp } from "react-icons/io5";

export const ViewOutfitsByClothingComponent = () => {
	const { pieceId } = useParams();
	const initArray = pieceId ? [parseInt(pieceId)] : [];
	const [pieceIds, setPieceIds] = useState(initArray);

	const addPieceId = (pieceId) => {
		setPieceIds(update(pieceIds, { $push: [parseInt(pieceId)] }));
	};

	const removePieceId = (removalPiece) => {
		const index = pieceIds.findIndex(
			(pieceId) => pieceId == removalPiece.id
		);
		if (index >= 0) {
			setPieceIds(update(pieceIds, { $splice: [[index, 1]] }));
		}
	};

	const cardCheck = (piece) => {
		if (pieceIds.findIndex((pieceId) => pieceId == piece.id) < 0) {
			return false;
		}
		return true;
	};

	const [clothingOverlay, setClothingOverlay] = useState(false);
	const [clothingContainer, setClothingContainer] = useState(false);

	const handleOverlay = () => {
		if (clothingOverlay) {
			setClothingOverlay(false);
			setTimeout(() => {
				setClothingContainer(false);
			}, 300);
		} else {
			setClothingOverlay(true);
			setClothingContainer(true);
		}
	};

	return (
		<>
			<div
				className={`fixed ${
					clothingContainer ? "left-0" : "-left-full"
				} ${
					clothingOverlay ? "opacity-100" : "opacity-0"
				} transition-opacity duration-300 flex items-center justify-center z-20 top-0 w-full h-full`}
			>
				<div
					onClick={handleOverlay}
					className="absolute cursor-pointer w-full h-full bg-black bg-opacity-80"
				></div>
				<div className="absolute flex flex-col overflow-hidden border rounded-md bg-white self-center w-5/6 h-5/6">
					<div className="flex justify-between items-center p-2 border-b text-lg font-light">
						<span className="self-center">Add Clothing</span>
						<span
							className="p-1 cursor-pointer"
							onClick={handleOverlay}
						>
							<IoCloseSharp />
						</span>
					</div>
					<div className="h-full p-2 overflow-y-auto ">
						<ViewClothingComponent
							local={true}
							onCardClick={(piece) => addPieceId(piece.id)}
							cardCheck={(piece) => cardCheck(piece)}
						/>
					</div>
				</div>
			</div>
			<div className="w-full flex flex-col">
				<PiecesComponent
					pieceIds={pieceIds}
					handleOverlay={handleOverlay}
					onCardClick={(pieceId) => removePieceId(pieceId)}
				/>
				<ViewOutfitsComponent local={true} add="" pieceIds={pieceIds} />
			</div>
		</>
	);
};
