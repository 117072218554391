const defaultPiece = { id: -69 };

export const AddOutfitReducer = (state, action) => {
	switch (action.type) {
		case "set_position":
			return {
				...state,
				position: action.position,
			};

		case "set_outfitName":
			return {
				...state,
				name: action.name,
			};

		case "add_outfit_piece":
			return {
				...state,
				outfitPieceGrid: {
					...state.outfitPieceGrid,
					[state.position]: action.item,
				},
			};

		case "change_dimension":
			return {
				...state,
				outfitDimensionGrid: {
					...state.outfitDimensionGrid,
					[state.position]: {
						...state.outfitDimensionGrid[state.position],
						[action.dimension]: action.value,
					},
				},
			};

		case "set_ratio":
			return {
				...state,
				outfitDimensionGrid: {
					...state.outfitDimensionGrid,
					[action.position]: {
						...state.outfitDimensionGrid[action.position],
						ratio: action.value,
					},
				},
			};

		case "set_zoom": {
			return {
				...state,
				zoom: action.zoom,
			};
		}

		case "set_topScale": {
			return {
				...state,
				topScale: action.top,
			};
		}

		case "set_leftScale": {
			return {
				...state,
				leftScale: action.left,
			};
		}

		case "reset_zoom": {
			return {
				...state,
				zoom: 0,
			};
		}

		case "reset_position": {
			return {
				...state,
				topScale: 0,
				leftScale: 0,
			};
		}

		case "set_preview":
			return {
				...state,
				preview: !state.preview,
			};

		case "reset_size":
			return {
				...state,
				outfitDimensionGrid: {
					...state.outfitDimensionGrid,
					[state.position]: {
						...state.outfitDimensionGrid[state.position],
						widthScale:
							state.position == 1 || state.position == 2
								? 0
								: -40,
						heightScale:
							state.position == 1 || state.position == 2
								? 0
								: -40,
					},
				},
			};

		case "reset_spacing":
			return {
				...state,
				outfitDimensionGrid: {
					...state.outfitDimensionGrid,
					[state.position]: {
						...state.outfitDimensionGrid[state.position],
						spacing: 0,
					},
				},
			};

		case "reset_cords":
			return {
				...state,
				outfitDimensionGrid: {
					...state.outfitDimensionGrid,
					[state.position]: {
						...state.outfitDimensionGrid[state.position],
						topScale: 0,
						leftScale: 0,
					},
				},
			};

		case "clear_piece":
			return {
				...state,
				outfitPieceGrid: {
					...state.outfitPieceGrid,
					[state.position]: defaultPiece,
				},
			};

		case "reset_all":
			return {
				...state,
				outfitPieceGrid: defaultOutfitPieceGrid,
				outfitDimensionGrid: defaultOutfitDimensionGrid,
			};
		case "set_dimension":
			return {
				...state,
				outfitPieceGrid: {
					...state.outfitPieceGrid,
					[action.position]: {
						...state.outfitPieceGrid[action.position],
						[`${action.dimension}Scale`]: action.value,
					},
				},
			};

		case "set_outfitPieceGrid":
			var outfitPieceGrid = {};
			var outfitDimensionGrid = {};
			if (action.outfitPieces.length == 0) {
				console.log("no length");
				return {
					...state,
					outfitPieceGrid: defaultOutfitPieceGrid,
					outfitDimensionGrid: defaultOutfitDimensionGrid,
				};
			} else {
				for (const piece of action.outfitPieces) {
					outfitPieceGrid = {
						...outfitPieceGrid,
						[parseInt(piece.position)]:
							piece.Clothing != null
								? piece.Clothing
								: defaultPiece,
					};
					outfitDimensionGrid = {
						...outfitDimensionGrid,
						[parseInt(piece.position)]: {
							widthScale: piece.widthScale,
							heightScale: piece.heightScale,
							topScale: piece.topScale,
							leftScale: piece.leftScale,
							split: piece.split,
							zIndex: piece.zIndex,
							spacing: piece.spacing,
						},
					};
				}

				[...Array(9).keys()].forEach((key) => {
					// fill sections of the outfitPiece/outfitDimension grid that aren't filled by the outfit that was passed in
					let outfitPiece = outfitPieceGrid[key + 1];
					if (outfitPiece == undefined) {
						outfitPieceGrid[key + 1] = defaultOutfitPiece;
						outfitDimensionGrid[key + 1] =
							defaultOutfitDimensionGrid[key + 1];
					}
				});

				return {
					...state,
					outfitPieceGrid: outfitPieceGrid,
					outfitDimensionGrid: outfitDimensionGrid,
				};
			}

		case "reset_outfitPieceGrid": {
			return {
				...state,
				outfitPieceGrid: defaultOutfitPieceGrid,
				outfitDimensionGrid: defaultOutfitDimensionGrid,
			};
		}

		case "set_outfitProps":
			return {
				...state,
				name: action.name,
				topScale: action.topScale,
				leftScale: action.leftScale,
			};

		default:
			return state;
	}
};

const maxMinValues = {
	1: { maxHeight: 40, minHeight: -40, maxWidth: 15, minWidth: -15 },
	2: { maxHeight: 40, minHeight: -40, maxWidth: 15, minWidth: -15 },
	3: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
	4: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
	5: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
	6: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
	7: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
	8: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
	9: { maxHeight: -20, minHeight: -90, maxWidth: -40, minWidth: -80 },
};

const defaultOutfitPiece = {
	id: -69,
};

export const defaultOutfitDimension = {
	widthScale: -40,
	topScale: 0,
	leftScale: 0,
	spacing: 0,
	split: 0,
	zIndex: 0,
};

export const defaultOutfitDimensionGrid = {
	1: { ...defaultOutfitDimension, widthScale: 0 },
	2: { ...defaultOutfitDimension, widthScale: 0 },
	3: defaultOutfitDimension,
	4: defaultOutfitDimension,
	5: defaultOutfitDimension,
	6: defaultOutfitDimension,
	7: defaultOutfitDimension,
	8: defaultOutfitDimension,
	9: defaultOutfitDimension,
};

const defaultOutfitPieceGrid = {
	1: defaultOutfitPiece,
	2: defaultOutfitPiece,
	3: defaultOutfitPiece,
	4: defaultOutfitPiece,
	5: defaultOutfitPiece,
	6: defaultOutfitPiece,
	7: defaultOutfitPiece,
	8: defaultOutfitPiece,
	9: defaultOutfitPiece,
};

export const initialState = {
	position: 1,
	outfitPieceGrid: defaultOutfitPieceGrid,
	outfitDimensionGrid: defaultOutfitDimensionGrid,
	preview: false,
	name: "",
	zoom: 0,
	topScale: 0,
	leftScale: 0,
};
