import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { requestHandler } from "../../helpers/requests";
import { ColorButton } from "../general/misc/ColorButton";
import { LabledTextInput } from "../general/forms/LabledTextInput";
import { useEffect } from "react";
import {
	GoogleLogin,
	GoogleOAuthProvider,
	useGoogleLogin,
} from "@react-oauth/google";
import { useErrorHandler } from "react-error-boundary";
import google from "../../assets/logos/google_logo.png";

export const GoogleSignupLoginComponent = ({
	signup = false,
	login = false,
}) => {
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies(["userInfo"]);
	const [error, setError] = useState(null);
	useErrorHandler(error);

	useEffect(() => {
		if (cookies.userInfo != undefined) {
			navigate("/clothing");
		}
	}, []);

	const handleSignupSuccess = (credentialResponse) => {
		const formData = new FormData();
		formData.append("code", credentialResponse.code);
		requestHandler.googleSignup(formData).then((response) => {
			if (response.ok) {
				response.json().then((data) => {
					localStorage.setItem("token", data.token);
					setCookie("userInfo", data.token, { path: "/" });
					if (data.new) {
						navigate("/welcome");
					} else navigate("/clothing");
				});
			} else {
				setError(response);
			}
		});
	};

	const loginState = useGoogleLogin({
		onSuccess: (tokenResponse) => handleSignupSuccess(tokenResponse),
		flow: "auth-code",
	});

	return (
		<div className="flex flex-col flex-grow w-full items-center justify-center">
			<div className="flex flex-col w-96 items-center rounded-md p-12">
				<div className="text-5xl font-bold mb-4 bg-gradient-to-r from-green-500 via-blue-500 to-red-500 bg-clip-text text-transparent p-2">
					{signup ? "Sign Up" : "Log In"}
				</div>
				<div
					onClick={loginState}
					class="flex hover:bg-black hover:text-white shadow-lg transition-all duration-50 items-center border border-black rounded-full cursor-pointer px-4 py-2"
				>
					<img class="w-8 h-8 object-contain mr-2" src={google} />
					<span class="text-lg font-normal">Sign in with Google</span>
				</div>
			</div>
		</div>
	);
};
