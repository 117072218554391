import React from "react";
import { ClothingReducer, initialState } from "./ClothingReducer";

export const ClothingContext = React.createContext({
	state: initialState,
	dispatch: () => null,
});

export const ClothingProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(ClothingReducer, initialState);

	return (
		<ClothingContext.Provider value={[state, dispatch]}>
			{children}
		</ClothingContext.Provider>
	);
};
