import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { requestHandler } from "../../../helpers/requests";
import { Spinner } from "../../general/universal/Spinner";
import { ColorButton } from "../../general/misc/ColorButton";
import { IndividualOutfitPieceGrid } from "./IndividualOutfitPieceGrid";
import { OutfitCard } from "../viewOutfits/OutfitCard";
import { ConfirmationModal } from "../../general/misc/ConfirmationModal";
import { useErrorHandler } from "react-error-boundary";
import { AppContext } from "../../../contexts/App";

export const ViewIndividualOutfitComponent = () => {
	const [error, setError] = useState(null);
	const [modal, setModal] = useState(false);
	const [appState, _] = useContext(AppContext);
	useErrorHandler(error);
	const navigate = useNavigate();

	let redirectUrl = "/outfits";
	if (appState.outfitRedirect) {
		redirectUrl = `/outfits?${appState.outfitRedirect}`;
	}

	const deleteOutfit = (OutfitId) => {
		const body = new FormData();
		body.append("OutfitId", OutfitId);
		requestHandler
			.deleteOutfit(body)
			.then((response) => {
				if (response.ok) {
					navigate(redirectUrl);
				} else {
					setError(response);
				}
			})
			.catch((err) => console.log(err));
	};

	const handleDeleteButton = () => {
		setModal(true);
	};

	const [outfit, setOutfit] = useState(null);

	const { outfitId } = useParams();

	useEffect(() => {
		const formData = new FormData();
		formData.append("OutfitId", outfitId);
		requestHandler
			.getOutfit(formData)
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						if (!data.outfit) {
							navigate("/outfits");
						} else {
							setOutfit(data.outfit);
						}
					});
				} else {
					setError(response);
				}
			})

			.catch((err) => console.log(err));
	}, []);

	const editOutfit = () => {
		navigate(`/editOutfit/${outfitId}`);
	};

	return !outfit ? (
		<Spinner />
	) : (
		<div className="flex flex-col w-full items-center justify-center lg:items-start lg:flex-row">
			<ConfirmationModal
				modalState={modal}
				setModalState={setModal}
				confirmFunc={() => deleteOutfit(outfitId)}
				itemName=" outfit"
			></ConfirmationModal>
			<div className="flex flex-col w-full mb-6 lg:mb-0 lg:mr-8">
				<OutfitCard cardType="big" nohover={true} Outfit={outfit} />
				<div className="flex justify-center flex-grow">
					<ColorButton
						type="yellow"
						onClick={editOutfit}
						className="mr-4 w-24"
						label="Edit"
					/>
					<ColorButton
						onClick={handleDeleteButton}
						type="red"
						className="w-24"
						label="Delete"
					/>
				</div>
			</div>
			<div className="flex w-full">
				<IndividualOutfitPieceGrid
					key="shut up react there's literally only one of these"
					Outfit={outfit}
				/>
			</div>
		</div>
	);
};
