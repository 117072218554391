/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";
import { requestHandler } from "../../../helpers/requests";
import { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../general/universal/Spinner";
import { PieceAdderGridWrapper } from "./PieceGrid/PieceAdderGrid";
import { useErrorHandler } from "react-error-boundary";
import { TitleTextInput } from "../../general/forms/TitleTextInput";
import {
	IoShirtOutline,
	IoOptionsOutline,
	IoMoveSharp,
	IoChevronUpSharp,
	IoChevronDownSharp,
} from "react-icons/io5";
import { AddOutfitClothingComponentWrapper } from "./AddOutfitClothingComponent";
import { BottomButtons } from "./BottomButtons";
import { SizingMenuWrapper } from "./SizingMenu";
import { memo } from "react";
import { useDrag } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import { ConfirmationModal } from "../../general/misc/ConfirmationModal";
import { ZoomPositionSlider } from "./ZoomPositionSliders";

/* Used for adding & editing outfits */
export const AddOutfitComponentWrapper = () => {
	const navigate = useNavigate();
	const [outfitState, dispatch] = useContext(AddOutfitContext);
	const [outfit, setOutfit] = useState(null);
	const [error, setError] = useState(null);
	useErrorHandler(error);

	const { outfitId } = useParams();
	const isEditing = outfitId != undefined;

	useEffect(() => {
		/* Get Outfit if editing */
		if (isEditing) {
			const formData = new FormData();
			formData.append("OutfitId", outfitId);
			requestHandler.getOutfit(formData).then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							if (!data.outfit) {
								navigate("/outfits");
							} else {
								dispatch({
									type: "set_outfitPieceGrid",
									outfitPieces: data.outfit.OutfitPieces,
								});
								dispatch({
									type: "set_outfitProps",
									name: data.outfit.name,
									topScale: data.outfit.topScale,
									leftScale: data.outfit.leftScale,
								});
								setOutfit(data.outfit);
							}
						});
					} else {
						setError(response);
					}
				},
				(error) => setError(error)
			);
		}
	}, []);

	return (outfitId !== undefined ? (!outfit ? true : false) : false) ? (
		<Spinner />
	) : (
		<AddOutfitComponent
			outfit={outfit}
			dispatch={dispatch}
			preview={outfitState.preview}
			name={outfitState.name}
		/>
	);
};

export const AddOutfitComponent = memo(
	({ outfit = null, dispatch = () => null, preview = false, name = "" }) => {
		const handleOutfitNameChange = (event) => {
			const outfitName = event.target.value;
			dispatch({ type: "set_outfitName", name: outfitName });
		};

		const [mode, setMode] = useState("clothing");
		const [mobileClothing, setMobileClothing] = useState(false);
		const [mobileSliders, setMobileSliders] = useState(false);
		const [mobileCardSliders, setMobileCardSliders] = useState(false);

		const handleClothingOverlay = () => {
			if (mobileClothing) {
				clothingApi.start({ bottom: "-100%" });

				setMobileClothing(false);
			} else {
				clothingApi.start({ bottom: "0%" });
				setMobileClothing(true);
			}
		};

		const handleSlidersOverlay = () => {
			if (mobileSliders) {
				sizingApi.start({ bottom: "-100%" });
				setMobileSliders(false);
			} else {
				sizingApi.start({ bottom: "0%" });
				setMobileSliders(true);
			}
		};

		const handleCardSlidersOverlay = () => {
			if (mobileCardSliders) {
				cardApi.start({ bottom: "-100%" });
				setMobileCardSliders(false);
			} else {
				cardApi.start({ bottom: "0%" });
				setMobileCardSliders(true);
			}
		};

		const closeOverlays = () => {
			if (mobileClothing) {
				clothingApi.start({ bottom: "-100%" });
				setMobileClothing(false);
			}
			if (mobileSliders) {
				sizingApi.start({ bottom: "-100%" });
				setMobileClothing(false);
			}
			if (mobileCardSliders) {
				cardApi.start({ bottom: "-100%" });
				setMobileClothing(false);
			}
		};

		const iconSize = 22;

		const [adjusterStyles, adjusterApi] = useSpring(() => ({
			backgroundColor: "white",
			color: "black",
		}));

		const [clothingStyles, clothingApi] = useSpring(() => ({
			height: window.innerHeight / 2,
			bottom: `-100%`,
		}));

		const [sizingStyles, sizingApi] = useSpring(() => ({
			height: window.innerHeight / 2,
			bottom: `-100%`,
		}));

		const [cardStyles, cardApi] = useSpring(() => ({
			height: window.innerHeight / 2,
			bottom: `-100%`,
		}));

		const [windowHeight, setWindowHeight] = useState(window.innerHeight);
		const maxHeight = windowHeight - 85;
		const [clothingBoxHeight, setClothingBoxHeight] = useState(
			window.innerHeight / 2
		);
		const [sizingBoxHeight, setSizingBoxHeight] = useState(
			window.innerHeight / 2
		);
		const [cardBoxHeight, setCardBoxHeight] = useState(
			window.innerHeight / 2
		);

		window.addEventListener("resize", () => {
			setWindowHeight(window.innerHeight);
		});

		const HeightAdjuster = ({ api, boxHeightState, setBoxHeightState }) => {
			// Set the drag hook and define component movement based on gesture data

			const minHeight = 125;

			const bind = useDrag(
				({ down, movement: [mx, my], xy: [x, y], delta }) => {
					if (down) {
						adjusterApi.start({
							backgroundColor: "black",
							color: "white",
						});
						if (boxHeightState - my >= maxHeight) {
							api.start({
								height: maxHeight,
								immediate: down,
							});
						} else if (boxHeightState - my <= minHeight) {
							api.start({
								height: minHeight,
								immediate: down,
							});
						} else {
							api.start({
								height: boxHeightState - my,
								immediate: down,
							});
						}
					} else {
						adjusterApi.start({
							backgroundColor: "white",
							color: "black",
						});
						setBoxHeightState(
							boxHeightState - my >= maxHeight
								? maxHeight
								: boxHeightState - my <= minHeight
								  ? minHeight
								  : boxHeightState - my
						);
					}
				}
			);

			return (
				<animated.div
					{...bind()}
					style={{
						backgroundColor: adjusterStyles.backgroundColor,
						color: adjusterStyles.color,
					}}
					className="flex lg:hidden w-full bg-white border-y border-gray-400 touch-none cursor-grab"
				>
					<div className="flex flex-col justify-between items-center w-full h-full">
						<IoChevronUpSharp size={16} />
						<IoChevronDownSharp size={16} />
					</div>
				</animated.div>
			);
		};

		const [clearPieceModal, setClearPieceModal] = useState(false);

		return (
			<div className="h-full">
				<ConfirmationModal
					altText="Are you sure you want to remove the piece from this slot?"
					modalState={clearPieceModal}
					confirmFunc={() => dispatch({ type: "clear_piece" })}
					setModalState={setClearPieceModal}
				/>
				<div className="flex select-none flex-col lg:flex-row justify-start h-full w-full">
					<div
						className={`flex flex-col mr-0 w-full  overflow-x-hidden lg:overflow-x-visible p-0 lg:px-4 lg:pt-4 pb-24 rounded-md`}
					>
						<div
							onClick={closeOverlays}
							className="flex w-full mb-2 lg:mb-4"
						>
							<TitleTextInput
								className="w-full p-2 lg:p-0"
								onChange={handleOutfitNameChange}
								placeholder="Outfit Name"
								value={name}
								charLimit={40}
							/>
						</div>

						<PieceAdderGridWrapper />
					</div>
					<div className="flex w-full  h-full flex-grow items-start pt-0 pl-0 lg:p-4 rounded-md ">
						<div className="flex flex-col w-full h-full items-center">
							<div className="hidden lg:flex select-none w-full cursor-pointer font-light leading-tight text-base text-center  mb-4">
								<div
									onClick={() => setMode("clothing")}
									className={` w-full h-full border rounded-l-md py-3 ${
										mode == "clothing"
											? "bg-black border-black text-white font-medium"
											: "border-gray-300"
									}`}
								>
									Add & Replace
								</div>
								<div
									onClick={() => setMode("resizing")}
									className={` w-full h-full border-t border-b border-r py-3 ${
										mode == "resizing"
											? "bg-black border-black text-white font-medium"
											: "border-gray-300"
									}`}
								>
									Resize & Reset
								</div>
								<div
									onClick={() => setMode("positioning")}
									className={` w-full h-full border-t border-b border-r  rounded-r-md py-3 ${
										mode == "positioning"
											? "bg-black border-black text-white font-medium"
											: "border-gray-300"
									}`}
								>
									Zoom & Position
								</div>
							</div>

							<div className="w-full h-full">
								<animated.div
									style={{
										height: clothingStyles.height,
										bottom: clothingStyles.bottom,
									}}
									className={`${
										mode != "clothing" ? "lg:hidden" : ""
									} fixed z-30 w-full lg:pb-0 lg:static max-h-screen left-0`}
								>
									<div className="absolute -top-10 left-0 z-30  flex w-full h-10">
										<HeightAdjuster
											api={clothingApi}
											boxHeightState={clothingBoxHeight}
											setBoxHeightState={
												setClothingBoxHeight
											}
										/>
									</div>
									{
										<div className="absolute flex lg:hidden w-full flex-col items-center -bottom-0 left-0 z-30">
											<div
												onClick={handleClothingOverlay}
												className="flex items-center justify-center w-full p-3 bg-white bg-opacity-80 border-t border-gray-400"
											>
												<IoChevronDownSharp size={22} />
											</div>
										</div>
									}

									<div className="p-2 lg:pt-0 lg:px-0 pb-12 bg-white w-full h-full overflow-y-scroll lg:overflow-y-visible">
										<AddOutfitClothingComponentWrapper />
									</div>
								</animated.div>

								<animated.div
									style={{
										height: sizingStyles.height,
										bottom: sizingStyles.bottom,
									}}
									className={`${
										mode != "resizing" ? "lg:hidden" : ""
									} fixed z-30 border-t lg:border-t-0 border-gray-400 bg-white pt-2 flex flex-col items-center lg:pt-0 lg:static left-0 w-full`}
								>
									<div className="absolute -top-10 left-0 z-30  flex w-full h-10">
										<HeightAdjuster
											api={sizingApi}
											boxHeightState={sizingBoxHeight}
											setBoxHeightState={
												setSizingBoxHeight
											}
										/>
									</div>
									<div className="absolute flex lg:hidden w-full flex-col items-center -bottom-0 left-0 z-30">
										<div
											onClick={handleSlidersOverlay}
											className="flex items-center justify-center w-full p-3 bg-white bg-opacity-80 border-t border-gray-400"
										>
											<IoChevronDownSharp size={22} />
										</div>
									</div>
									<div class="w-full h-full overflow-y-scroll lg:overflow-y-visible pb-12">
										<SizingMenuWrapper
											setClearPieceModal={
												setClearPieceModal
											}
										/>
									</div>
								</animated.div>

								<animated.div
									style={{
										height: cardStyles.height,
										bottom: cardStyles.bottom,
									}}
									className={`${
										mode != "positioning" ? "lg:hidden" : ""
									} fixed z-30 w-full lg:pb-0 lg:static max-h-screen left-0`}
								>
									<div className="absolute -top-10 left-0 z-30  flex w-full h-10">
										<HeightAdjuster
											api={cardApi}
											boxHeightState={cardBoxHeight}
											setBoxHeightState={setCardBoxHeight}
										/>
									</div>

									<div className="absolute flex lg:hidden w-full flex-col items-center -bottom-0 left-0 z-30">
										<div
											onClick={handleCardSlidersOverlay}
											className="flex items-center justify-center w-full p-3 bg-white bg-opacity-80 border-t border-gray-400"
										>
											<IoChevronDownSharp size={22} />
										</div>
									</div>

									<div className="flex flex-col items-center lg:pt-0 lg:px-0 lg:pb-0 pb-12 bg-white w-full h-full overflow-y-scroll lg:overflow-y-visible">
										<div className="w-5/6 lg:w-full">
											<ZoomPositionSlider />
										</div>
									</div>
								</animated.div>
							</div>
						</div>
					</div>
				</div>

				<div className="lg:hidden">
					<div className="fixed bottom-2 left-0 z-20 w-1/3 pl-2 pr-1">
						<div
							className={`flex h-10 w-full justify-center items-center bg-white bottom-2 border rounded-md py-1 cursor-pointer`}
							onClick={handleClothingOverlay}
						>
							<div className="flex flex-col self-center">
								<IoShirtOutline size={iconSize} />
							</div>
						</div>
					</div>
					<div className="fixed bottom-2 right-1/3 z-20 w-1/3 px-1">
						<div
							className={`h-10 flex justify-center items-center bg-white border cursor-pointer rounded-md py-1`}
							onClick={handleCardSlidersOverlay}
						>
							<IoMoveSharp size={iconSize} />
						</div>
					</div>
					<div className="fixed bottom-2 right-0 z-20 w-1/3 pr-2 pl-1">
						<div
							className={` bg-white h-10 flex justify-center items-center bottom-2 right-2 border cursor-pointer rounded-md py-1`}
							onClick={handleSlidersOverlay}
						>
							<div className="flex flex-col self-center">
								<IoOptionsOutline size={iconSize} />
							</div>
						</div>
					</div>
				</div>
				<BottomButtons outfit={outfit} />
			</div>
		);
	}
);
