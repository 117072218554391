import React from "react";
import { OutfitCard } from "../../outfits/viewOutfits/OutfitCard";
import { NoItemCard } from "../../general/misc/NoItemCard";
import { IoCloseSharp } from "react-icons/io5";

export const LookbookAdderCard = ({
	Outfit = null,
	onCardClick = () => {},
	cardType = "",
}) => {
	return !Outfit ? (
		<NoItemCard />
	) : (
		<div
			className="transition-all duration-75 cursor-pointer relative rounded-sm"
			onClick={() => onCardClick(Outfit)}
		>
			<div className="transition-all duration-50 absolute top-0 w-full bg-red-600 bg-opacity-20 h-full opacity-0 hover:opacity-100 border border-transparent hover:border-red-600">
				<div className="flex w-full h-full items-center justify-center text-red-600">
					<IoCloseSharp size={64} />
				</div>
			</div>
			<OutfitCard nohover={true} cardType={cardType} Outfit={Outfit} />
		</div>
	);
};
