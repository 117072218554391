import React from "react";

export const TitleTextInput = ({
	placeholder = "Title",
	onChange = () => null,
	value = "",
	className = "",
	charLimit = 50,
}) => {
	const onInputChange = (event) => {
		const value = event.target.value;
		if (value.length > charLimit) {
			return;
		} else {
			onChange(event);
		}
	};
	return (
		<div className={className}>
			<input
				className="appearance-none focus:outline-none border-b border-gray-300 border-t border-t-transparent focus:border-b-gray-700 text-center w-full text-base sm:text-xl rounded-sm pb-1 sm:pb-2 font-light leading-tight"
				onChange={onInputChange}
				value={value}
				placeholder={placeholder}
			/>
		</div>
	);
};
