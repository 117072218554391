import { requestHandler } from "../../helpers/requests";
import { LookbookCard } from "./LookbookCard";
import { ItemsView } from "../general/itemsview/ItemsView";
import { ViewerProvider } from "../../contexts/Generic";
import { ViewerContext } from "../../contexts/Generic";

export const ViewLookbooksComponent = ({ add = "/addLookbook" }) => {
	const costFunc = () => {
		return "";
	};

	const cardFunc = (Lookbook) => {
		return <LookbookCard key={Lookbook.id} Lookbook={Lookbook} />;
	};

	return (
		<ViewerProvider>
			<ItemsView
				fetchItemsFunc={requestHandler.getLookbooks}
				context={ViewerContext}
				cost={costFunc}
				card={cardFunc}
				add={add}
				type={"lookbooks"}
				excludeCategoryFilter={true}
				excludeCostSort={true}
				itemNames={{ single: "lookbook", plural: "lookbooks" }}
				searchPlaceholder="Search by lookbook name..."
			/>
		</ViewerProvider>
	);
};
