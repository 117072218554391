import React, { useState, useEffect, useContext, memo } from "react";
import {
	AddOutfitProvider,
	AddOutfitContext,
} from "../../contexts/Outfits/AddOutfit";
import { PieceAdderGridWrapper } from "../outfits/addOutfit/PieceGrid/PieceAdderGrid";
import { MemoizedSlider, Sliders } from "../outfits/addOutfit/Sliders";
import { SizingMenuWrapper } from "../outfits/addOutfit/SizingMenu";
import { ColorButton } from "../general/misc/ColorButton";
import Fade from "react-reveal";
import IsseyJacket from "../../assets/homepage/outfits/exampleOutfit/issey_jacket.jpg-comp.jpg";
import AcneJacket from "../../assets/homepage/outfits/exampleOutfit/acne_jacket.jpg-comp.jpg";
import EnfinPants from "../../assets/homepage/outfits/exampleOutfit/enfin_pants.jpg-comp.jpg";
import AcnePants from "../../assets/homepage/outfits/exampleOutfit/acne_pants.png-comp.jpg";
import FtfBoots from "../../assets/homepage/outfits/exampleOutfit/ftf_boots.jpg-comp.jpg";
import OurlegacyBoots from "../../assets/homepage/outfits/exampleOutfit/ourlegacy_boots.jpg-comp.jpg";
import EnfinBag from "../../assets/homepage/outfits/exampleOutfit/enfin_bag.jpg-comp.jpg";
import BottegaBag from "../../assets/homepage/outfits/exampleOutfit/bottega_bag.jpg-comp.jpg";
import AlyxNecklace from "../../assets/homepage/outfits/exampleOutfit/alyx_necklace.jpg-comp.jpg";
import GivenchyNecklace from "../../assets/homepage/outfits/exampleOutfit/givenchy_necklace.jpg-comp.jpg";
import AlyxBracelet from "../../assets/homepage/outfits/exampleOutfit/alyx_bracelet.jpg-comp.jpg";
import BalenciBracelet from "../../assets/homepage/outfits/exampleOutfit/balenci_bracelet.jpg-comp.jpg";
import { ClothingCard } from "../clothing/ClothingCard";
import { GridDisplayComponent } from "../general/itemsview/GridDisplayComponent";
import { FixedOverlay } from "../general/misc/FixedOverlay";
import { ZoomPositionSlider } from "../outfits/addOutfit/ZoomPositionSliders";

const defaultOutfitPieces = [
	{
		Clothing: { id: 1, imageName: IsseyJacket, static: true },
		position: 1,
		widthScale: 35,
		spacing: 0,
	},
	{
		Clothing: { id: 3, imageName: EnfinPants, static: true },
		position: 2,
		widthScale: -20,
		spacing: 0,
	},
	{
		Clothing: { id: 5, imageName: FtfBoots, static: true },
		position: 3,
		widthScale: -45,
		spacing: 0,
	},
	{
		position: 4,
		widthScale: -40,
		spacing: 0,
	},
	{
		position: 5,
		widthScale: -40,
		spacing: 0,
	},
	{
		position: 6,
		widthScale: -40,
		spacing: 0,
	},
	{
		position: 7,
		widthScale: -40,
		spacing: 0,
	},
	{
		position: 8,
		widthScale: -40,
		spacing: 0,
	},
	{
		position: 9,
		widthScale: -40,
		spacing: 0,
	},
];

const examplePieces = [
	{
		id: 1,
		name: "AW96 Parachute Bomber (Khaki)",
		brand: "Issey Miyake",
		cost: 10000,
		imageName: IsseyJacket,
		static: true,
	},
	{
		id: 2,
		name: "Green Boiled Wool Jacket",
		brand: "Acne Studios",
		cost: 671,
		imageName: AcneJacket,
		static: true,
	},
	{
		id: 3,
		name: "Ameztu Cargo Pants",
		brand: "Enfin Leve",
		cost: 671,
		imageName: EnfinPants,
		static: true,
	},
	{
		id: 4,
		name: "Purple Corduroy Pants",
		brand: "Acne Studios",
		cost: 342,
		imageName: AcnePants,
		static: true,
	},
	{
		id: 5,
		name: "Lorenzo Suede Combat Boots",
		brand: "From the First",
		cost: 773,
		imageName: FtfBoots,
		static: true,
	},
	{
		id: 6,
		name: "Camion Boots",
		brand: "Our Legacy",
		cost: 514,
		imageName: OurlegacyBoots,
		static: true,
	},
	{
		id: 7,
		name: "Erein Bag",
		brand: "Enfin Leve",
		cost: 773,
		imageName: EnfinBag,
		static: true,
	},
	{
		id: 8,
		name: "Purple Mini Cassette Bag",
		brand: "Bottega Veneta",
		cost: 1390,
		imageName: BottegaBag,
		static: true,
	},
	{
		id: 9,
		name: "Leather Details Necklace",
		brand: "Alyx",
		cost: 187,
		imageName: AlyxNecklace,
		static: true,
	},
	{
		id: 10,
		name: "Pink Medium Shading G Chain Necklace",
		brand: "Givenchy",
		cost: 2310,
		imageName: GivenchyNecklace,
		static: true,
	},
	{
		id: 11,
		name: "Silver Bracelet",
		brand: "Alyx",
		cost: 289,
		imageName: AlyxBracelet,
		static: true,
	},
	{
		id: 12,
		name: "Gold Force Striped Bracelet",
		brand: "Balenciaga",
		cost: 850,
		imageName: BalenciBracelet,
		static: true,
	},
];

const OutfitPreviewButton = () => {
	const [outfitState, dispatch] = useContext(AddOutfitContext);

	return (
		<div className="sticky bottom-0 flex-col w-full justify-center">
			<div className="flex w-full h-10">
				<ColorButton
					className="w-full"
					type={outfitState.preview ? "blue" : ""}
					label={outfitState.preview ? "Back" : "Preview Outfit"}
					onClick={() => dispatch({ type: "set_preview" })}
				/>

				<ColorButton
					className="w-full ml-4"
					type="red"
					label="Reset All"
					onClick={() =>
						dispatch({
							type: "reset_outfitPieceGrid",
						})
					}
				/>
			</div>
		</div>
	);
};

const ToyOutfitClothing = memo(
	({
		className = "",
		position = null,
		outfitPieceGrid = null,
		dispatch = null,
	}) => {
		const addOutfitPiece = (piece) => {
			dispatch({ type: "add_outfit_piece", item: piece });
		};

		const cardAddedCheck = (piece) => {
			var check = false;
			Object.entries(outfitPieceGrid).every(([key, outfitPiece]) => {
				if (outfitPiece.id == piece.id && key == position) {
					check = true;
					return false;
				} else return true;
			});
			return check;
		};

		const card = (piece) => {
			return (
				<ClothingCard
					cardCheck={cardAddedCheck}
					onCardClick={addOutfitPiece}
					type="clothingPicker"
					piece={piece}
				/>
			);
		};
		return (
			<div className={`${className} p-2 lg:py-4`}>
				<GridDisplayComponent card={card} items={examplePieces} />
			</div>
		);
	}
);

export const ToyOutfitClothingWrapper = memo(({ className = "" }) => {
	const [state, dispatch] = useContext(AddOutfitContext);
	return (
		<ToyOutfitClothing
			position={state.position}
			outfitPieceGrid={state.outfitPieceGrid}
			dispatch={dispatch}
			className={className}
		/>
	);
});

export const ToyOutfitExample = () => {
	const [overlay, setOverlay] = useState(false);
	return (
		<div className="py-24 flex flex-col w-full items-center">
			<AddOutfitProvider>
				<div className="w-full lg:w-1/2 mb-4">
					<ZoomPositionSlider />
				</div>

				<div className="flex flex-col lg:flex-row w-full">
					<div className="flex flex-col flex-grow">
						<div className="w-full overflow-x-hidden lg:overflow-x-visible">
							<PieceAdderGridWrapper
								defaultOutfitPieces={defaultOutfitPieces}
							/>
						</div>

						<div className="w-full self-center mt-4 px-2 sm:px-0">
							<OutfitPreviewButton />
						</div>
						<div className="flex lg:hidden w-full h-10 px-2 sm:px-0 my-4">
							<ColorButton
								type="green"
								className="w-full"
								label="Change Clothing"
								onClick={() => setOverlay(true)}
							/>
						</div>
					</div>

					<div className="flex flex-col flex-shrink ml-0 lg:ml-12 mt-4 md:mt-0">
						<SizingMenuWrapper />

						<FixedOverlay
							modalHeader="Change Clothing"
							disableScrolling={true}
							parentState={overlay}
							setParentState={setOverlay}
						>
							<ToyOutfitClothingWrapper />
						</FixedOverlay>
						<ToyOutfitClothingWrapper className="hidden lg:block" />
					</div>
				</div>
			</AddOutfitProvider>
		</div>
	);
};
