import React, { useState, useEffect } from "react";
import { LookbookContext } from "../../../contexts/Lookbooks/AddLookbook";
import { ViewOutfitsComponent } from "../../outfits/viewOutfits/ViewOutfitsComponent";
import { LookbookAdderCard } from "./LookbookAdderCard";
import { GridDisplayComponent } from "../../general/itemsview/GridDisplayComponent";
import { Spinner } from "../../general/universal/Spinner";
import { LabledTextInput } from "../../general/forms/LabledTextInput";
import { ColorButton } from "../../general/misc/ColorButton";
import { requestHandler } from "../../../helpers/requests";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import { gridClasses, gridTypes } from "../../../helpers/gridTypes";
import { ConfirmationModal } from "../../general/misc/ConfirmationModal";
import { ImageCropComponent } from "../../clothing/ImageCropComponent";
import { PaginationControls } from "../../general/itemsview/PaginationControls";
import { TitleTextInput } from "../../general/forms/TitleTextInput";
import { CONFIG } from "../../../config";
import { IoHelpSharp } from "react-icons/io5";
import { TutorialComponent } from "../../tutorials/TutorialComponent";

export const AddLookbookComponent = ({ context = LookbookContext }) => {
	const navigate = useNavigate();
	const { lookbookId } = useParams();
	const editing = lookbookId != undefined;
	const [lookbookState, dispatch] = React.useContext(context);
	const [loading, setLoading] = useState(false);

	const [modalState, setModalState] = useState(false);
	const [resetAllModalState, setResetAllModal] = useState(false);
	const [removingOutfit, setRemovingOutfit] = useState(null);
	const [noImage, setNoImage] = useState(false);

	const [error, setError] = useState(null);
	useErrorHandler(error);

	const maxOutfits = 50; // determines max amount of outfits allowed in a lookbook

	useEffect(() => {
		if (editing) {
			const formData = new FormData();
			formData.append("lookbookId", lookbookId);
			requestHandler.getOutfits(formData).then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							if (data.items.length == 0) {
								navigate("/lookbooks");
							} else {
								dispatch({
									type: "set_outfits",
									outfits: data.items,
								});
							}
						});
					} else {
						setError(response);
					}
				},
				(err) => setError(err)
			);
			formData.append("id", lookbookId);
			requestHandler.getLookbook(formData).then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							dispatch({
								type: "set_name",
								name: data.lookbook.name,
							});
							dispatch({
								type: "set_lookbook",
								lookbook: data.lookbook,
							});
						});
					} else {
						setError(response);
					}
				},
				(err) => setError(err)
			);
		}
	}, []);

	const page = lookbookState.page;
	const itemsPerPage = lookbookState.itemsPerPage;
	const items = lookbookState.outfits.slice(
		(page - 1) * itemsPerPage,
		page * itemsPerPage
	);

	useEffect(() => {
		console.log((page - 1) * itemsPerPage, page * itemsPerPage);
	}, [items]);

	const onNameChange = (event) => {
		const value = event.target.value;
		dispatch({ type: "set_name", name: value });
	};

	const addOutfit = (Outfit) => {
		if (lookbookState.outfits.length >= maxOutfits) {
			return;
		} else {
			let flag = false;
			lookbookState.outfits.forEach((outfit) => {
				if (outfit.id == Outfit.id) {
					flag = true;
				}
			});
			if (flag) {
				return;
			} else {
				dispatch({ type: "add_outfit", outfit: Outfit });
			}
		}
	};

	const removeOutfit = (Outfit) => {
		dispatch({ type: "remove_outfit", outfit: Outfit });
	};

	const addLookbook = () => {
		const formData = new FormData();
		if (!lookbookState.dataURL) {
			setNoImage(true);
			window.scrollTo(0, 0);
			return;
		} else {
			setLoading(true);
			formData.append(
				"name",
				lookbookState.name == "" ? "Lookbook Name" : lookbookState.name
			);
			formData.append("productImage", lookbookState.dataURL);
			formData.append("fileName", lookbookState.fileName);
			lookbookState.outfits.forEach((outfit) =>
				formData.append("outfits[]", outfit.id)
			);
			requestHandler.addLookbook(formData).then(
				(response) => {
					if (response.ok) {
						navigate("/lookbooks");
					} else {
						setError(response);
					}
				},
				(error) => setError(error)
			);
		}
	};

	const editLookbook = () => {
		const formData = new FormData();
		if (!lookbookState.dataURL) {
			setNoImage(true);
			window.scrollTo(0, 0);
			return;
		} else {
			setLoading(true);
			formData.append("id", lookbookId);
			formData.append(
				"name",
				lookbookState.name == "" ? "Lookbook Name" : lookbookState.name
			);
			formData.append("productImage", lookbookState.dataURL);
			formData.append("fileName", lookbookState.fileName);
			formData.append("imageChanged", lookbookState.imageChanged);
			formData.append("oldImage", lookbookState.lookbook.imageName);
			lookbookState.outfits.forEach((outfit) =>
				formData.append("outfits[]", outfit.id)
			);
			requestHandler.editLookbook(formData).then(
				(response) => {
					if (response.ok) {
						navigate(`/lookbook/${lookbookId}`);
					} else {
						setError(response);
					}
				},
				(error) => setError(error)
			);
		}
	};

	const card = (Outfit) => {
		return (
			<LookbookAdderCard
				key={Outfit.id}
				cardType={"outfitPicker"}
				onCardClick={() => {
					setModalState(true);
					setRemovingOutfit(Outfit);
				}}
				Outfit={Outfit}
			/>
		);
	};

	const cardCheck = (Outfit) => {
		let check = false;
		if (lookbookState.outfits.length >= maxOutfits) {
			check = true;
		} else {
			lookbookState.outfits.every((lookbookOutfit) => {
				if (lookbookOutfit.id == Outfit.id) {
					check = true;
					return false;
				} else return true;
			});
		}

		return check;
	};

	const [tutorialModal, setTutorialModal] = useState(false);

	return !lookbookState.outfits || (editing && !lookbookState.lookbook) ? (
		<Spinner />
	) : (
		<div className="flex flex-col w-full h-full">
			<TutorialComponent
				parentState={tutorialModal}
				setParentState={setTutorialModal}
				type="lookbooks"
			/>
			<ConfirmationModal
				modalState={modalState}
				altText="Are you sure you want to remove this outfit from the lookbook?"
				setModalState={setModalState}
				confirmFunc={() => removeOutfit(removingOutfit)}
			/>
			<ConfirmationModal
				modalState={resetAllModalState}
				altText="Are you sure remove all outfits from the lookbook?"
				setModalState={setResetAllModal}
				confirmFunc={() => dispatch({ type: "reset_outfits" })}
			/>
			<div className="flex self-center mb-4 lg:mb-8">
				<ImageCropComponent
					defaultImg={
						editing
							? `${CONFIG.IMAGE_URL}${lookbookState.lookbook.imageName}`
							: null
					}
					noImage={noImage}
					context={LookbookContext}
				/>
			</div>
			<div className="flex flex-col w-full h-full">
				<div className="flex flex-col lg:flex-row flex-grow pb-16">
					<div className="relative flex flex-col w-full mr-0 mb-4 lg:mb-0 lg:mr-3 cursor-default">
						<div>
							<TitleTextInput
								onChange={onNameChange}
								value={lookbookState.name}
								placeholder="Lookbook Name"
								className="self-center w-full"
							/>
						</div>

						<div
							className={`${
								lookbookState.outfits.length >= maxOutfits
									? "text-red-600"
									: ""
							} my-3 text-sm font-light leading-tight text-center`}
						>
							{`${lookbookState.outfits.length}
              ${
					lookbookState.outfits.length == 1 ? "outfit" : "outfits"
				} (max ${maxOutfits})`}
						</div>
						{lookbookState.outfits.length > 0 ? (
							<GridDisplayComponent
								gridClass={gridClasses("outfitPicker")}
								items={items}
								card={card}
							/>
						) : (
							<div className="w-full h-full min-h-[597px] lg:min-h-0 text-gray-600 flex items-center select-none justify-center font-light">
								<span className="text-2xl text-center text-gray-400">
									No outfits, click on an outfit to add it!
								</span>
							</div>
						)}
						{lookbookState.outfits.length > 0 && (
							<PaginationControls context={LookbookContext} />
						)}
					</div>
					<div className="flex w-full ml-0 lg:ml-3">
						<ViewOutfitsComponent
							local={true}
							onCardClick={addOutfit}
							type="outfitPicker"
							cardType="outfitPicker"
							add=""
							cardCheck={cardCheck}
						/>
					</div>
				</div>
			</div>
			<div className="fixed z-20 w-full flex justify-center bg-white  border-t border-gray-400 right-0 bottom-0 p-3">
				<ColorButton
					className="self-center w-24 text-base mr-2"
					label={`Reset`}
					type="red"
					onClick={() => setResetAllModal(true)}
				/>
				<ColorButton
					className="self-center w-24 text-base"
					label={`${
						editing
							? loading
								? "Saving"
								: "Save"
							: loading
							  ? "Adding"
							  : "Add"
					}`}
					type="green"
					loading={loading}
					onClick={editing ? editLookbook : addLookbook}
				/>
				<div className="absolute flex h-full items-center right-2 top-0">
					<div
						onClick={() => setTutorialModal(true)}
						className="p-2 border rounded-full hover:bg-black hover:text-white cursor-pointer"
					>
						<IoHelpSharp size={20} />
					</div>
				</div>
			</div>
		</div>
	);
};
