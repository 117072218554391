import React, { useState } from "react";
import Clothing from "../../assets/clothing.png";
import Outfit from "../../assets/outfit.png";
import Lookbook from "../../assets/lookbook.png";

const imgClass = "p-2 h-full  rounded-md object-contain";
const cardClass =
	"flex flex-col overflow-hidden border border-gray-400 hover:border-gray-700 rounded-xl p-2 w-full text-center text-2xl mb-10 transform transition-transform duration-300 hover:scale-105";

export const Cards = ({
	className = "",
	cardClassModifier = "",
	clothingCaption = "Consoom jawnz",
	outfitsCaption = "Create infinite bricked fits",
	lookbooksCaption = "Group outfits for epic results",
	onClothingClick = () => null,
	onOutfitsClick = () => null,
	onLookbooksClick = () => null,
	cardOverlay = null,
	clothingOverlay = false,
	outfitOverlay = false,
	lookbookOverlay = false,
}) => {
	return (
		<div
			className={`${className} w-full h-full flex flex-col sm:flex-row 2xl:w-3/4 px-8`}
		>
			<div className={`${cardClassModifier} ${cardClass} sm:mr-8`}>
				{clothingOverlay && cardOverlay && cardOverlay}
				<div
					onClick={onClothingClick}
					className="absolute z-10 w-full h-full top-0 left-0"
				/>
				<div className="font-bold mb-2">Clothing Tutorial</div>
				<div className="text-sm font-light">{clothingCaption}</div>
				<div className="w-full h-full flex items-center justify-center mt-8">
					<img className={imgClass} src={Clothing}></img>
				</div>
			</div>

			<div className={`${cardClassModifier} ${cardClass}`}>
				{outfitOverlay && cardOverlay && cardOverlay}
				<div
					onClick={onOutfitsClick}
					className="absolute z-10 w-full h-full top-0 left-0"
				/>
				<div className="font-bold mb-2">Outfits Tutorial</div>
				<div className="font-light text-sm">{outfitsCaption}</div>
				<div className="w-full h-full flex items-center justify-center mt-8">
					<img className={imgClass} src={Outfit}></img>
				</div>
			</div>

			<div className={`${cardClassModifier} ${cardClass} sm:ml-8`}>
				{lookbookOverlay && cardOverlay && cardOverlay}
				<div
					onClick={onLookbooksClick}
					className="absolute z-10 w-full h-full top-0 left-0"
				/>
				<div className="font-bold mb-2">Lookbooks Tutorial</div>
				<div className="font-light text-sm">{lookbooksCaption}</div>
				<div className="w-full h-full flex items-center justify-center mt-8">
					<img className={imgClass} src={Lookbook}></img>
				</div>
			</div>
		</div>
	);
};
