import React, { useState, useEffect, useContext, useCallback } from "react";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ConfirmationModal } from "../../general/misc/ConfirmationModal";
import { requestHandler } from "../../../helpers/requests";
import { useErrorHandler } from "react-error-boundary";
import { ColorButton } from "../../general/misc/ColorButton";
import { TutorialComponent } from "../../tutorials/TutorialComponent";
import { IoCloseSharp, IoHelpSharp } from "react-icons/io5";
import { MemoizedSlider } from "./Sliders";
import { defaultOutfitDimensionGrid } from "../../../contexts/Outfits/AddOutfit/AddOutfitReducer";
import { AppContext } from "../../../contexts/App";
import { ZoomPositionSlider } from "./ZoomPositionSliders";

export const BottomButtons = () => {
	const [appState, _] = useContext(AppContext);
	let redirectUrl = "/outfits";
	if (appState.outfitRedirect) {
		redirectUrl = `/outfits?${appState.outfitRedirect}`;
	}
	const [state, dispatch] = useContext(AddOutfitContext);
	const [fitCheck, setFitCheck] = useState(false);
	const [adding, setAdding] = useState(false);
	const [saving, setSaving] = useState(false);
	const [resetAllModal, setResetAllModal] = useState(false);
	const [error, setError] = useState(null);
	useErrorHandler(error);
	const navigate = useNavigate();

	const { outfitId } = useParams();
	const isEditing = outfitId != undefined;

	useEffect(() => {
		var flag = false;
		Object.entries(state.outfitPieceGrid).forEach(([key, value]) => {
			if (value) {
				flag = true;
			}
		});
		setFitCheck(flag);
	}, [state.outfitPieceGrid]);

	const buildOutfitPieceGrid = () => {
		let outfitPieceGrid = {};
		let outfitDimensionGrid = {};
		Object.entries(state.outfitPieceGrid).forEach(([key, piece]) => {
			let dimensions = state.outfitDimensionGrid[key];
			let defaultDimensions = defaultOutfitDimensionGrid[key];
			//console.log(dimensions, defaultDimensions);
			if (
				piece.id >= 0 ||
				dimensions.widthScale != defaultDimensions.widthScale ||
				dimensions.spacing != defaultDimensions.spacing
			) {
				outfitPieceGrid[key] = piece;
				if (dimensions.ratio) {
					let heightScale =
						(100 + dimensions.widthScale) / dimensions.ratio - 100;
					dimensions.heightScale = heightScale;
				}
				outfitDimensionGrid[key] = dimensions;
			}
		});
		return { outfitPieceGrid, outfitDimensionGrid };
	};

	const addOutfit = () => {
		setAdding(true);
		let { outfitPieceGrid, outfitDimensionGrid } = buildOutfitPieceGrid();
		//console.log(outfitPieceGrid, outfitDimensionGrid);
		const outfitFormData = new FormData();
		outfitFormData.append(
			"outfitPieceGrid",
			JSON.stringify(outfitPieceGrid)
		);
		outfitFormData.append(
			"outfitDimensionGrid",
			JSON.stringify(outfitDimensionGrid)
		);
		outfitFormData.append(
			"name",
			state.name == "" ? "Outfit Name" : state.name
		);
		outfitFormData.append("topScale", state.topScale);
		outfitFormData.append("leftScale", state.leftScale);

		requestHandler.addOutfit(outfitFormData).then(
			(response) => {
				if (response.ok) {
					navigate("/outfits");
				} else {
					setError(response);
				}
			},
			(error) => setError(error)
		);
	};

	const editOutfit = () => {
		setSaving(true);
		let { outfitPieceGrid, outfitDimensionGrid } = buildOutfitPieceGrid();
		//console.log(outfitDimensionGrid);
		const outfitFormData = new FormData();
		outfitFormData.append(
			"outfitPieceGrid",
			JSON.stringify(outfitPieceGrid)
		);
		outfitFormData.append(
			"outfitDimensionGrid",
			JSON.stringify(outfitDimensionGrid)
		);
		outfitFormData.append(
			"name",
			state.name == "" ? "Outfit Name" : state.name
		);
		outfitFormData.append("outfitId", outfitId);
		outfitFormData.append("topScale", state.topScale);
		outfitFormData.append("leftScale", state.leftScale);

		requestHandler.editOutfit(outfitFormData).then(
			(response) => {
				if (response.ok) {
					navigate(redirectUrl);
				} else {
					setError(response);
				}
			},
			(error) => setError(error)
		);
	};

	const [tutorialModal, setTutorialModal] = useState(false);

	const setTutorialModalMemo = useCallback((state) => {
		setTutorialModal(state);
	}, []);

	const [zoomSlider, setZoomSlider] = useState(false);

	return (
		<>
			<TutorialComponent
				parentState={tutorialModal}
				setParentState={setTutorialModalMemo}
				type="outfits"
			/>
			<ConfirmationModal
				altText="Are you sure you want to clear this outfit?"
				modalState={resetAllModal}
				confirmFunc={() => dispatch({ type: "reset_all" })}
				setModalState={setResetAllModal}
			/>
			<div className="fixed z-10 pb-14 lg:pb-2 w-full flex  justify-center bg-white  border-t border-gray-400 right-0 bottom-0 p-2">
				{isEditing && (
					<ColorButton
						onClick={editOutfit}
						type="green"
						height="h-10 lg:h-full"
						className="w-full lg:w-24 text-sm lg:text-base self-center "
						disabled={adding}
						loading={saving}
						label={saving ? "Saving" : "Save"}
					/>
				)}
				<ColorButton
					onClick={addOutfit}
					type="green"
					height="h-10 lg:h-full"
					className={`w-full lg:w-24 text-sm lg:text-base self-center  ${
						isEditing ? "ml-2" : ""
					}`}
					disabled={saving}
					loading={adding}
					label={adding ? "Adding" : "Add"}
				/>
				<ColorButton
					onClick={() => dispatch({ type: "set_preview" })}
					type={state.preview ? "blue" : ""}
					height={"h-10 lg:h-full"}
					className="w-full lg:w-24 text-sm lg:text-base self-center  ml-2"
					label={state.preview ? "Back" : "Preview"}
				/>
				<ColorButton
					onClick={() => setResetAllModal(true)}
					type="red"
					height={"h-10 lg:h-full"}
					className="w-full lg:w-24 text-sm lg:text-base self-center  ml-2"
					label="Clear"
				/>
				<div className="absolute ml-2 lg:ml-0 flex lg:h-full items-center right-2 -top-12 lg:top-0">
					<div
						onClick={() => setTutorialModal(true)}
						className="p-2 border bg-white bg-opacity-60 rounded-full hover:bg-black hover:text-white cursor-pointer"
					>
						<IoHelpSharp size={20} />
					</div>
				</div>
			</div>
		</>
	);
};
