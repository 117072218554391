import React from "react";
import { ViewClothingComponent } from "../components/clothing/ViewClothingComponent";
import { Container } from "../components/general/universal/Container";
import { ViewerContext } from "../contexts/Generic";
import { ViewerProvider } from "../contexts/Generic";
import { defaultPadding } from "../helpers/classNames";

const ViewClothing = () => {
	return (
		<Container>
			<ViewClothingComponent add={"/add"} />
		</Container>
	);
};

export default ViewClothing;
