import React from "react";
import { Container } from "../components/general/universal/Container";
import { AccountEditComponent } from "../components/login/AccountEditComponent";

const handleExternalLink = (link) => {
	window.location.href = link;
};

const linkClass = "font-bold text-blue-700 hover:text-blue-500 cursor-pointer";

const PrivacyPolicyCopy = [
	{
		header: "OUTFITORG PRIVACY POLICY",
		body: (
			<div>
				This privacy policy explains the manner in which OutfitOrg
				collects, uses and discloses personal information throughout our
				platform.
			</div>
		),
	},
	{
		header: "INFORMATION WE COLLECT AND USE",
		body: (
			<>
				<div>
					The only personal information collected is during account
					creation, and the only piece of information collected is the
					user's email address. The email address is only used to
					create the user's account. We do not send emails to any
					collected email addresses.
				</div>
			</>
		),
	},
	{
		header: "INFORMATION ABOUT OUR WEBSITE",
		body: (
			<>
				<div>
					<span className="font-bold">Images:</span> We store images
					uploaded to the platform, but only display them to the user
					who uploaded them. Put simply, your images are only visible
					to you.
				</div>
				<div className="mt-2">
					<span className="font-bold">Cookies:</span> We use cookies
					to store info which is required for authentication. If you
					disable cookies your experience will be severely limited.
					The info is encrypted in a JWT (JSON Web Token).
				</div>
			</>
		),
	},
	{
		header: "CONTACT US",
		body: (
			<>
				You can contact us through{" "}
				<span
					className={linkClass}
					onClick={() =>
						handleExternalLink(
							"https://www.linkedin.com/company/outfitorg/"
						)
					}
				>
					LinkedIn
				</span>
				.
			</>
		),
	},
];

const PrivacyPolicySection = ({ header, body }) => {
	return (
		<div className="flex flex-col">
			<div className="text-2xl font-bold">{header}</div>
			<div className="mt-4">{body}</div>
		</div>
	);
};

const Privacy = () => {
	return (
		<Container>
			<div className="italic font-light mb-4">
				Last Updated: August 19th, 2023
			</div>
			<div className="flex flex-col gap-10">
				{PrivacyPolicyCopy.map((section) => (
					<PrivacyPolicySection
						header={section.header}
						body={section.body}
					></PrivacyPolicySection>
				))}
			</div>
		</Container>
	);
};

export default Privacy;
