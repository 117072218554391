/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { cssFunc } from "../../../helpers/cssHelper";
import { memo } from "react";

/* 
  Wrapper for the cssFunc with some other props for ease of use (onClick, className, children) 
*/
export const PieceSpacer = memo(
	({
		children,
		className = "",
		onClick = () => null,
		type = "",
		widthScale = 0,
		heightScale = null,
		topScale = 0,
		leftScale = 0,
		zIndex = 0,
		zoom = 0,
		empty = true,
		spacing = 0,
		noWidth = false,
		noHeight = false,
	}) => {
		return (
			<div
				onClick={onClick}
				className={`${className} relative`}
				css={cssFunc({
					type,
					widthScale,
					heightScale,
					topScale,
					leftScale,
					zIndex,
					zoom,
					spacing,
					empty,
					noWidth,
					noHeight,
				})}
			>
				{children}
			</div>
		);
	}
);
