import React from "react";
import { ColorButton } from "../misc/ColorButton";
import { CategoryFilterComponent } from "./filtercontents/CategoryFilterComponent";
import { SortComponent } from "./filtercontents/SortComponent";
import { useEffect } from "react";
import { useState } from "react";
import { ViewerContext } from "../../../contexts/Generic";
import "./filter.css";

export const DesktopFilter = ({ context = ViewerContext }) => {
	const [contextState, dispatch] = React.useContext(context);
	const [headerContainerState, setHeaderContainerState] = useState(null);
	const [headerState, setHeaderState] = useState(contextState.showFilter);
	const [scrollState, setScrollState] = useState(0);

	useEffect(() => {
		/* This shit always runs so need a way to 
    know if it's the first time running or not, 
    scuffed implementation lmao*/
		if (headerContainerState == null) {
			setHeaderContainerState(contextState.showFilter);
			setHeaderState(contextState.showFilter);
			if (contextState.showFilter) {
				var scrollY = window.scrollY;
				document.body.style.top = `-${scrollY}px`;
				document.body.style.position = "fixed";
				setScrollState(scrollY);
			}
		} else {
			if (!contextState.showFilter) {
				setHeaderState(false);
				setTimeout(() => {
					setHeaderContainerState(false);
				}, 300);
			} else {
				setHeaderContainerState(true);
				setHeaderState(true);
			}
		}
	}, [contextState.showFilter]);

	return (
		<div className="hidden lg:block">
			<div
				className={`z-9 absolute top-12 pb-14  ${
					headerContainerState ? "visible" : "invisible"
				} w-auto`}
			>
				<div
					className={`transition-opacity duration-300 overflow-y-scroll filter-content ${
						!headerState ? "opacity-0" : "opacity-100"
					}  flex flex-col p-2 w-full border border-gray-900 shadow-md bg-white bg-opacity-70 rounded-md font-light`}
				>
					<div className="flex flex-col rounded-md">
						{contextState.excludeCategoryFilter ? (
							""
						) : (
							<CategoryFilterComponent
								className="mb-2"
								context={context}
							/>
						)}

						<SortComponent context={context} />
					</div>
				</div>
			</div>
		</div>
	);
};
