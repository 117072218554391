import React from "react";
import { AddOutfitReducer, initialState } from "./AddOutfitReducer";
export const AddOutfitContext = React.createContext({
	state: initialState,
	dispatch: () => null,
});

export const AddOutfitProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(AddOutfitReducer, initialState);

	return (
		<AddOutfitContext.Provider value={[state, dispatch]}>
			{children}
		</AddOutfitContext.Provider>
	);
};
