import { React, useEffect, useState } from "react";
import { NoItemCard } from "../general/misc/NoItemCard";
import { GridDisplayComponent } from "../general/itemsview/GridDisplayComponent";
import { OutfitCard } from "../outfits/viewOutfits/OutfitCard";
import { Outfit } from "../outfits/Outfit";
import { useNavigate } from "react-router-dom";
import { gridClasses } from "../../helpers/gridTypes";
import GenericJacket from "../../assets/GenericJacket.png";
import { CONFIG } from "../../config";

export const LookbookCard = ({ Lookbook = null }) => {
	const navigate = useNavigate();
	const card = (LookbookOutfit) => {
		return (
			<Outfit
				key={LookbookOutfit.id}
				type="lookbookThumbnailOutfit"
				Outfit={LookbookOutfit.Outfit}
			/>
		);
	};

	const [opacityState, setOpacityState] = useState(false);

	return !Lookbook ? (
		<NoItemCard />
	) : (
		<div
			onClick={() => navigate(`/lookbook/${Lookbook.id}`)}
			className={`relative flex flex-col w-full h-full transition-border duration-75 flex-shrink items-center justify-center border border-transparent select-none  rounded-sm py-2 px-1 
        hover:border-gray-400 cursor-pointer
      `}
		>
			<div className="flex w-full h-full justify-center relative rounded-md p-2 items-center mb-2">
				<img
					onLoad={() => setOpacityState(true)}
					className={`w-full h-32 sm:h-32 md:h-48 lg:h-64 xl:h-72 2xl:h-72
           opacity-0 transition-all duration-300 ${
				opacityState ? "opacity-100" : "opacity-0"
			} object-contain rounded-md`}
					src={
						Lookbook.imageName
							? `${CONFIG.IMAGE_URL}${Lookbook.imageName}`
							: GenericJacket
					}
				/>
			</div>
			<div className="flex flex-col w-full items-center text-center text-sm font-light mb-1">
				<div className="w-full font-normal">{Lookbook.name}</div>
				<div className="w-full text-xs truncate">
					{Lookbook.count}{" "}
					{Lookbook.count == 1 ? "outfit" : "outfits"}
				</div>
			</div>
		</div>
	);
};
