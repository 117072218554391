import React from "react";
import { gridTypes } from "../../../helpers/gridTypes";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const GridDisplayComponent = ({
	card,
	className,
	displayFields = [],
	items = [],
	itemNamePlural = "Items",
	gridClass = "grid-cols-2 grid-rows-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6",
	add = "",
}) => {
	const navigate = useNavigate();
	return items.length == 0 ? (
		<div className="flex flex-col flex-grow select-none w-full  justify-center">
			<div className="flex flex-col items-center justify-center">
				<span className="text-2xl font-light text-gray-400 mb-1">
					No {itemNamePlural}
				</span>
				{add && (
					<div
						className="flex w-10 h-10 justify-center items-center border border-gray-400 hover:border-blue-700 hover:bg-blue-700 rounded-md text-gray-400 hover:text-white cursor-pointer"
						onClick={() => navigate(add)}
					>
						<IoAdd size={20} />
					</div>
				)}
			</div>
		</div>
	) : (
		<div
			className={`${className} flex justify-center grid auto-rows-fr ${gridClass} `}
		>
			{items.map((piece) => {
				return card(piece, displayFields);
			})}
		</div>
	);
};
