import React from "react";
import { Container } from "../components/general/universal/Container";
import { AccountEditComponent } from "../components/login/AccountEditComponent";
import { GoogleAccountEditComponent } from "../components/login/GoogleAccountEditComponent";

const AccountEdit = () => {
	return (
		<Container>
			<GoogleAccountEditComponent />
		</Container>
	);
};

export default AccountEdit;
