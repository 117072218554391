import { useState, useEffect, useMemo, memo } from "react";
import { ClothingRowCard } from "./ClothingRowCard";
import { ViewClothingComponent } from "../clothing/ViewClothingComponent";
import { Chart } from "react-charts";
import Bar from "./Bar";
import { StyledChart } from "./StyledChart";
import { requestHandler, authRequest } from "../../helpers/requests";
import { useSpring } from "@react-spring/web";
import { ColorButton } from "../general/misc/ColorButton";
import { Spinner } from "../general/universal/Spinner";
import { Link } from "react-router-dom";

const ChartCard = memo(({ title, data, className, seriesColor }) => {
	return (
		<div
			className={`${className} mb-4 sm:mb-0 w-full p-1 sm:p-4  rounded-md`}
		>
			<StyledChart title={title} data={data} seriesColor={seriesColor} />
		</div>
	);
});

export const ClothingAnalyticsComponent = () => {
	const formatData = (rawData, label = "label", field = "cost") => {
		let data = [];

		let series = {
			label: label,
			data: rawData.map((Category) => {
				return {
					primary: Category.name,
					secondary: parseInt(Category[field]),
				};
			}),
		};
		data.push(series);

		return data;
	};

	const [aggregateData, setAggregateData] = useState(null);

	const [detailedData, setDetailedData] = useState(null);

	const [categories, setCategories] = useState(null);

	useEffect(() => {
		requestHandler.getCategories().then((response) => {
			if (response.ok) {
				response.json().then((categoryData) => {
					setCategoryId(categoryData.categories[0].id);
					setCategories(categoryData.categories);
				});
			}
		});
		authRequest("/getClothingAnalytics", "post").then((response) => {
			if (response.ok) {
				response.json().then((data) => {
					setAggregateData({
						costData: formatData(data.data, "Total Value ($)"),
						wearData: formatData(
							data.data,
							"Average Wears",
							"wears"
						),
						cpwData: formatData(
							data.data,
							"Average CPW ($)",
							"cpw"
						),
						countData: formatData(
							data.data,
							"Total Pieces",
							"count"
						),
					});
				});
			}
		});
	}, []);

	const [categoryId, setCategoryId] = useState(null);

	useEffect(() => {
		if (categoryId) {
			const formData = new FormData();
			formData.append("categoryId", categoryId);
			authRequest("/getDetailedClothingAnalytics", "post", formData).then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							setDetailedData({
								costData: formatData(
									data.data,
									"Total Value ($)"
								),
								wearData: formatData(
									data.data,
									"Average Wears",
									"wears"
								),
								cpwData: formatData(
									data.data,
									"Average CPW ($)",
									"cpw"
								),
								countData: formatData(
									data.data,
									"Total Pieces",
									"count"
								),
							});
						});
					}
				}
			);
		}
	}, [categoryId]);

	const [loading, setLoading] = useState(true);

	return !aggregateData || !detailedData ? (
		<Spinner />
	) : (
		<div className="flex flex-col">
			<div className="text-3xl sm:text-4xl font-bold text-center mt-4 mb-8 sm:mt-8 sm:mb-12">
				Metrics by Category
			</div>
			<div className="flex flex-col 2xl:flex-row w-full">
				<div className="flex flex-col sm:flex-row w-full mb-0 sm:mb-4 mr-0 2xl:mr-4">
					<ChartCard
						title="Total Value"
						data={aggregateData.costData}
						className="mr-0 sm:mr-4"
					/>

					<ChartCard
						title="Average Wears"
						data={aggregateData.wearData}
						seriesColor={1}
					/>
				</div>
				<div className="flex flex-col sm:flex-row w-full mb-0 sm:mb-4">
					<ChartCard
						title="Average Cost Per Wear"
						data={aggregateData.cpwData}
						className="mr-0 sm:mr-4"
						seriesColor={2}
					/>
					<ChartCard
						title="Total Pieces"
						data={aggregateData.countData}
						seriesColor={3}
					/>
				</div>
			</div>
			<div className="flex flex-col mt-12 mb-16 sm:mt-8 sm:mb-12">
				<div className="text-3xl sm:text-4xl font-bold text-center mb-4">
					Metrics by Subcategory
				</div>
				<div className="flex flex-wrap w-full justify-center">
					{categories &&
						categories.map((category) => (
							<div key={category.id} className="p-2">
								<div
									onClick={() => setCategoryId(category.id)}
									className={`${
										category.id == categoryId
											? "border-black bg-black text-white font-bold"
											: "border-gray-400"
									} cursor-pointer p-2 w-24 border font-light text-sm rounded-md text-center`}
								>
									{category.name}
								</div>
							</div>
						))}
				</div>
			</div>

			<div className={`flex flex-col 2xl:flex-row w-full`}>
				<div className="flex flex-col sm:flex-row w-full mb-0 sm:mb-4 mr-0 2xl:mr-4">
					<ChartCard
						title="Total Value"
						data={detailedData.costData}
						className="mr-0 sm:mr-4"
					/>

					<ChartCard
						title="Average Wears"
						data={detailedData.wearData}
						seriesColor={1}
					/>
				</div>
				<div className="flex flex-col sm:flex-row w-full mb-0 sm:mb-4">
					<ChartCard
						title="Average Cost Per Wear"
						data={detailedData.cpwData}
						className="mr-0 sm:mr-4"
						seriesColor={2}
					/>
					<ChartCard
						title="Total Pieces"
						data={detailedData.countData}
						seriesColor={3}
					/>
				</div>
			</div>
		</div>
	);
};
