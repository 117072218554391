import React from "react";
import { Container } from "../components/general/universal/Container";
import { ViewOutfitsComponent } from "../components/outfits/viewOutfits/ViewOutfitsComponent";
import { useParams } from "react-router-dom";
import { defaultPadding } from "../helpers/classNames";

const ViewLookbook = () => {
	const { lookbookId } = useParams();
	return (
		<Container>
			<ViewOutfitsComponent
				type="outfits"
				add=""
				lookbookId={lookbookId}
			/>
		</Container>
	);
};

export default ViewLookbook;
