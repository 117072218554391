export const AppReducer = (state, action) => {
	switch (action.type) {
		case "hide_footer": {
			return {
				...state,
				footer: false,
			};
		}
		case "show_footer": {
			return {
				...state,
				footer: true,
			};
		}
		case "set_clothingRedirect": {
			return {
				...state,
				clothingRedirect: action.clothingRedirect,
			};
		}
		case "set_outfitRedirect": {
			return {
				...state,
				outfitRedirect: action.outfitRedirect,
			};
		}
		default:
			return state;
	}
};

export const initialState = {
	footer: true,
	clothingRedirect: null,
	outfitRedirect: null,
};
