import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Cards } from "./Cards";
import { ColorButton } from "../general/misc/ColorButton";
import { useNavigate } from "react-router-dom";
import Clothing from "../../assets/clothing.png-comp.jpg";
import ClothingOverview from "../../assets/homepage/clothing/clothing_overview.png-comp.jpg";
import MobileClothingOverview from "../../assets/homepage/mobile_clothing/mobile_clothing_overview.png-comp.jpg";
import ClothingFilter from "../../assets/homepage/clothing/category_filter.png-comp.jpg";
import MobileClothingFilter from "../../assets/homepage/mobile_clothing/mobile_clothing_filter.png-comp.jpg";
import ClothingSort from "../../assets/homepage/clothing/clothing_sort.png-comp.jpg";
import MobileClothingSort from "../../assets/homepage/mobile_clothing/mobile_clothing_sort.png-comp.jpg";
import ClothingOwned from "../../assets/homepage/clothing/clothing_owned.png-comp.jpg";
import MobileClothingOwned from "../../assets/homepage/mobile_clothing/mobile_clothing_owned.png-comp.jpg";
import Outfit from "../../assets/outfit.png-comp.jpg";
import Lookbook from "../../assets/lookbook.png-comp.jpg";
import { Spinner } from "../general/universal/Spinner";
import { Footer } from "../general/universal/Footer";
import OutfitCreation from "../../assets/homepage/outfits/outfit_creation.png-comp.jpg";
import MobileOutfitCreation from "../../assets/homepage/mobile_outfit/mobile_outfit_creation.png-comp.jpg";
import OutfitsOverview from "../../assets/homepage/outfits/outfits_overview.png-comp.jpg";
import MobileOutfitsOverview from "../../assets/homepage/mobile_outfit/mobile_outfit_overview.png-comp.jpg";
import OutfitsFilter from "../../assets/homepage/outfits/category_filter.png-comp.jpg";
import MobileOutfitsFilter from "../../assets/homepage/mobile_outfit/mobile_outfit_filter.png-comp.jpg";
import OutfitsOwnership from "../../assets/homepage/outfits/ownership.png-comp.jpg";
import MobileOutfitsOwnership from "../../assets/homepage/mobile_outfit/mobile_outfit_ownership.png-comp.jpg";
import OutfitsByClothing from "../../assets/homepage/outfits/outfits_by_clothing.png-comp.jpg";
import MobileOutfitsByClothing from "../../assets/homepage/mobile_outfit/mobile_outfit_byclothing.png-comp.jpg";
import { ImageCarousel } from "../tutorials/ImageCarousel";
import { importAll } from "../../helpers/miscHelper";
import { ToyOutfitExample } from "./ToyOutfitExample";

const imgClass = "p-2 h-48 rounded-md object-contain";

export const HomeComponent = () => {
	const navigate = useNavigate();

	const SingleImageWithText = ({
		imgSrc = "",
		mobileImgSrc = "",
		header = "Header",
		body = "Body",
		swapped = false,
		textColor = "text-green-700",
	}) => {
		return (
			<div className="flex flex-col lg:flex-row items-center justify-center w-full pt-32">
				<Fade>
					<div
						className={`${
							swapped ? "order-2" : "order-1"
						} flex flex-col justify-center text-center lg:text-left w-full lg:w-1/3 ${
							swapped ? "ml-0 lg:ml-8" : "mr-0 lg:mr-8"
						} mb-8 lg:mb-0`}
					>
						<span
							className={`text-2xl ${textColor} font-bold pb-2`}
						>
							{header}
						</span>
						<span className="text-base font-light  mt-2">
							{body}
						</span>
					</div>
				</Fade>

				<Fade
					left={swapped ? true : false}
					right={swapped ? false : true}
				>
					<img
						className={`hidden sm:block ${
							swapped ? "order-2 lg:order-1" : "order-2"
						} lg:w-[700px] lg:h-[330px] xl:w-[900px] xl:h-[425px] 2xl:w-[1200px] 2xl:h-[566px] object-contain border border-gray-400 shadow-lg rounded-md bg-white`}
						src={imgSrc}
						loading="lazy"
					/>
					<img
						className={`block sm:hidden ${
							swapped ? "order-2 lg:order-1" : "order-2"
						} w-[320px] h-[634px] object-contain border border-gray-400 shadow-lg rounded-md bg-white`}
						src={mobileImgSrc}
						lazy="lazy"
					/>
				</Fade>
			</div>
		);
	};

	const SegmentContainer = ({ children }) => {
		return (
			<div className="flex w-full justify-center px-2 sm:px-6 md:px-12 pt-24">
				<div className="w-full max-w-[1500px] flex flex-col md:flex-row">
					{children}
				</div>
			</div>
		);
	};

	const SegmentHeader = ({ header = "", body = "", textColor = "" }) => {
		return (
			<>
				<div
					className={`mb-2 font-bold text-4xl ${textColor} border-b border-gray-400 pb-2`}
				>
					{header}
				</div>
				<div className="text-base font-light text-gray-700">{body}</div>
			</>
		);
	};

	const clothingTheme = {
		text: "text-green-700",
		border: "border-green-700",
	};
	const outfitTheme = { text: "text-blue-700", border: "border-blue-700" };
	const lookbookTheme = { text: "text-red-600", border: "border-red-700" };

	const lookbookCarouselImages = importAll(
		require.context("../../assets/homepage/lookbooks/comp", false, /\.png/)
	);

	const mobileLookbookCarouselImages = importAll(
		require.context(
			"../../assets/homepage/mobile_lookbook/comp",
			false,
			/\.png/
		)
	);

	return (
		<div className="flex flex-col w-full self-center justify-center items-center">
			<SegmentContainer>
				<div className="flex flex-col flex-grow justify-center items-center w-full md:w-1/3 mr-0 md:mr-8 mb-8 md:mb-0 ">
					<div className="self-center flex flex-col text-center md:text-left">
						<div className="mb-2 text-4xl font-bold border-b border-gray-400 pb-4">
							Outfit planning, online.
						</div>
						<div className="text-base font-light  text-gray-700 mt-1">
							OutfitOrg (short for "outfit organizer") is the
							digital solution to organizing your clothing and
							planning better outfits.
						</div>
						<div
							className="mt-4 self-center md:self-start"
							onClick={() => navigate("/signup")}
						>
							<ColorButton
								className="w-32 text-base"
								label={"Sign Up"}
								type=""
							/>
						</div>
					</div>
				</div>
				<Fade>
					<div className="flex justify-between w-full h-[200px] md:h-[250px] lg:h-[350px] md:w-2/3">
						<img
							className="object-contain w-[30%] bg-white border border-gray-300 rounded-md shadow-lg"
							src={Clothing}
						/>

						<img
							className="object-contain  w-[30%] bg-white  border border-gray-300 rounded-md shadow-lg"
							src={Outfit}
						/>

						<img
							className="object-contain  w-[30%] bg-white border border-gray-300 rounded-md shadow-lg"
							src={Lookbook}
						/>
					</div>
				</Fade>
			</SegmentContainer>
			<SegmentContainer>
				<div className="flex flex-col w-full items-center">
					<SegmentHeader
						header="Interactive Demo"
						body="Give this fully interactive demo a try and get a taste of what the
            app is like!"
					/>
					<ToyOutfitExample />
				</div>
			</SegmentContainer>

			<SegmentContainer>
				<div className="flex flex-col w-full items-center">
					<SegmentHeader
						header="Clothing"
						body="Digitize your wardrobe."
						textColor={clothingTheme.text}
					/>
					<SingleImageWithText
						imgSrc={ClothingOverview}
						mobileImgSrc={MobileClothingOverview}
						header="Your closet, accessible anywhere."
						body="Store all your clothing in one
                    place, see how many pieces you own, and see their total
                    value."
					/>
					<SingleImageWithText
						imgSrc={ClothingFilter}
						mobileImgSrc={MobileClothingFilter}
						header="Discover things you never know about your wardrobe."
						body="For example, how many jackets you own, or how much
              money you've spent on shoes. Whatever it is, find out using OutfitOrg."
						swapped={true}
					/>
					<SingleImageWithText
						imgSrc={ClothingSort}
						mobileImgSrc={MobileClothingSort}
						header="Sort using intuitive metrics."
						body="Find your most expensive jacket, your least expensive pair
              of pants, your most worn piece of jewelry, and much more using the power of OutfitOrg."
					/>
					<SingleImageWithText
						imgSrc={ClothingOwned}
						mobileImgSrc={MobileClothingOwned}
						header="The internet is your wardrobe."
						body="Don't own a piece of clothing? No problem. Add things you
              don't own through OutfitOrg to make the outfits of your
              dreams!"
						swapped={true}
					/>
				</div>
			</SegmentContainer>

			<SegmentContainer>
				<div className="flex flex-col w-full items-center">
					<SegmentHeader
						header="Outfits"
						body="Effortless creation and organization."
						textColor={outfitTheme.text}
					/>
					<SingleImageWithText
						imgSrc={OutfitCreation}
						mobileImgSrc={MobileOutfitCreation}
						header="Effortless outfit creation."
						textColor={outfitTheme.text}
						body="Our intuitive outfit creation system makes it easy to build the
            outfits you want. No more worrying about positioning and
            centering. No need to drag and drop. Seamless piece swapping.
            All with a high degree of personalization."
					/>
					<SingleImageWithText
						swapped={true}
						imgSrc={OutfitsOverview}
						mobileImgSrc={MobileOutfitsOverview}
						header="Powerful analytics."
						textColor={outfitTheme.text}
						body="See the cost of each outfit, the average cost of all outfits, and what your most/least expensive outfit is through OutfitOrg. "
					/>
					<SingleImageWithText
						imgSrc={OutfitsFilter}
						mobileImgSrc={MobileOutfitsFilter}
						header="Unrivaled categorization."
						textColor={outfitTheme.text}
						body="Find out how many outfits you have with a specific type of piece. For example, see how many outfits you have that include a bomber jacket."
					/>
					<SingleImageWithText
						swapped={true}
						imgSrc={OutfitsOwnership}
						mobileImgSrc={MobileOutfitsOwnership}
						header="Limitless possibilities."
						textColor={outfitTheme.text}
						body="Use any piece that you can find an image for to create the outfits of your dreams!"
					/>
					<SingleImageWithText
						imgSrc={OutfitsByClothing}
						mobileImgSrc={MobileOutfitsByClothing}
						header="Find your favorite outfit with your favorite piece."
						textColor={outfitTheme.text}
						body="Have a specific piece you want to wear but don't know what to wear with it? Use OutfitOrg to see what outfits you've created with any specific piece of clothing!"
					/>
				</div>
			</SegmentContainer>

			<SegmentContainer>
				<div className="flex flex-col w-full items-center">
					<SegmentHeader
						header="Lookbooks"
						body="Outfits for any mood."
						textColor={lookbookTheme.text}
					/>
					<div className="flex flex-col justify-center items-center w-full mt-24">
						<Fade>
							<div className="w-[320px] h-[600px] sm:w-[600px] sm:h-[300px] md:w-[800px] md:h-[400px] lg:w-[1000px] lg:h-[500px] xl:w-[1200px] xl:h-[600px] 2xl:w-[1500px] 2xl:h-[750px]">
								<ImageCarousel
									className="hidden sm:block"
									images={lookbookCarouselImages}
								/>
								<ImageCarousel
									className="block sm:hidden"
									images={mobileLookbookCarouselImages}
								/>
							</div>
						</Fade>
						{
							<Fade>
								<div className="flex flex-col w-full items-center  text-center lg:text-start mt-24">
									<span
										className={`text-2xl ${lookbookTheme.text} font-bold pb-2`}
									>
										Your outfits, grouped by you.
									</span>
									<span className="text-base font-light  mt-2">
										Create lookbooks for anything. Video
										game characters, movies, aesthetics,
										pieces, brands, and whatever else you
										can think of!
									</span>
								</div>
							</Fade>
						}
					</div>
				</div>
			</SegmentContainer>
			<SegmentContainer>
				<Fade>
					<div className="flex flex-col w-full items-center py-12 lg:py-24 mb-12 lg:mb-24 border border-gray-400 rounded-md shadow-lg ">
						<div className="text-3xl lg:text-5xl font-bold mb-4 bg-gradient-to-r from-green-500 via-blue-500 to-red-500 bg-clip-text text-transparent p-2">
							Sign up now!
						</div>
						<div className="flex w-full  justify-center items-center">
							<ColorButton
								onClick={() => navigate("/signup")}
								className="w-32 text-base shadow-lg"
								label={"Sign Up"}
								type=""
							/>
						</div>
					</div>
				</Fade>
			</SegmentContainer>

			{/*<Cards />*/}
			<div className="w-full">
				<Footer />
			</div>
		</div>
	);
};
