import React, { useState, useEffect } from "react";
import { requestHandler } from "../../../helpers/requests";
import { OutfitCard } from "./OutfitCard";
import { ItemsView } from "../../general/itemsview/ItemsView";
import { LookbookNameWidget } from "../../lookbooks/LookbookNameWidget";
import { ViewerContext } from "../../../contexts/Generic";
import { ViewerProvider } from "../../../contexts/Generic";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "../../general/universal/Spinner";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { ClothingWidget } from "../../clothing/ClothingWidget";

export const ViewOutfitsComponent = ({
	lookbookId = null,
	pieceIds = null,
	local = false,
	type = "outfits",
	cardType = "",
	onCardClick = null,
	className = "",
	add = "/addOutfit",
	cardCheck = () => false,
}) => {
	const navigate = useNavigate();

	const [lookbook, setLookbook] = useState(null);
	const [piece, setPiece] = useState(null);

	const [error, setError] = useState(null);
	useErrorHandler(error);

	const costFunc = (cost) => {
		return `, Average Cost: $${cost ? cost.toFixed(2) : "0.00"}`;
	};

	const cardFunc = (Outfit) => {
		return (
			<OutfitCard
				cardType={cardType}
				key={Outfit.id}
				Outfit={Outfit}
				onCardClick={onCardClick}
				cardCheck={cardCheck}
			/>
		);
	};

	useEffect(() => {
		if (lookbookId) {
			const formData = new FormData();
			formData.append("id", lookbookId);
			requestHandler.getLookbook(formData).then(
				(response) => {
					if (response.ok) {
						response.json().then((data) => {
							if (!data.lookbook) {
								navigate("/lookbooks");
							} else {
								setLookbook(data.lookbook);
							}
						});
					} else {
						setError(response);
					}
				},
				(error) => setError(error)
			);
		}
	}, []);

	return (lookbookId ? !lookbook : false) ? (
		<Spinner />
	) : (
		<ViewerProvider>
			<div className={`${className} flex flex-col flex-grow `}>
				<LookbookNameWidget lookbook={lookbook} />
				<ItemsView
					local={local}
					fetchItemsFunc={requestHandler.getOutfits}
					fetchReqItems={
						lookbookId
							? { lookbookId: lookbookId }
							: pieceIds
							  ? { pieceIds: JSON.stringify(pieceIds) }
							  : null
					}
					context={ViewerContext}
					cost={costFunc}
					card={cardFunc}
					add={add}
					type={type}
					itemNames={{ single: "outfit", plural: "outfits" }}
					searchPlaceholder="Search outfits by piece name or brand..."
				/>
			</div>
		</ViewerProvider>
	);
};
