import React from "react";
import { ViewOutfitsReducer, initialState } from "./ViewOutfitsReducer";

export const ViewOutfitsContext = React.createContext({
	state: initialState,
	dispatch: () => null,
});

export const ViewOutfitsProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(
		ViewOutfitsReducer,
		initialState
	);

	return (
		<ViewOutfitsContext.Provider value={[state, dispatch]}>
			{children}
		</ViewOutfitsContext.Provider>
	);
};
