import React from "react";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { addParam, removeParam } from "../../../../helpers/searchParamsHelper";
import { useState, useEffect } from "react";
import { ViewerContext } from "../../../../contexts/Generic";
import { filterHeaderClass, FilterItem } from "./CategoryFilterComponent";

export const SortComponent = ({ className = "", context = ViewerContext }) => {
	const [contextState, dispatch] = React.useContext(context);
	const [searchParams, setParams] = useSearchParams({});
	const local = contextState.local;
	const sort = local
		? contextState.sort
			? contextState.sort
			: contextState.defaultSort
		: searchParams.get("sort")
		? searchParams.get("sort")
		: contextState.defaultSort;
	const type = contextState.type;

	const sortTypes = {
		clothing: [
			{ type: "updatedAt", name: "Modified" },
			{ type: "startDate", name: "Date Acquired" },
			{ type: "lastWorn", name: "Last Worn Date" },
			{ type: "cpw", name: "Cost Per Wear" },
			{ type: "cost", name: "Cost" },
			{ type: "wears", name: "# of Wears" },
		],
		outfits: [
			{ type: "updatedAt", name: "Modified" },
			{ type: "cost", name: "Cost" },
			{ type: "name", name: "Name" },
		],
		lookbooks: [
			{ type: "updatedAt", name: "Modified" },
			{ type: "name", name: "Name" },
			{ type: "count", name: "Outfit Count" },
		],
	};

	const metricTypes = [
		{ type: "startDate", name: "Date Acquired" },
		{ type: "lastWorn", name: "Last Worn Date" },
		{ type: "cpw", name: "Cost Per Wear" },
		{ type: "cost", name: "Cost" },
		{ type: "wears", name: "# of Wears" },
	];

	useEffect(() => {
		if (
			sortArray.findIndex(
				(sortTypeObj) =>
					sort == `${sortTypeObj.type}-asc` ||
					sort == `${sortTypeObj.type}-desc`
			) < 0
		) {
			removeParam(searchParams, "sort");
		}
	}, [sort]);

	const sortArray =
		sortTypes[type] == undefined ? sortTypes.clothing : sortTypes[type];

	const changeSort = (sortType) => {
		var newSortType = "";
		if (sort.includes(sortType)) {
			// already sorting by cost/name
			newSortType =
				sort == `${sortType}-asc`
					? `${sortType}-desc`
					: `${sortType}-asc`;
		} else {
			// change sort type
			newSortType = `${sortType}-desc`;
		}

		local
			? dispatch({ type: "set_sort", sort: newSortType })
			: setParams(addParam(searchParams, "sort", newSortType));
	};

	const addRemoveMetricType = (type) => {
		let fields = [...contextState.displayFields];
		if (contextState.displayFields.includes(type)) {
			fields.splice(
				fields.findIndex((field) => field == type),
				1
			);
		} else {
			fields.push(type);
		}
		let newLocalStorageFields = JSON.stringify(fields);
		localStorage.setItem("displayFields", newLocalStorageFields);
		dispatch({ type: "set_displayFields", fields });
	};

	return (
		<div className={`${className} flex flex-col select-none`}>
			<div className={filterHeaderClass}>Sort</div>
			<div className={contextState.includeMetricTypes ? "mb-4" : ""}>
				{sortArray.map((typeObj) => {
					return (
						<FilterItem
							key={typeObj.name}
							label={typeObj.name}
							onClick={() => changeSort(typeObj.type)}
							selected={sort.includes(typeObj.type)}
						>
							<span className="text-blue-700">
								{sort == `${typeObj.type}-desc` ? (
									<IoArrowDownOutline size={15} />
								) : sort == `${typeObj.type}-asc` ? (
									<IoArrowUpOutline size={15} />
								) : (
									""
								)}
							</span>
						</FilterItem>
					);
				})}
			</div>
			{contextState.includeMetricTypes && (
				<>
					<div className={filterHeaderClass}>Metrics</div>
					{metricTypes.map((metricObj) => {
						return (
							<FilterItem
								key={metricObj.name}
								label={metricObj.name}
								onClick={() =>
									addRemoveMetricType(metricObj.type)
								}
								selected={contextState.displayFields.includes(
									metricObj.type
								)}
							></FilterItem>
						);
					})}
				</>
			)}
		</div>
	);
};
