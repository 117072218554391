/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Outfit as OutfitElement } from "../Outfit"; // OutfitElement is used since Outfit is a prop
import { NoItemCard } from "../../general/misc/NoItemCard";
import { AppContext } from "../../../contexts/App";

export const OutfitCard = ({
	Outfit = null,
	nohover = false,
	onCardClick = null,
	cardType = "",
	individual = false,
	cardCheck = (Outfit) => false,
}) => {
	const navigate = useNavigate();
	const [appState, dispatch] = useContext(AppContext);

	const costOfFit = (outfit) => {
		var fitCost = 0;
		outfit.OutfitPieces.forEach((OutfitPiece) => {
			if (OutfitPiece.Clothing) {
				fitCost = fitCost + OutfitPiece.Clothing.cost;
			}
		});
		return fitCost;
	};

	const handleClick = (id) => {
		dispatch({
			type: "set_outfitRedirect",
			outfitRedirect: window.location.href.split("?")[1],
		});
		navigate(`/viewOutfit/${id}`);
	};

	const [opacityState, setOpacityState] = useState(true);

	/*useEffect(() => {
    setTimeout(() => {
      // hack to get fade in on load to work - doesn't work without timeout due to... reasons
      setOpacityState(true);
    }, 10);
  }, []);*/

	return !Outfit ? (
		<NoItemCard />
	) : (
		<div
			className={`w-full h-full transition-border duration-75 border border-transparent ${
				nohover
					? ""
					: cardCheck(Outfit)
					  ? "opacity-40 cursor-not-allowed"
					  : "cursor-pointer sm:hover:border-gray-400"
			} py-4`}
		>
			<div
				onClick={
					nohover
						? null
						: onCardClick
						  ? () => onCardClick(Outfit)
						  : () => handleClick(Outfit.id)
				}
				className={`flex flex-col w-full h-full select-none items-center`}
			>
				<div
					className={`flex w-full h-full transition-all duration-300 ${
						opacityState ? "opacity-100" : "opacity-0"
					} justify-center items-center mb-2`}
				>
					<OutfitElement
						type={cardType}
						individual={individual}
						Outfit={Outfit}
					/>
				</div>
				<div className="flex flex-col justify-end items-center px-4 mb-2 font-light text-center w-full">
					<span className="w-full truncate">
						{Outfit.name != "" ? Outfit.name : <span>&nbsp;</span>}
					</span>
					<span className="w-full truncate text-xs">
						${costOfFit(Outfit)}
					</span>
				</div>
			</div>
		</div>
	);
};
