import { useState, useEffect, useMemo } from "react";
import { ClothingRowCard } from "./ClothingRowCard";
import { ViewClothingComponent } from "../clothing/ViewClothingComponent";
import { AxisOptions, Chart } from "react-charts";

export const StyledChart = ({
  data = null,
  title = "Chart Title",
  className = "",
  seriesColor = 0,
}) => {
  const memoData = useMemo(() => data, [data]);

  const primaryAxis = useMemo(
    () => ({
      getValue: (datum: any) => datum.primary,
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum: any) => parseFloat(datum.secondary) as number,
      },
    ],
    []
  );

  const [{ activeSeriesIndex, activeDatumIndex }, setState] = useState({
    activeSeriesIndex: -1,
    activeDatumIndex: -1,
  });

  return (
    <div className="w-full h-48 sm:h-72 2xl:h-96 mb-12">
      <div className="flex justify-center text-md font-light mb-4">{title}</div>

      {memoData && (
        <Chart
          options={{
            data: memoData,
            primaryAxis,
            secondaryAxes,
            onFocusDatum: (focused) =>
              setState({
                activeSeriesIndex: focused ? focused.seriesIndex : -1,
                activeDatumIndex: focused ? focused.index : -1,
              }),
            getSeriesStyle: (series) => {
              return {
                color: `url(#${(series.index + seriesColor) % 6})`,
                opacity:
                  activeSeriesIndex > -1
                    ? series.index === activeSeriesIndex
                      ? 1
                      : 0.3
                    : 1,
              };
            },
            renderSVG: () => (
              <defs>
                <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#1d4ed8" />
                  <stop offset="100%" stopColor="#2563eb" />
                </linearGradient>
                <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#047857" />
                  <stop offset="100%" stopColor="#059669" />
                </linearGradient>
                <linearGradient id="2" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#e11d48" />
                  <stop offset="100%" stopColor="#f43f5e" />
                </linearGradient>
                <linearGradient id="3" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#d97706" />
                  <stop offset="100%" stopColor="#f59e0b" />
                </linearGradient>
                <linearGradient id="4" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#7c3aed" />
                  <stop offset="100%" stopColor="#8b5cf6" />
                </linearGradient>
                <linearGradient id="5" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#db2777" />
                  <stop offset="100%" stopColor="#ec4899" />
                </linearGradient>
              </defs>
            ),
          }}
        />
      )}
    </div>
  );
};
