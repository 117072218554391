import React from "react";
import { Container } from "../components/general/universal/Container";
import { ViewOutfitsComponent } from "../components/outfits/viewOutfits/ViewOutfitsComponent";
import { ViewerContext } from "../contexts/Generic";
import { ViewerProvider } from "../contexts/Generic";
import { defaultPadding } from "../helpers/classNames";

const ViewOutfits = () => {
	return (
		<ViewerProvider>
			<Container>
				<ViewOutfitsComponent
					type="outfits"
					add="/addOutfit"
					context={ViewerContext}
				/>
			</Container>
		</ViewerProvider>
	);
};

export default ViewOutfits;
