import React from "react";
import { Container } from "../components/general/universal/Container";
import { AdminComponent } from "../components/login/AdminComponent";

const Admin = () => {
	return (
		<Container>
			<AdminComponent />
		</Container>
	);
};

export default Admin;
