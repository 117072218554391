import React from "react";
import { Container } from "../components/general/universal/Container";
import { AddLookbookComponent } from "../components/lookbooks/addLookbook/AddLookbookComponent";
import { LookbookProvider } from "../contexts/Lookbooks/AddLookbook";
import { LookbookContext } from "../contexts/Lookbooks/AddLookbook";

const AddLookbook = () => {
	return (
		<LookbookProvider>
			<Container showFooter={false}>
				<AddLookbookComponent />
			</Container>
		</LookbookProvider>
	);
};

export default AddLookbook;
