import React, { useState, useRef, useEffect } from "react";

import { IoAdd } from "react-icons/io5";

import ReactCrop, {
	centerCrop,
	makeAspectCrop,
	Crop,
	PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import { ClothingContext } from "../../contexts/Clothing";
import { ColorButton } from "../general/misc/ColorButton";
import "react-image-crop/dist/ReactCrop.css";
import { FixedOverlay } from "../general/misc/FixedOverlay";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

export const ImageCropComponent = ({
	context = ClothingContext,
	className = "",
	defaultImg = null,
	noImage = false,
}) => {
	const [clothingState, dispatch] = React.useContext(context);
	const [imgSrc, setImgSrc] = useState("");
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);
	const [crop, setCrop] = useState();
	const [cropOverlay, setCropOverlay] = useState(false);
	const [intermediateCrop, setIntermediateCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [aspect, setAspect] = useState();

	function resetFile() {
		dispatch({ type: "set_fileName", fileName: "" });
		setImgSrc("");
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			const fileRegex = new RegExp("image/*");
			const image = fileRegex.test(e.target.files[0].type);
			// check if file is indeed an image
			if (!image) {
				resetFile();
				setErrorMessage(
					"File is not an image! Accepted formats: jpg, jpeg, png, webp"
				);
				return;
			} else if (e.target.files[0].size > 5000000) {
				resetFile();
				setErrorMessage("File is too big! (Max. file size 5MB)");
				return;
			} else {
				setErrorMessage("");
				setCrop(undefined); // Makes crop preview update between images.
				dispatch({
					type: "set_fileName",
					fileName: e.target.files[0].name,
				});
				dispatch({ type: "set_imageChanged" });
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					setImgSrc(reader.result?.toString() || "");
				});
				reader.readAsDataURL(e.target.files[0]);
			}
		}
	}

	useEffect(() => {
		if (defaultImg) {
			setImgSrc(defaultImg);
		}
	}, [defaultImg]);

	useEffect(() => {
		var img = new Image();
		img.src = imgSrc;
		img.crossOrigin = "anonymous";
		var loadImg = new Promise((res) => {
			if (img.complete) {
				return res();
			}
			img.onload = () => res();
			img.onerror = () => res();
		});
		loadImg
			.then(() => {
				dispatch({ type: "update_data_url", url: imgSrc });
				if (previewCanvasRef.current) {
					canvasPreview(
						img,
						previewCanvasRef.current,
						{
							x: 0,
							y: 0,
							width: img.width,
							height: img.height,
							unit: "px",
						},
						scale,
						rotate
					);
				}
			})
			.catch((e) => console.log(e));
	}, [imgSrc]);

	function onImageLoad(e) {
		const { width, height } = e.currentTarget;
		if (aspect) {
			setCrop(centerAspectCrop(width, height, aspect));
		} else {
			setCrop(
				centerCrop(
					{ x: 0, y: 0, width: 98, height: 98, unit: "%" },
					width,
					height
				)
			);
		}
	}

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale,
					rotate
				);

				dispatch({
					type: "update_data_url",
					url: previewCanvasRef.current.toDataURL(),
				});
				dispatch({ type: "set_imageChanged" });

				if (cropOverlay) {
					setCropOverlay(false);
				}
			}
		},
		0,
		[completedCrop, scale, rotate]
	);

	function handleToggleAspectClick() {
		if (aspect) {
			setAspect(undefined);
		} else if (imgRef.current) {
			const { width, height } = imgRef.current;
			setAspect(16 / 9);
			setCrop(centerAspectCrop(width, height, 16 / 9));
		}
	}

	const sus = "p-6 bg-white flex flex-col items-center";

	const [errorMessage, setErrorMessage] = useState("");

	return (
		<div className={`${className} flex flex-col items-center select-none`}>
			<FixedOverlay
				modalHeader="Crop Image"
				parentState={cropOverlay}
				setParentState={setCropOverlay} // virgin typescript nerd vs. chad any enjoyer
				disableScrolling={true}
				modalWidth="w-[95%] lg:w-1/2"
				modalHeight="h-auto max-h-[95%]"
			>
				{Boolean(imgSrc) && (
					<div className={sus}>
						<ReactCrop
							crop={crop}
							onChange={(_, percentCrop) => setCrop(percentCrop)}
							onComplete={(c) => setIntermediateCrop(c)}
							className="w-5/6 lg:w-3/4 xl:w-1/2 border-2 border-gray-500 mb-6"
							//aspect={aspect}
						>
							<div className="object-contain">
								<img
									ref={imgRef}
									crossOrigin="anonymous"
									alt="Crop me"
									src={imgSrc}
									style={{
										transform: `scale(${scale}) rotate(${rotate}deg)`,
									}}
									className="w-full h-full object-contain"
									onLoad={onImageLoad}
								/>
							</div>
						</ReactCrop>
						<div className="flex w-full justify-center">
							<ColorButton
								onClick={() => {
									setCompletedCrop(intermediateCrop);
									if (intermediateCrop === completedCrop) {
										setCropOverlay(false);
									}
								}}
								type="green"
								className="w-32 self-center mr-2"
								label="Set Crop"
							/>
							<ColorButton
								label="Close"
								className="w-32"
								onClick={() => setCropOverlay(false)}
							/>
						</div>
					</div>
				)}
			</FixedOverlay>
			{imgSrc ? (
				<ColorButton
					onClick={() => setCropOverlay(true)}
					className="mb-2"
					label="Crop"
				/>
			) : (
				""
			)}
			<div
				className={`w-64 h-64 sm:w-64 sm:h-64 md:w-72 md:h-72 lg:w-72 lg:h-72 mb-1 border ${
					noImage ? "border-red-600" : "border-black"
				} rounded-md overflow-hidden`}
			>
				<label className="w-full h-full rounded-md cursor-pointer">
					<input
						className="hidden"
						type="file"
						accept="image/*"
						onChange={onSelectFile}
					/>
					{!Boolean(imgSrc) ? (
						<div
							className={`h-full w-full flex items-center justify-center ${
								noImage ? "text-red-600" : ""
							}`}
						>
							<IoAdd className="" size={26} />
						</div>
					) : (
						Boolean(imgSrc) && (
							<canvas
								ref={previewCanvasRef}
								className="object-contain"
								style={{
									width: "100%", //completedCrop?.width,
									height: "100%", //completedCrop?.height,
								}}
							/>
						)
					)}
				</label>

				{/* <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
        <div>
          <button onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? "off" : "on"}
          </button>
        </div>*/}
			</div>
			<div className="w-full text-sm ">
				{errorMessage ? (
					<span className="text-red-600">{errorMessage}</span>
				) : (
					<span className="text-gray-500">Max. image size 5MB</span>
				)}
			</div>
		</div>
	);
};
