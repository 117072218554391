/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

const bp = {
	xs: 0,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	"2xl": 1536,
};

const mq = (n) => {
	return `@media (min-width: ${bp[n]}px)`;
};

const dimensions = {
	default: {
		xs: 140,
		sm: 140,
		md: 150,
		lg: 125,
		xl: 150,
		"2xl": 190,
	},
	big: {
		xs: 125,
		sm: 150,
		md: 175,
		lg: 200,
		xl: 200,
		"2xl": 245,
	},
	outfitPicker: {
		xs: 50,
		sm: 60,
		md: 60,
		lg: 60,
		xl: 60,
		"2xl": 80,
	},
	pieceAdder: {
		xs: 130,
		sm: 145,
		md: 175,
		lg: 180,
		xl: 180,
		"2xl": 200,
	},
};

const mediaQuery = (
	bp = "xs",
	width = null,
	height = null,
	marginTop = null,
	top = null,
	left = null,
	zIndex = null,
	right = null
) => {
	return `${mq(bp)} {
      ${width != null ? `width: ${width};` : ""}
      ${height != null ? `height: ${height};` : ""}
      ${marginTop != null ? `margin-top: ${marginTop};` : ""}
      ${top != null ? `top: ${top};` : ""}
      ${left != null ? `left: ${left};` : ""}
      ${zIndex != null ? `z-index: ${zIndex};` : ""}
      ${right != null ? `right: ${right};` : ""}
    }`;
};

/* 
  Main function for sizing pieces in outfits. 
  Takes an object, which means the order which variables go in is irrelevant.
  Helps for modifiability.
*/
const cssFunc = ({
	type = "default",
	widthScale = 0,
	heightScale = null,
	topScale = 0,
	leftScale = 0,
	zIndex = 0,
	zoom = 0,
	spacing = 0,
	empty = true,
	noWidth = false,
	noHeight = false,
}) => {
	var cssString = "";
	let boxSide = type ? dimensions[type] : dimensions.default;
	if (boxSide == undefined) {
		boxSide = dimensions.default;
	}

	Object.entries(bp).forEach(([bp, value]) => {
		const scale = parseFloat(boxSide[bp] / 100);
		const boxWidth = boxSide[bp] + widthScale * scale; // scaled boxWidth
		const width = `${noWidth ? 0 : boxWidth + (zoom * boxWidth) / 100}px`; // boxWidth +/- zoom factor

		const height = noHeight
			? "0px"
			: heightScale
			  ? `${boxSide[bp] + heightScale * scale}px` // heightScale is used to prevent height:auto jankiness
			  : empty
			    ? width
			    : "auto"; //`${noHeight ? 0 : boxSide[bp] + heightScale * scale}px`;
		const marginTop = `${spacing * scale}px`;
		const top = topScale * scale;
		const zoomTop = `${top + (zoom * top) / 100}px`;
		const left = leftScale * scale;
		const zoomLeft = `${left + (zoom * left) / 100}px`;
		cssString = `${cssString}\n ${mediaQuery(
			bp,
			width,
			height,
			marginTop,
			zoomTop,
			zoomLeft,
			zIndex
		)}`;
	});

	return css`
		${cssString}
	`;
};

/* Calculates max outfit height & width before outfit overflows */
const cardHeightFunc = (type = "default", zoom = 0) => {
	var cssString = "";
	const boxSide = type ? dimensions[type] : dimensions.default;

	Object.entries(bp).forEach(([bp, value]) => {
		const scale = parseFloat(boxSide[bp] / 100);

		/* 
    
    Max card width is calculated using the middle position + 2 outer positions w/ some scaling 
    Can be defined as whatever, but needs to use boxSide otherwise it won't scale properly when resizing the screen.

    */
		const cardWidth =
			boxSide[bp] + 40 * scale + (boxSide[bp] + -40 * scale) * 2;
		/* 
    
    zoom is a percentage scale, -50 for zoom = 50% smaller. 
    hence why the initial width needs to be calculated, then the zoomed width \
    
    */
		const width = `${cardWidth + (zoom * cardWidth) / 100}px`;
		/*

    Old height calculation used box heights w/ some scaling. New height value is using an aspect ratio.
    Both produce roughtly the same sized card.
    
    const height = `${
      (boxSide[bp] + 40 * scale) * 2 + (boxSide[bp] + -20 * scale) + addOn
    }px`; // max. height slider values for middle 3 pieces are 40, 40, -20
    
    */
		const height = `${1.385 * parseFloat(width)}px`;
		cssString = `${cssString}\n ${mediaQuery(bp, width, height)}`;
	});

	return css`
		${cssString}
	`;
};

/* For showing the margins in outfit creation */
const scaledHeight = (
	type = "default",
	spacing = 0,
	zoom = 0,
	topScale = 0,
	leftScale = 0
) => {
	var cssString = "";
	const boxSide = type ? dimensions[type] : dimensions.default;

	Object.entries(bp).forEach(([bp, value]) => {
		const scale = parseFloat(boxSide[bp] / 100);
		const width = `100%`;
		const height = spacing * scale;
		const zoomHeight = `${height + (zoom * height) / 100}px`;
		const top = topScale * scale;
		const zoomTop = `${top + (zoom * top) / 100}px`;
		const left = leftScale * scale;
		const zoomLeft = `${left + (zoom * left) / 100}px`;
		cssString = `${cssString}\n ${mediaQuery(
			bp,
			width,
			zoomHeight,
			0,
			zoomTop,
			zoomLeft
		)}`;
	});

	return css`
		${cssString}
	`;
};

const scaledPosition = (
	type = "default",
	topScale = 0,
	leftScale = 0,
	zoom = 0
) => {
	var cssString = "";
	const boxSide = type ? dimensions[type] : dimensions.default;

	Object.entries(bp).forEach(([bp, value]) => {
		const scale = parseFloat(boxSide[bp] / 100);
		const top = topScale * scale;
		const zoomTop = `${top + (zoom * top) / 100}px`;
		const left = leftScale * scale;
		const zoomLeft = `${left + (zoom * left) / 100}px`;
		cssString = `${cssString}\n ${mediaQuery(
			bp,
			null,
			null,
			null,
			zoomTop,
			zoomLeft
		)}`;
	});

	return css`
		${cssString}
	`;
};

const scaledLeftRight = (
	type = "default",
	splitScale = 0,
	isLeft = true,
	zoom = 0
) => {
	var cssString = "";
	const boxSide = type ? dimensions[type] : dimensions.default;

	Object.entries(bp).forEach(([bp, value]) => {
		const scale = parseFloat(boxSide[bp] / 100);
		const left = splitScale * scale;
		const zoomLeft = `${left + (zoom * left) / 100}px`;
		cssString = `${cssString}\n ${mediaQuery(
			bp,
			null,
			null,
			null,
			null,
			isLeft ? zoomLeft : null,
			null,
			!isLeft ? zoomLeft : null
		)}`;
	});

	return css`
		${cssString}
	`;
};

export {
	cssFunc,
	cardHeightFunc,
	scaledHeight,
	scaledPosition,
	scaledLeftRight,
};
