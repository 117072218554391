const addParam = (searchParams, newParam, value) => {
	var newParams = {};
	searchParams.set(newParam, value);
	searchParams.forEach(
		(value, key) => (newParams = { ...newParams, [key]: value })
	);
	return newParams;
};

const removeParam = (searchParams, removeParam) => {
	var newParams = {};
	searchParams.delete(removeParam);
	searchParams.forEach(
		(value, key) => (newParams = { ...newParams, [key]: value })
	);
	return newParams;
};

export { addParam, removeParam };
