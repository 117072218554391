import { useState, useEffect } from "react";

function importAll(r) {
	let images = {};
	let index = 0;
	r.keys().map((item) => {
		images[index] = r(item);
		index++;
	});
	return images;
}

const enableScroll = (document) => {
	document.body.style.position = "";
	document.body.style.top = "";
};

const disableScroll = (document, window) => {
	var scrollY = window.scrollY;
	document.body.style.top = `-${scrollY}px`;
	document.body.style.position = "fixed";
};

const adjustDate = (date) => {
	const d = new Date(date);
	const dateStr = d.toLocaleString("en-US", {
		timeZone: "UTC",
	});
	return new Date(dateStr);
};

export { importAll, enableScroll, disableScroll, adjustDate };
