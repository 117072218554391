import React from "react";
import { Container } from "../components/general/universal/Container";
import { HomeComponent } from "../components/home/HomeComponent";

const Home = () => {
	return (
		<Container noPadding={true} showFooter={false}>
			<HomeComponent />
		</Container>
	);
};

export default Home;
