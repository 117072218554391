import React, { useContext, useReducer } from "react";
import GenericJacket from "../../assets/GenericJacket.png";
import { useNavigate, useLocation } from "react-router-dom";
import { NoItemCard } from "../general/misc/NoItemCard";
import { useState, useEffect } from "react";
import { CONFIG } from "../../config";
import { AppContext } from "../../contexts/App";
import { adjustDate } from "../../helpers/miscHelper";

export const MetricString = ({ displayFields, piece }) => {
	const noFields = !(
		displayFields.includes("cost") ||
		displayFields.includes("wears") ||
		displayFields.includes("cpw") ||
		displayFields.includes("startDate") ||
		displayFields.includes("lastWorn")
	);
	const d = adjustDate(piece.startDate);
	const dateString = `${
		(d.getMonth() + 1) % 13
	}/${d.getDate()}/${d.getFullYear()}`;
	const lastWorn = adjustDate(piece.lastWorn);
	const lastWornString = `${
		(lastWorn.getMonth() + 1) % 13
	}/${lastWorn.getDate()}/${lastWorn.getFullYear()}`;
	return !noFields ? (
		<>
			{displayFields.includes("lastWorn") && (
				<div>{lastWornString} (Last Worn)</div>
			)}
			{displayFields.includes("startDate") && (
				<div>{dateString} (Acquired)</div>
			)}
			{displayFields.includes("cpw") && <div>{`$${piece.cpw} CPW`}</div>}
			{displayFields.includes("cost") && <div>{`$${piece.cost}`}</div>}
			{displayFields.includes("wears") && (
				<div>{`${piece.wears} wears`}</div>
			)}
		</>
	) : (
		<div>{`$${piece.cost}`}</div>
	);
};

export const ClothingCard = ({
	className = "",
	type = "clothing",
	displayFields = [],
	piece = null,
	onCardClick = null,
	noHover = false,
	hoverOverlay = null,
	hoverBorder = null,
	cardCheck = (piece) => false,
}) => {
	const [opacityState, setOpacityState] = useState(false);
	const [appState, dispatch] = useContext(AppContext);

	const navigate = useNavigate();
	return !piece ? (
		<NoItemCard />
	) : (
		<div
			onClick={
				noHover
					? null
					: onCardClick
					? () => onCardClick(piece)
					: () => {
							dispatch({
								type: "set_clothingRedirect",
								clothingRedirect:
									window.location.href.split("?")[1],
							});
							navigate(`/editClothing/${piece.id}`, {
								state: window.location.href,
							});
					  }
			}
			className={`${className} group relative flex flex-col w-full h-full transition-border duration-75 flex-shrink items-center justify-center border border-transparent select-none rounded-sm py-2 px-1 ${
				noHover
					? ""
					: cardCheck(piece)
					? "opacity-40 cursor-not-allowed"
					: `sm:hover:border-gray-400 cursor-pointer`
			} `}
		>
			{hoverOverlay ? (
				hoverOverlay
			) : (
				<div className="absolute z-8 top-0 left-0 w-full h-full" />
			)}

			<div className="flex w-full h-full justify-center relative rounded-md p-2 items-center mb-2">
				<img
					onLoad={() => setOpacityState(true)}
					className={`w-full ${
						type == "clothingPicker"
							? "h-20 sm:h-20 md:h-22 lg:h-22 xl:h-22 2xl:h-28"
							: type == "big"
							? "h-64 md:h-72 lg:h-80 xl:h-90"
							: "h-32 sm:h-32 md:h-48 lg:h-64 xl:h-72 2xl:h-72"
					} opacity-0 transition-all duration-300 ${
						opacityState ? "opacity-100" : "opacity-0"
					} object-contain rounded-md`}
					src={
						piece.imageName !== ""
							? piece.static
								? piece.imageName
								: `${CONFIG.IMAGE_URL}${piece.imageName}`
							: GenericJacket
					}
				/>
			</div>
			<div className="flex flex-col w-full items-center text-center text-sm font-light mb-1">
				<div className="w-full font-normal truncate">{piece.brand}</div>
				<div className="w-full text-xs truncate">{piece.name}</div>
				<div className="w-full text-xs truncate whitespace-pre-line">
					<MetricString displayFields={displayFields} piece={piece} />
				</div>
			</div>
		</div>
	);
};
