import React from "react";
import { Container } from "../components/general/universal/Container";
import { ViewIndividualOutfitComponent } from "../components/outfits/viewIndividualOutfit/ViewIndividualOutfitComponent";
import { ViewOutfitsProvider } from "../contexts/Outfits/ViewOutfits";

const ViewOutfits = () => {
	return (
		<ViewOutfitsProvider>
			<Container>
				<ViewIndividualOutfitComponent />
			</Container>
		</ViewOutfitsProvider>
	);
};

export default ViewOutfits;
