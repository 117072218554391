import { useState, useEffect, useContext } from "react";
import { ViewClothingComponent } from "../../clothing/ViewClothingComponent";
import { memo } from "react";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";

export const AddOutfitClothingComponent = memo(
	({ position = null, outfitPieceGrid = null, dispatch = null }) => {
		const addOutfitPiece = (piece) => {
			dispatch({ type: "add_outfit_piece", item: piece });
		};

		const cardAddedCheck = (piece) => {
			var check = false;
			Object.entries(outfitPieceGrid).every(([key, outfitPiece]) => {
				if (outfitPiece.id == piece.id && key == position) {
					check = true;
					return false;
				} else return true;
			});
			return check;
		};
		return (
			<div>
				<ViewClothingComponent
					className="sm:pb-16"
					type="clothingPicker"
					local={true}
					includeMetricTypes={false}
					onCardClick={addOutfitPiece}
					cardCheck={cardAddedCheck}
				/>
			</div>
		);
	}
);

export const AddOutfitClothingComponentWrapper = memo(() => {
	const [state, dispatch] = useContext(AddOutfitContext);
	return (
		<AddOutfitClothingComponent
			position={state.position}
			outfitPieceGrid={state.outfitPieceGrid}
			dispatch={dispatch}
		/>
	);
});
