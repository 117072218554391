import React, { useState, useEffect } from "react";
import { ColorButton } from "./ColorButton";

export const ConfirmationModal = ({
	itemName = "",
	altText = null,
	modalState = false,
	setModalState,
	confirmFunc,
}) => {
	const handleModalClick = () => {
		setModalState(false);
	};

	const handleModalConfirmation = () => {
		confirmFunc();
		setModalState(false);
	};

	return (
		<>
			{modalState && (
				<div
					className={`fixed top-0 left-0 z-40 self-center flex items-center justify-center  w-full h-full`}
				>
					<div
						onClick={handleModalClick}
						className="absolute z-10 cursor-pointer w-full h-full bg-black bg-opacity-70"
					/>
					<div className="absolute z-20 flex flex-col justify-center items-center p-4 shadow-md bg-white border border-black rounded-md">
						<div className="font-light select-none">
							{altText
								? altText
								: `Are you sure you want to delete this${itemName}?`}
						</div>
						<div className="flex mt-4 w-full justify-center">
							<ColorButton
								onClick={handleModalConfirmation}
								type="red"
								className="w-16 mr-4"
								label="Yes"
							/>
							<ColorButton
								onClick={handleModalClick}
								className="w-16"
								label="No"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
