import React, { memo, useCallback } from "react";
import { Slider } from "@mui/material";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";
import { useState, useEffect, useContext } from "react";

const sliderColor = "#047857";

export const MemoizedSlider = memo(
	({
		dispatch = () => {},
		value = 0,
		min = 0,
		max = 69,
		label = "Slider Label",
		color = sliderColor,
		onChange = () => null,
	}) => {
		return (
			<>
				<label className="font-light text-xs lg:text-base">
					{label}
				</label>
				<Slider
					size="small"
					defaultValue={0}
					value={value}
					min={min}
					max={max}
					aria-label="Small"
					type="top"
					onChange={(e) => onChange(e)}
					sx={{
						color: color,
					}}
				/>
			</>
		);
	}
);

export const Sliders = () => {
	const [outfitState, dispatch] = useContext(AddOutfitContext);

	const position = outfitState.position;

	const minWidthValue = position == 1 || position == 2 ? -40 : -90;
	const maxWidthValue = position == 1 || position == 2 ? 40 : 0;

	const minCoordinateValue = -80;
	const maxCoordinateValue = 80;

	const dimensionObj = outfitState.outfitDimensionGrid[position];

	const changeSize = useCallback((event) => {
		dispatch({
			type: "change_dimension",
			value: event.target.value,
			dimension: "widthScale",
		});
	}, []);

	const changeSpacing = useCallback((event) => {
		dispatch({
			type: "change_dimension",
			value: event.target.value,
			dimension: "spacing",
		});
	}, []);

	const changeTop = useCallback((event) => {
		dispatch({
			type: "change_dimension",
			value: event.target.value,
			dimension: "topScale",
		});
	}, []);

	const changeLeft = useCallback((event) => {
		dispatch({
			type: "change_dimension",
			value: event.target.value,
			dimension: "leftScale",
		});
	}, []);

	const changeSplit = useCallback((event) => {
		dispatch({
			type: "change_dimension",
			dimension: "split",
			value: event.target.value,
		});
	}, []);

	return (
		<div className="flex flex-col items-center w-full">
			<div className="flex flex-col w-5/6 lg:w-full">
				<MemoizedSlider
					value={
						dimensionObj !== undefined ? dimensionObj.widthScale : 0
					}
					min={minWidthValue}
					max={maxWidthValue}
					dispatch={dispatch}
					onChange={changeSize}
					label="Size"
				/>
				<MemoizedSlider
					value={
						dimensionObj !== undefined ? dimensionObj.spacing : 0
					}
					min={0}
					max={100}
					dispatch={dispatch}
					color={"#1d4ed8"}
					dimension="spacing"
					onChange={changeSpacing}
					label="Spacing"
				/>
				<MemoizedSlider
					value={
						dimensionObj !== undefined ? dimensionObj.topScale : 0
					}
					min={minCoordinateValue}
					max={maxCoordinateValue}
					dispatch={dispatch}
					color={"black"}
					dimension="spacing"
					onChange={changeTop}
					label="Top"
				/>
				<MemoizedSlider
					value={
						dimensionObj !== undefined ? dimensionObj.leftScale : 0
					}
					min={minCoordinateValue}
					max={maxCoordinateValue}
					dispatch={dispatch}
					color={"black"}
					dimension="spacing"
					onChange={changeLeft}
					label="Left"
				/>
				{position == 3 && (
					<MemoizedSlider
						value={
							dimensionObj !== undefined ? dimensionObj.split : 0
						}
						min={0}
						max={50}
						dispatch={dispatch}
						color={"black"}
						dimension="spacing"
						onChange={changeSplit}
						label="Split"
					/>
				)}
			</div>
		</div>
	);
};
