import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ColorButton } from "../general/misc/ColorButton";
import { requestHandler } from "../../helpers/requests";
import { useNavigate, useLocation } from "react-router-dom";
import { ClothingContext } from "../../contexts/Clothing";
import { Spinner } from "../general/universal/Spinner";
import { IoAdd } from "react-icons/io5";
import { LabledTextInput } from "../general/forms/LabledTextInput";
import { LabledSelect } from "../general/forms/LabledSelect";
import { LabledDate } from "../general/forms/LabledDate";
import { useErrorHandler } from "react-error-boundary";
import { ConfirmationModal } from "../general/misc/ConfirmationModal";
import { ImageCropComponent } from "./ImageCropComponent";
import { CONFIG } from "../../config";
import { IoHelpSharp } from "react-icons/io5";
import { TutorialComponent } from "../tutorials/TutorialComponent";
import { AppContext } from "../../contexts/App";
import { adjustDate } from "../../helpers/miscHelper";

export const EditClothingComponent = () => {
	const [appState, _] = useContext(AppContext);
	let redirectUrl = "/clothing";
	if (appState.clothingRedirect != undefined) {
		redirectUrl = `/clothing?${appState.clothingRedirect}`;
	}
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		brand: "",
		name: "",
		owned: true,
		cost: 0,
		wears: 0,
		category: 0,
		subcategory: 0,
		startDate: null,
		lastWorn: null,
	});
	const [formError, setFormError] = useState({
		brand: false,
		name: false,
		picture: false,
	});

	const { clothingId } = useParams();
	const editing = clothingId != undefined;
	const [piece, setPiece] = useState(null);
	const [categories, setCategories] = useState(null);
	const [subcategories, setSubCategories] = useState(null);
	const [modalState, setModalState] = useState(false);

	const [clothingState, dispatch] = React.useContext(ClothingContext);
	useErrorHandler(clothingState.error);

	const formCheck = () => {
		var formFields = { brand: false, name: false, picture: false };
		var formCheckPass = true;
		if (form.brand == "") {
			formFields.brand = true;
			formCheckPass = false;
		}
		if (form.name == "") {
			formFields.name = true;
			formCheckPass = false;
		}
		if (!clothingState.dataURL && !editing) {
			formFields.picture = true;
			formCheckPass = false;
		}
		setFormError(formFields);
		return formCheckPass;
	};

	useEffect(() => {
		requestHandler.getCategories().then(
			(response) => {
				if (response.ok) {
					response.json().then((categoryData) => {
						setCategories(categoryData.categories);
						if (editing) {
							const formData = new FormData();
							formData.append("id", clothingId);
							requestHandler
								.getPiece(formData)
								.then((response) => {
									if (response.ok) {
										response.json().then((data) => {
											if (!data.piece) {
												navigate("/clothing");
											} else {
												setPiece(data.piece);
												// make sure date is converted from UTC to PST and back properly
												setForm({
													brand: data.piece.brand,
													name: data.piece.name,
													owned: data.piece.owned,
													cost: data.piece.cost,
													wears: data.piece.wears,
													category:
														data.piece.CategoryId,
													subcategory:
														data.piece
															.SubCategoryId,
													startDate: adjustDate(
														data.piece.startDate
													),
													lastWorn: adjustDate(
														data.piece.lastWorn
													),
												});
												setSubCategories(
													categoryData.categories.find(
														(category) =>
															category.id ==
															data.piece
																.CategoryId
													).SubCategories
												);
											}
										});
									} else {
										dispatch({
											type: "set_error",
											error: response,
										});
									}
								});
						} else {
							setForm({
								...form,
								category: categoryData.categories[0].id,
							});
							setSubCategories(
								categoryData.categories[0].SubCategories
							);
						}
					});
				} else {
					dispatch({ type: "set_error", error: response });
				}
			},
			(error) => dispatch({ type: "set_error", error: error })
		);
	}, []);

	const onBrandChange = (event) => {
		const value = event.target.value;
		setForm({ ...form, brand: value });
	};

	const onNameChange = (event) => {
		const value = event.target.value;
		setForm({ ...form, name: value });
	};

	const onCostChange = (event) => {
		const value = event.target.value;
		if (Number.isInteger(parseInt(value))) {
			setForm({ ...form, cost: parseInt(value) });
		} else if (value == "") {
			setForm({ ...form, cost: "" });
		}
	};

	const onWearChange = (event) => {
		const value = event.target.value;
		if (Number.isInteger(parseInt(value))) {
			setForm({ ...form, wears: parseInt(value) });
		} else if (value == "") {
			setForm({ ...form, wears: "" });
		}
	};

	const onDateChange = (value) => {
		if (value && value !== "Invalid Date") {
			setForm({ ...form, startDate: value });
		}
	};

	const onLastWornChange = (value) => {
		console.log(value);
		if (value !== "Invalid Date") {
			setForm({ ...form, lastWorn: value });
		}
	};

	const onOwnedChange = (event) => {
		const value = event.target.value;
		if (value == "true") {
			setForm({ ...form, owned: true });
		} else {
			setForm({ ...form, owned: false });
		}
	};

	const onCategoryChange = (event) => {
		const value = event.target.value;
		const SubCategories =
			value != ""
				? categories.find((category) => category.id == value)
						.SubCategories
				: null;
		setForm({ ...form, category: value, subcategory: SubCategories[0].id });
		setSubCategories(SubCategories);
	};

	useEffect(() => {
		const SubCategories = categories
			? categories.find((category) => category.id == form.category)
					.SubCategories
			: null;
		setSubCategories(SubCategories);
	}, [form.category]);

	const onSubCategoryChange = (event) => {
		const value = event.target.value;
		setForm({ ...form, subcategory: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!formCheck()) {
			return;
		} else {
			setLoading(true);
		}

		const formData = new FormData();

		formData.append("productImage", clothingState.dataURL);
		if (clothingState.fileName) {
			formData.append("fileName", clothingState.fileName);
		}
		formData.append("imageChanged", clothingState.imageChanged);
		formData.append("brand", form.brand);
		formData.append("name", form.name);
		formData.append("owned", form.owned);
		formData.append("category", event.target[7].value);
		formData.append("subcategory", event.target[8].value);
		formData.append("cost", form.cost == "" ? 0 : form.cost);
		formData.append("wears", form.wears == "" ? 0 : form.wears);
		formData.append("startDate", form.startDate || new Date());
		console.log(form.lastWorn);
		formData.append("lastWorn", form.lastWorn || new Date());
		if (editing) {
			formData.append("id", piece.id);
			formData.append("oldImage", piece.imageName);
		}

		if (editing) {
			requestHandler.editClothingItem(formData).then(
				(response) => {
					if (response.ok) {
						navigate(redirectUrl); // use previous url when editing, ie if the user clicked a pipece after filtering/sorting
					} else {
						dispatch({ type: "set_error", error: response });
					}
				},
				(error) => dispatch({ type: "set_error", error: error })
			);
		} else {
			requestHandler.newClothingItem(formData).then(
				(response) => {
					if (response.ok) {
						navigate("/clothing");
					} else {
						dispatch({ type: "set_error", error: response });
					}
				},
				(error) => dispatch({ type: "set_error", error: error })
			);
		}
	};

	const openModal = () => {
		setModalState(true);
	};

	const handleDelete = () => {
		const formData = new FormData();
		formData.append("id", piece.id);
		formData.append("clothingImage", piece.imageName);

		requestHandler.deleteClothingItem(formData).then(
			(response) => {
				if (response.ok) {
					navigate("/clothing");
				} else {
					dispatch({ type: "set_error", error: response });
				}
			},
			(error) => dispatch({ type: "set_error", error: error })
		);
	};

	const textFields = [
		{
			className: "mb-4",
			label: "Brand",
			value: form.brand,
			required: true,
			onChange: onBrandChange,
			needsFill: formError.brand && form.brand == "",
		},
		{
			className: "mb-4",
			label: "Name",
			value: form.name,
			required: true,
			onChange: onNameChange,
			needsFill: formError.name && form.name == "",
		},
		{
			className: "mb-4",
			label: "Cost",
			value: form.cost,
			onChange: onCostChange,
			type: "number",
		},
		{
			className: "mb-4",
			label: "Number of Times Worn",
			value: form.wears,
			onChange: onWearChange,
			type: "number",
		},
	];

	const selectFields = [
		{
			className: "mb-4",
			label: "Owned",
			defaultValue: editing
				? piece
					? piece.owned
					: form.owned
				: form.owned,
			onChange: onOwnedChange,
			list: [
				{ name: "Yes", value: true },
				{ name: "No", value: false },
			],
			valueId: "value",
			nameId: "name",
		},
		{
			className: "mb-4",
			label: "Category",
			defaultValue: editing
				? piece
					? piece.CategoryId
					: form.category
				: form.category,
			onChange: onCategoryChange,
			list: categories,
			valueId: "id",
			nameId: "name",
		},
		{
			className: "mb-4",
			label: "Subcategory",
			defaultValue: editing
				? piece
					? piece.SubCategoryId
					: form.subcategory
				: form.subcategory,
			onChange: onSubCategoryChange,
			list: subcategories,
			valueId: "id",
			nameId: "name",
		},
	];

	const [tutorialModal, setTutorialModal] = useState(false);

	return (editing ? piece : true) && categories && subcategories ? (
		<div className="flex justify-center pb-16">
			<TutorialComponent
				parentState={tutorialModal}
				setParentState={setTutorialModal}
				type="clothing"
			/>
			<ConfirmationModal
				modalState={modalState}
				setModalState={setModalState}
				confirmFunc={handleDelete}
				itemName=" piece"
			></ConfirmationModal>
			<div className="w-full md:w-1/2 flex flex-col items-center justify-center px-12 font-light">
				<ImageCropComponent
					className="mb-4"
					defaultImg={
						piece ? `${CONFIG.IMAGE_URL}${piece.imageName}` : null
					}
					noImage={formError.picture}
				></ImageCropComponent>
				<form
					id="clothingForm"
					onSubmit={handleSubmit}
					className="flex flex-col w-full justify-center"
				>
					{textFields.map((field, index) => {
						return <LabledTextInput key={index} {...field} />;
					})}
					<LabledDate
						label="Date Acquired"
						onChange={onDateChange}
						className="mb-4"
						value={form.startDate}
						placeholder="MM/DD/YYYY"
					/>
					<LabledDate
						label="Last Worn"
						onChange={onLastWornChange}
						className="mb-4"
						value={form.lastWorn}
						placeholder="MM/DD/YYYY"
					/>
					{selectFields.map((field, index) => {
						return <LabledSelect key={index} {...field} />;
					})}
				</form>
				<div className="fixed w-full flex justify-center bg-white  border-t border-gray-400 right-0 bottom-0 p-3">
					<ColorButton
						onClick={() => {
							document
								.getElementById("clothingForm")
								.dispatchEvent(
									new Event("submit", {
										cancelable: true,
										bubbles: true,
									})
								);
						}}
						loading={loading}
						type="green"
						label={
							editing
								? loading
									? "Saving"
									: "Save"
								: loading
								? "Adding"
								: "Add"
						}
						className="w-24 text-base self-center mr-2"
					/>
					{editing && (
						<div className="flex">
							<ColorButton
								className="w-24 text-base mr-2"
								onClick={() =>
									navigate(`/outfitsByClothing/${piece.id}`)
								}
								label="Outfits"
							/>
							<ColorButton
								className="w-24 text-base"
								onClick={() => openModal()}
								type="red"
								label="Delete"
							/>
						</div>
					)}
					<div className="absolute flex h-full items-center right-2 top-0">
						<div
							onClick={() => setTutorialModal(true)}
							className="p-2 border rounded-full hover:bg-black hover:text-white cursor-pointer"
						>
							<IoHelpSharp size={20} />
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<Spinner />
	);
};
