import React from "react";
import { Container } from "../components/general/universal/Container";
import { GoogleSignupLoginComponent } from "../components/login/GoogleSignupLoginComponent";

const Login = () => {
	return (
		<Container>
			<GoogleSignupLoginComponent login={true} />
		</Container>
	);
};

export default Login;
