import React from "react";
import { ColorButton } from "../misc/ColorButton";
import { CategoryFilterComponent } from "./filtercontents/CategoryFilterComponent";
import { SortComponent } from "./filtercontents/SortComponent";
import { useEffect } from "react";
import { useState } from "react";
import { ViewerContext } from "../../../contexts/Generic";
import { IoCloseSharp } from "react-icons/io5";
import { FixedOverlay } from "../misc/FixedOverlay";

export const MobileFilter = ({ context = ViewerContext }) => {
	const [contextState, dispatch] = React.useContext(context);

	const setShowFilter = (value) => {
		dispatch({ type: "set_showFilter", value });
	};

	return (
		<FixedOverlay
			parentState={contextState.showFilter}
			setParentState={setShowFilter}
			className="flex lg:hidden"
			modalHeader="Refine"
		>
			<div className="flex flex-col  z-50 w-full h-full bg-white font-light p-2 ">
				{contextState.excludeCategoryFilter ? (
					""
				) : (
					<CategoryFilterComponent
						className="mb-2"
						context={context}
					/>
				)}
				<SortComponent className="" context={context} />
			</div>
		</FixedOverlay>
	);
};
