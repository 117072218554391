import React from "react";
import { IoLogoGoogle } from "react-icons/io5";

export const GoogleAccountEditComponent = () => {
	return (
		<div className="flex flex-col items-center flex-grow">
			<div className="text-2xl font-light mb-4">Account Details</div>
			<div className="text-sm font-light mb-4">
				Account modification is done through Google. Click the button
				below to go to Google's account page.
			</div>
			<div
				onClick={() =>
					(window.location.href = "https://myaccount.google.com")
				} // goofy ah hack because React scuffed xddd
				className="p-4 border rounded-full hover:bg-black hover:text-white cursor-pointer"
			>
				<IoLogoGoogle size={32} />
			</div>
		</div>
	);
};
