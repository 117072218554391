export const ViewerReducer = (state, action) => {
	switch (action.type) {
		case "set_all_items":
			return {
				...state,
				allItems: action.items,
			};
		case "set_categories":
			return {
				...state,
				categories: action.categories,
			};
		case "set_showFilter":
			if (action.value != undefined) {
				return {
					...state,
					showFilter: action.value,
				};
			} else {
				return {
					...state,
					showFilter: !state.showFilter,
				};
			}

		case "set_error": {
			return {
				...state,
				error: action.error,
			};
		}
		case "set_loading": {
			return {
				...state,
				loading: action.loading,
			};
		}
		case "set_count": {
			return {
				...state,
				count: action.count,
			};
		}
		case "set_cost": {
			return {
				...state,
				cost: parseFloat(action.cost),
			};
		}
		case "set_itemsPerPage": {
			return {
				...state,
				itemsPerPage: action.itemsPerPage,
			};
		}

		case "set_totalPages": {
			return {
				...state,
				totalPages: action.totalPages,
			};
		}

		case "set_local": {
			return {
				...state,
				local: true,
			};
		}

		case "set_local_default": {
			return {
				...state,
				local: false,
			};
		}

		case "set_category": {
			return {
				...state,
				category: action.category,
			};
		}

		case "remove_category": {
			return {
				...state,
				category: null,
			};
		}

		case "set_sort": {
			return {
				...state,
				sort: action.sort,
			};
		}

		case "set_search": {
			return {
				...state,
				search: action.search,
			};
		}

		case "set_page": {
			return {
				...state,
				page: action.page,
			};
		}

		case "reset": {
			return {
				...state,
				category: null,
				search: "",
				sort: null,
				owned: null,
				page: 1,
			};
		}

		case "set_excludeCategoryFilter": {
			return {
				...state,
				excludeCategoryFilter: true,
			};
		}

		case "set_includeMetricTypes": {
			return {
				...state,
				includeMetricTypes: true,
			};
		}

		case "set_excludeCostSort": {
			return {
				...state,
				excludeCostSort: true,
			};
		}

		case "set_type": {
			return {
				...state,
				type: action.value,
			};
		}

		case "set_displayFields": {
			return {
				...state,
				displayFields: action.fields,
			};
		}

		case "set_owned": {
			return {
				...state,
				owned: action.owned,
			};
		}

		case "remove_owned": {
			return {
				...state,
				owned: null,
			};
		}

		default:
			return state;
	}
};

export const initialState = {
	allItems: null,
	categories: null,
	showFilter: false,
	error: null,
	loading: false,
	count: 0,
	cost: 0,
	itemsPerPage: 12,
	totalPages: 0,
	defaultSort: "updatedAt-desc",
	displayFields: ["cost"],
	sort: null,
	search: "",
	category: null,
	owned: null,
	page: 1,
	local: null,
	excludeCategoryFilter: false,
	includeMetricTypes: false,
	excludeCostSort: false,
	type: null,
};
