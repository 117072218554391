import React from "react";
import { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { enableScroll, disableScroll } from "../../../helpers/miscHelper";

export const FixedOverlay = ({
	className = "",
	parentState = false,
	setParentState = () => null,
	disableScrolling = false,
	zIndex = "z-50",
	fadeDuration = "duration-300",
	modalHeader = "Modal Header",
	modalClass = "",
	modalWidth = "w-3/4",
	modalHeight = "h-auto max-h-[90%]",
	children,
}) => {
	const [container, setContainer] = useState(false);
	const [modal, setModal] = useState(false);
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		if (!modal && parentState) {
			// if the parentstate is set to true but the modal is not open, open it
			handleOverlay();
		} else if (modal && !parentState) {
			// if the parentstate is set to false but the modal is still open, close it
			handleOverlay();
		}
	}, [parentState]);

	const handleOverlay = () => {
		if (modal) {
			if (disableScrolling) {
				// re-enable scrolling
				enableScroll(document);
				window.scrollTo(0, scroll);
			}

			setModal(false);
			setTimeout(() => {
				setContainer(false);
			}, 200);
		} else {
			if (disableScrolling) {
				var scrollY = window.scrollY;
				//disableScrollFunc();
				disableScroll(document, window);
				setScroll(scrollY);
			}

			setContainer(true);
			setModal(true);
		}
		setParentState(!modal);
	};

	//window.addEventListener("resize", handleOverlay);

	return (
		<div
			className={`${className} ${zIndex} fixed sm:pt-12 top-0 w-full h-screen flex items-center justify-center ${
				container ? "left-0" : "-left-full"
			} ${
				modal ? "opacity-100" : "opacity-0"
			} transition-opacity ${fadeDuration}`}
		>
			<div
				onClick={handleOverlay}
				className={`absolute transition-all ${fadeDuration} bg-black bg-opacity-80 top-0 left-0 flex justify-center w-full h-full cursor-pointer`}
			></div>
			<div
				className={`${modalWidth} ${modalHeight} ${zIndex} bg-white overflow-hidden flex flex-col justify-center border border-gray-900 rounded-md`}
			>
				<div className="flex z-50 justify-between items-center p-2 border-b w-full shadow-md">
					<div className="font-light text-sm">{modalHeader}</div>
					<div onClick={handleOverlay} className="cursor-pointer p-2">
						<IoCloseSharp />
					</div>
				</div>
				<div className={`${modalClass} w-full h-full overflow-y-auto`}>
					{children}
				</div>
			</div>
		</div>
	);
};
