import React from "react";
import { Container } from "../components/general/universal/Container";
import { ViewOutfitsComponent } from "../components/outfits/viewOutfits/ViewOutfitsComponent";
import { useParams } from "react-router-dom";
import { ViewOutfitsByClothingComponent } from "../components/outfitsByClothing/ViewOutfitsByClothingComponent";

const ViewOutfitsByClothing = () => {
	return (
		<Container>
			<ViewOutfitsByClothingComponent />
		</Container>
	);
};

export default ViewOutfitsByClothing;
