import React, { useState, useContext, useEffect } from "react";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import { addParam, removeParam } from "../../../helpers/searchParamsHelper";
import { useSearchParams } from "react-router-dom";
import { ColorButton } from "../misc/ColorButton";
import { LabledTextInput } from "../forms/LabledTextInput";
import { ViewerContext } from "../../../contexts/Generic";

export const PaginationControls = ({ context = ViewerContext }) => {
	const [contextState, dispatch] = React.useContext(context);
	const local = contextState.local;
	const [searchParams, setParams] = useSearchParams();
	const [overlay, setOverlay] = useState(false);
	const [overlayContainer, setOverlayContainer] = useState(false);
	const [overlayInput, setOverlayInput] = useState(0);

	const curPage = local
		? contextState.page
		: searchParams.get("page")
		  ? parseInt(searchParams.get("page"))
		  : 1;

	useEffect(() => {
		setOverlayInput(curPage);
	}, [curPage]);

	const setPage = (page) => {
		local
			? dispatch({ type: "set_page", page })
			: setParams(addParam(searchParams, "page", page));
	};

	const onOverlayInputChange = (event) => {
		const value = event.target.value;
		if (Number.isInteger(parseInt(value))) {
			setOverlayInput(parseInt(value));
		} else if (value == "") {
			setOverlayInput("");
		}
	};

	const handleOverlayPageChange = (event) => {
		event.preventDefault();
		const page = parseInt(overlayInput);
		if (overlayInput == undefined) {
			handleOverlay();
		} else if (overlayInput > contextState.totalPages) {
			setPage(contextState.totalPages);
			handleOverlay();
		} else if (overlayInput < 1) {
			setPage(1);
			handleOverlay();
		} else {
			if (page == parseInt(curPage)) {
				handleOverlay();
			} else {
				setPage(page);
				handleOverlay();
			}
		}
	};

	const handleOverlay = () => {
		if (overlay) {
			setOverlay(false);
			setTimeout(() => {
				setOverlayContainer(false);
			}, 200);
		} else {
			setOverlayContainer(true);
			setOverlay(true);
		}
	};

	const incrementPage = () => {
		var newPage = curPage + 1;
		if (newPage > contextState.totalPages) {
			return;
		} else {
			setPage(newPage);
		}
	};

	const decrementPage = () => {
		var newPage = curPage - 1;
		if (newPage <= 0) {
			return;
		} else {
			setPage(newPage);
		}
	};
	return (
		<>
			<div
				className={`fixed z-50 ${
					overlayContainer ? "left-0" : "-left-full"
				} ${overlay ? "opacity-100" : "opacity-0"} "
    top-0 transition-opacity duration-200 w-full h-full flex items-center justify-center`}
			>
				<div
					onClick={handleOverlay}
					className="absolute w-full h-full bg-black bg-opacity-70 z-50"
				></div>
				<div className="z-50 flex flex-col justify-center max-h-screen bg-white border border-black p-4 rounded-md">
					<form
						onSubmit={handleOverlayPageChange}
						id="overlayForm"
						className="flex flex-col items-center justify-center mb-4"
					>
						<span className="mb-2 text-sm">Enter Page:</span>
						<LabledTextInput
							noLabel={true}
							type="number"
							value={overlayInput}
							onChange={onOverlayInputChange}
							className="w-32"
						></LabledTextInput>
						<span className="mt-2 text-sm">
							of {contextState.totalPages}
						</span>
					</form>
					<div className="flex flex-row self-center">
						<ColorButton
							label="Save"
							type="green"
							className="w-16 mr-2"
							onClick={() =>
								document
									.getElementById("overlayForm")
									.dispatchEvent(
										new Event("submit", {
											cancelable: true,
											bubbles: true,
										})
									)
							}
						/>
						<ColorButton
							label="Close"
							className="w-16"
							onClick={handleOverlay}
						/>
					</div>
				</div>
			</div>
			<div
				className={`flex select-none items-center justify-center font-light mt-4`}
			>
				<div
					onClick={() => setPage(1)}
					className={`flex items-center justify-center cursor-pointer w-12 h-12  ${
						curPage == 1
							? "text-gray-500 pointer-events-none"
							: "hover:text-blue-700"
					}`}
				>
					First
				</div>
				<div
					onClick={decrementPage}
					className={`flex items-center justify-center cursor-pointer w-12 h-12  ${
						curPage == 1
							? "text-gray-500 pointer-events-none"
							: "hover:text-blue-700 "
					}`}
				>
					<IoChevronBackOutline />
				</div>
				<div
					onClick={handleOverlay}
					className={`flex select-none text-sm items-center justify-center px-4 py-2 border border-gray-400 rounded-md cursor-pointer hover:bg-black hover:border-black hover:text-white`}
				>
					{contextState.totalPages != 0
						? `Page ${curPage} of ${contextState.totalPages}`
						: ""}
				</div>
				<div
					onClick={incrementPage}
					className={`flex items-center justify-center cursor-pointer w-12 h-12  ${
						curPage >= contextState.totalPages
							? "text-gray-500 pointer-events-none"
							: "hover:text-blue-700"
					}`}
				>
					<IoChevronForwardOutline />
				</div>
				<div
					onClick={() => setPage(contextState.totalPages)}
					className={`flex items-center justify-center cursor-pointer w-12 h-12  ${
						curPage >= contextState.totalPages
							? "text-gray-500 pointer-events-none"
							: "hover:text-blue-700"
					}`}
				>
					Last
				</div>
			</div>
		</>
	);
};
