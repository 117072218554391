/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { OutfitPiece } from "./OutfitPiece";
import { PieceSpacer } from "../general/misc/PieceSpacer";
import { cardHeightFunc, scaledPosition } from "../../helpers/cssHelper";

export const Outfit = ({ Outfit = null, individual = false, type = "" }) => {
	const findPosition = (position) => {
		return Outfit.OutfitPieces.find(
			(OutfitPiece) => parseInt(OutfitPiece.position) == position
		);
	};

	return !Outfit ? (
		""
	) : (
		<div
			css={individual ? "" : cardHeightFunc(type)}
			className="flex flex-col justify-center items-center h-auto overflow-hidden"
		>
			<div
				css={scaledPosition(type, Outfit.topScale, Outfit.leftScale)}
				className="flex relative"
			>
				<div className="flex flex-col items-end mr-0.5">
					<OutfitPiece
						type={type}
						piece={findPosition(4)}
						position={4}
					/>
					<OutfitPiece
						type={type}
						piece={findPosition(5)}
						position={5}
					/>
					<OutfitPiece
						type={type}
						piece={findPosition(6)}
						position={6}
					/>
					<PieceSpacer type={type} noHeight={true} widthScale={40} />
				</div>

				<div className="flex flex-col items-center">
					<OutfitPiece
						type={type}
						piece={findPosition(1)}
						position={1}
					/>
					<OutfitPiece
						type={type}
						piece={findPosition(2)}
						position={2}
					/>
					<OutfitPiece
						type={type}
						piece={findPosition(3)}
						position={3}
					/>
				</div>
				<div className="flex flex-col items-start ml-0.5">
					<OutfitPiece
						type={type}
						piece={findPosition(7)}
						position={7}
					/>
					<OutfitPiece
						type={type}
						piece={findPosition(8)}
						position={8}
					/>
					<OutfitPiece
						type={type}
						piece={findPosition(9)}
						position={9}
					/>
					<PieceSpacer type={type} noHeight={true} widthScale={40} />
				</div>
			</div>
		</div>
	);
};
