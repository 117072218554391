import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { IoClose, IoMenu } from "react-icons/io5";
import { GrMenu } from "react-icons/gr";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

export const Header = () => {
	const [desktop, setDesktop] = useState(document.body.clientWidth >= 624);
	const checkWindowSize = () => {
		if (document.body.clientWidth >= 625) {
			setDesktop(true);
		} else {
			setDesktop(false);
		}
	};

	//window.addEventListener("resize", checkWindowSize);

	return (
		<>
			<DesktopHeader /> <MobileHeader />
		</>
	);
};
