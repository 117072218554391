import React from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { addParam, removeParam } from "../../../../helpers/searchParamsHelper";
import { ViewerContext } from "../../../../contexts/Generic";
import { IoCloseSharp } from "react-icons/io5";

export const filterHeaderClass =
	"font-normal border-b border-gray-500 text-xl lg:text-lg my-1";

const filterItemClass =
	"flex items-center py-1.5 lg:p-0.5 cursor-pointer border border-transparent rounded-md";
const filterItemSelectedClass = "font-medium underline";
const filterItemHover =
	"hover:bg-blue-100 hover:bg-opacity-25 hover:border-blue-700";

export const FilterItem = ({
	fontSize = "text-base lg:text-sm",
	onClick = () => null,
	label = "",
	selected = false,
	children,
}) => {
	return (
		<div
			onClick={onClick}
			className={`${filterItemClass} ${fontSize} ${
				selected ? filterItemSelectedClass : filterItemHover
			}`}
		>
			{label}
			{children}
		</div>
	);
};

export const CategoryFilterComponent = ({
	context = ViewerContext,
	className = "",
}) => {
	const [searchParams, setParams] = useSearchParams();
	const [contextState, dispatch] = React.useContext(context);
	const navigate = useNavigate();
	const local = contextState.local;

	const filterCategory = local
		? contextState.category
		: searchParams.get("category");
	const owned = local ? contextState.owned : searchParams.get("owned");

	const addCategory = local
		? (category) => dispatch({ type: "set_category", category })
		: (category) => setParams(addParam(searchParams, "category", category));
	const removeCategory = local
		? () => dispatch({ type: "remove_category" })
		: () => setParams(removeParam(searchParams, "category"));

	const setOwned = local
		? (value) => dispatch({ type: "set_owned", owned: value })
		: (value) => setParams(addParam(searchParams, "owned", value));

	const removeOwned = local
		? () => dispatch({ type: "remove_owned" })
		: () => setParams(removeParam(searchParams, "owned"));

	const setOwnership = (value) => {
		console.log("setting ownership to: ", value);
		setOwned(value);
	};

	/* Find if the category from searchParams/context is actually a subcategory */
	const findSubCategory = (categoryObj) => {
		var result = false;
		categoryObj.SubCategories.forEach((subcategory) => {
			if (subcategory.urlName == filterCategory) {
				result = true;
				return result;
			}
		});
		return result;
	};

	return (
		<div className={`${className} flex flex-col w-full select-none`}>
			<div className="mb-4">
				<div className={filterHeaderClass}>Category</div>
				<FilterItem
					label="All Categories"
					onClick={removeCategory}
					selected={!filterCategory}
				/>
				{contextState.categories &&
					contextState.categories.map((category, i) => {
						return (
							<div
								key={category.name}
								className="flex flex-col w-full"
							>
								<FilterItem
									key={category.id}
									label={category.name}
									onClick={() =>
										addCategory(category.urlName)
									}
									selected={
										findSubCategory(category) ||
										filterCategory == category.urlName
									}
								/>
								<div
									className={`flex flex-col ml-5 ${
										findSubCategory(category) ||
										filterCategory == category.urlName
											? ""
											: "hidden"
									}`}
								>
									{category.SubCategories.map(
										(subcategory) => {
											return (
												<FilterItem
													fontSize="text-sm lg:text-xs"
													key={subcategory.id}
													label={subcategory.name}
													onClick={() =>
														addCategory(
															subcategory.urlName
														)
													}
													selected={
														filterCategory ==
														subcategory.urlName
													}
												/>
											);
										}
									)}
								</div>
							</div>
						);
					})}
			</div>

			<div className="mb-4">
				<div className={filterHeaderClass}>Ownership</div>
				<div className="flex flex-col text-base lg:text-sm w-full">
					<FilterItem
						onClick={() => removeOwned()}
						selected={owned == null}
						label="All"
					/>
					<FilterItem
						onClick={() => setOwnership("true")}
						selected={owned == "true"}
						label="Owned"
					/>
					<FilterItem
						onClick={() => setOwnership("false")}
						selected={owned == "false"}
						label="Not Owned"
					/>
				</div>
			</div>
		</div>
	);
};
