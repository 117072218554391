import React from "react";
import { DesktopFilter } from "./DesktopFilter";
import { MobileFilter } from "./MobileFilter";
import { useState } from "react";
import { ViewerContext } from "../../../contexts/Generic";

export const FilterComponent = ({ context = ViewerContext }) => {
	return (
		<>
			<DesktopFilter context={context} />
			<MobileFilter context={context} />
		</>
	);
};
