import { useState, useEffect, useMemo, memo } from "react";
import { ClothingRowCard } from "./ClothingRowCard";
import { ViewClothingComponent } from "../clothing/ViewClothingComponent";
import { Chart } from "react-charts";
import Bar from "./Bar";
import { StyledChart } from "./StyledChart";
import { requestHandler, authRequest } from "../../helpers/requests";
import { useSpring } from "@react-spring/web";
import { ColorButton } from "../general/misc/ColorButton";
import { Spinner } from "../general/universal/Spinner";
import { Link } from "react-router-dom";

export const AddWearsComponent = () => {
	const cardFunc = (piece, displayField) => {
		return (
			<ClothingRowCard
				key={piece.id}
				piece={piece}
				displayField={displayField}
			/>
		);
	};
	return (
		<ViewClothingComponent type="clothingAnalytics" cardFunc={cardFunc} />
	);
};
