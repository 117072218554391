import { useState, useEffect } from "react";
import { CONFIG } from "../../config";
import { NoItemCard } from "../general/misc/NoItemCard";
import { displayMetricString } from "../clothing/ClothingCard";
import { IoAdd } from "react-icons/io5";
import { useErrorHandler } from "react-error-boundary";
import { requestHandler } from "../../helpers/requests";
import { CircularProgress } from "@mui/material";

export const ClothingRowCard = ({ piece = null, displayField = "" }) => {
	const [wears, setWears] = useState(0);
	const [opacity, setOpacity] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	useErrorHandler(error);

	useEffect(() => {
		if (piece) {
			setWears(parseInt(piece.wears));
		}
	}, []);

	const addOneWear = () => {
		let id = setTimeout(() => {
			setLoading(true);
		}, 100);
		const formData = new FormData();
		formData.append("id", piece.id);
		formData.append("wears", wears);
		requestHandler.addOneWear(formData).then((response) => {
			if (response.ok) {
				setWears(wears + 1);
			} else {
				setError(response);
			}
			clearTimeout(id);
			setLoading(false);
		});
	};

	const metricSelectedClass = "bg-blue-700 bg-opacity-20";
	const tableCellClass =
		"flex w-1/3 overflow-hidden h-full items-center justify-center p-2";

	return !piece ? (
		<NoItemCard />
	) : (
		<div className="p-1">
			<div className="flex select-none w-full h-full border border-gray-400 shadow-sm rounded-md p-2">
				<div className="flex items-center shrink-0">
					<img
						onLoad={() => setOpacity(true)}
						className={`transition-all duration-300 ${
							opacity ? "opacity-100" : "opacity-0"
						} w-24 h-24 object-contain`}
						src={`${CONFIG.IMAGE_URL}${piece.imageName}`}
					></img>
				</div>

				<div className="flex flex-grow overflow-hidden px-2 items-center font-light text-sm ">
					{/*<div
          className={`${tableCellClass} ${
            displayField && displayField.split("-")[0] == "cost"
              ? metricSelectedClass
              : ""
          }`}
        >
          {displayMetricString("cost", piece)}
        </div>
        <div
          className={`${tableCellClass} ${
            displayField && displayField.split("-")[0] == "wears"
              ? metricSelectedClass
              : ""
          }`}
        >
          {displayMetricString("wears", piece)}
        </div>*/}
					<div className="flex flex-col w-full justify-start flex-shrink overflow-hidden">
						<span className="text-sm font-normal truncate">
							{piece.brand}
						</span>
						<span className="text-sm font-light truncate">
							{piece.name}
						</span>
						<span className="text-sm font-light truncate">
							{`${wears} ${wears == 1 ? "wear" : "wears"}`}
						</span>
					</div>
				</div>
				<div className="flex h-full items-center justify-end font-light text-sm">
					<div
						onClick={loading ? () => null : addOneWear}
						className={`relative flex items-center justify-center w-8 h-8 border rounded-full  ${
							loading
								? "border-gray-400 text-gray-400"
								: "hover:bg-black hover:text-white cursor-pointer"
						}`}
					>
						<IoAdd size={20} />
						{loading && (
							<div className="absolute flex items-center justify-center w-full h-full">
								<CircularProgress
									size={20}
									sx={{ color: "black" }}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
