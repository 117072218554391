/* Provides a wrapper for backend requests */

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function nonAuthPostOptions(body) {
	return {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: body,
		credentials: "include",
	};
}

function postOptions(body) {
	return {
		method: "POST",
		body: body,
		headers: {
			Authorization: `Bearer ${getCookie("userInfo")}`,
		},
	};
}

function getOptions() {
	return {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getCookie("userInfo")}`,
		},
	};
}

const requestHandler = {
	/* Signup/Login Requests */
	checkAuth: function () {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getCookie("userInfo")}`,
			},
		};
		return fetch("/checkAuth", requestOptions);
	},
	login: function (body) {
		const requestOptions = nonAuthPostOptions(body);
		return fetch("/login", requestOptions);
	},
	googleAuth: function (body) {
		return fetch("/googleAuth", { method: "POST", body: body });
	},
	googleSignup: function (body) {
		return fetch("/googleSignup", { method: "POST", body: body });
	},
	checkAdmin: function (body) {
		return fetch("/checkAdmin", postOptions());
	},
	sync: function () {
		return fetch("/sync", postOptions());
	},
	createCategories: function () {
		return fetch("/createCategories", postOptions());
	},
	testUserSignin: function () {
		return fetch("/testUserSignin", { method: "POST" });
	},
	decodeToken: function (body) {
		return fetch("/decodeToken", { method: "POST", body: body });
	},
	signup: function (body) {
		const requestOptions = nonAuthPostOptions(body);
		return fetch("/signup", requestOptions);
	},

	/* Account Requests */
	getUserInfo: function () {
		return fetch("/getUserInfo", getOptions());
	},
	changeEmail: function (body) {
		return fetch("/changeEmail", postOptions(body));
	},
	changePassword: function (body) {
		return fetch("/changePassword", postOptions(body));
	},

	/* Clothing Requests */
	newClothingItem: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/newClothingItem", requestOptions);
	},
	editClothingItem: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/editClothingItem", requestOptions);
	},
	addOneWear: function (body) {
		return fetch("/addOneWear", postOptions(body));
	},
	getClothing: function (body) {
		return fetch("/getClothing", postOptions(body));
	},
	getClothingCount: function (body) {
		return fetch("/getClothingCount", postOptions(body));
	},
	getClothingAnalytics: function () {
		return fetch("/getClothingAnalytics", postOptions());
	},
	getPiece: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/getPiece", requestOptions);
	},
	getPiecesByIds: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/getPiecesByIds", requestOptions);
	},
	deleteClothingItem: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/deleteClothingItem", requestOptions);
	},
	getCategories: function () {
		return fetch("/getCategories", getOptions());
	},

	/* Outfit Requests */
	addOutfit: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/addOutfit", requestOptions);
	},
	editOutfit: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/editOutfit", requestOptions);
	},
	getOutfits: function (body) {
		return fetch("/getOutfits", postOptions(body));
	},
	getOutfit: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/getOutfit", requestOptions);
	},
	deleteOutfit: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/deleteOutfit", requestOptions);
	},

	/* Lookbook Requests */
	getLookbooks: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/getLookbooks", requestOptions);
	},
	addLookbook: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/addLookbook", requestOptions);
	},
	getLookbook: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/getLookbook", requestOptions);
	},
	deleteLookbook: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/deleteLookbook", requestOptions);
	},
	editLookbook: function (body) {
		const requestOptions = postOptions(body);
		return fetch("/editLookbook", requestOptions);
	},
};

const authRequest = (url, type = "get", body) => {
	const requestOptions = type == "get" ? getOptions() : postOptions(body);
	return fetch(url, requestOptions);
};

export { requestHandler, authRequest };
