const PROD_CONFIG = {
	GOOGLE_CLIENT_ID:
		"71327259825-q8tdp0l90vgj7eg7rntorfd8v3hbllkq.apps.googleusercontent.com",
	IMAGE_URL: "https://outfitorg.com/",
};

const DEV_CONFIG = {
	GOOGLE_CLIENT_ID:
		"418008539987-vskpeqso1qk7hq42659rragtbe0u8lmj.apps.googleusercontent.com",
	IMAGE_URL: "http://localhost:3001/",
};

export const CONFIG = PROD_CONFIG;
//export const CONFIG = DEV_CONFIG;
