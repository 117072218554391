import React from "react";
import { useState } from "react";
import { ColorButton } from "../misc/ColorButton";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { addParam, removeParam } from "../../../helpers/searchParamsHelper";
import { LabledTextInput } from "../forms/LabledTextInput";
import { ViewerContext } from "../../../contexts/Generic";
import { useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { FixedOverlay } from "../misc/FixedOverlay";

export const ViewMenu = ({
	context = ViewerContext,
	add = "",
	searchPlaceholder = "Search...",
	cost = () => {
		return "";
	},
	noFilters = false,
	itemNames = { single: "item", plural: "items" },
}) => {
	const [contextState, dispatch] = React.useContext(context);
	const local = contextState.local;
	const [searchParams, setParams] = useSearchParams();
	const [searchTerm, setSearchTerm] = useState(
		local
			? contextState.search
				? contextState.search
				: ""
			: searchParams.get("search")
			  ? searchParams.get("search")
			  : ""
	);

	const navigate = useNavigate();

	const category = local
		? contextState.category
		: searchParams.get("category");
	const search = local ? contextState.search : searchParams.get("search");
	const sort = local ? contextState.sort : searchParams.get("sort");
	const owned = local ? contextState.owned : searchParams.get("owned");

	const updateSearchTerm = (event) => {
		const value = event.target.value;
		setSearchTerm(value);
	};

	const handleSearch = (event) => {
		event.preventDefault();
		//dispatch({ type: "set_pageNum", pageNum: 0 });
		local
			? dispatch({ type: "set_search", search: searchTerm })
			: setParams(addParam(searchParams, "search", searchTerm));
		if (searchOverlay) {
			handleSearchOverlay();
		}
	};

	const reset = () => {
		local ? dispatch({ type: "reset" }) : setParams({});
		setSearchTerm("");
	};

	const [searchOverlay, setSearchOverlay] = useState(false);
	const [searchOverlayContainer, setSearchOverlayContainer] = useState(false);

	const handleSearchOverlay = () => {
		if (searchOverlay) {
			setSearchOverlay(false);
			setTimeout(() => {
				setSearchOverlayContainer(false);
			}, 200);
		} else {
			setSearchOverlayContainer(true);
			setSearchOverlay(true);
		}
	};

	return (
		<>
			<FixedOverlay
				parentState={searchOverlay}
				setParentState={setSearchOverlay}
				modalHeader="Search"
				modalWidth="w-5/6"
			>
				<div className="flex p-4">
					<form className="flex flex-grow" onSubmit={handleSearch}>
						<LabledTextInput
							noLabel={true}
							className={"w-full h-full"}
							value={searchTerm}
							onChange={updateSearchTerm}
							placeholder={searchPlaceholder}
							charLimit={100}
						/>
					</form>
					<ColorButton
						onClick={handleSearch}
						className="ml-2"
						label="Search"
					/>
				</div>
			</FixedOverlay>
			<div
				className={`flex flex-col mb-3 w-full sm:h-auto justify-between`}
			>
				{!noFilters && (
					<div className="flex w-full h-10 sm:h-full">
						<ColorButton
							onClick={() => dispatch({ type: "set_showFilter" })}
							className={`w-full sm:w-auto`}
							type={category || sort || owned ? "blue" : ""}
							label="Refine"
						/>

						{(search || category || sort || owned) && (
							<ColorButton
								onClick={reset}
								label="Reset"
								type="green"
								className="ml-2 w-full h-full sm:h-auto sm:w-auto"
							/>
						)}

						<div className="hidden sm:flex flex-grow w-full select-none ml-2">
							<form
								className="flex flex-grow "
								onSubmit={handleSearch}
							>
								<LabledTextInput
									noLabel={true}
									className={"w-full h-full"}
									value={searchTerm}
									onChange={updateSearchTerm}
									placeholder={searchPlaceholder}
									charLimit={100}
								/>
							</form>
						</div>

						<ColorButton
							onClick={handleSearch}
							className="hidden sm:block ml-2"
							label="Search"
						/>

						<ColorButton
							onClick={handleSearchOverlay}
							className="ml-2 h-auto flex sm:hidden w-full sm:w-auto"
							label="Search"
						/>

						{add ? (
							<ColorButton
								onClick={() => navigate(add)}
								type="blue"
								className="h-full whitespace-nowrap flex w-full sm:w-auto ml-2"
								label="+ New"
							/>
						) : (
							""
						)}
					</div>
				)}
				<div className="flex justify-center text-center px-3 mt-3 font-light leading-tight text-sm select-none">
					{`${contextState.count} ${
						contextState.count == 1
							? itemNames.single
							: itemNames.plural
					}${cost(contextState.cost)}`}
				</div>
			</div>
			{search == "" || !search ? (
				""
			) : (
				<div className="flex justify-center mb-1 px-3 font-light text-sm">
					{`Results for "${search}"`}
				</div>
			)}
		</>
	);
};
