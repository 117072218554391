/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useContext, useEffect } from "react";
import { AddOutfitContext } from "../../../../contexts/Outfits/AddOutfit";
import { PieceAdder } from "./PieceAdder";
import { PieceSpacer } from "../../../general/misc/PieceSpacer";
import { scaledPosition } from "../../../../helpers/cssHelper";
import { ShoePieceAdder } from "./ShoePieceAdder";

export const PieceAdders = ({ defaultOutfitPieces = null }) => {
	const [outfitState, dispatch] = useContext(AddOutfitContext);

	useEffect(() => {
		if (defaultOutfitPieces) {
			dispatch({
				type: "set_outfitPieceGrid",
				outfitPieces: defaultOutfitPieces,
			});
		}
	}, []);

	return (
		<div
			css={scaledPosition(
				"pieceAdder",
				outfitState.topScale,
				outfitState.leftScale,
				outfitState.zoom
			)}
			className="flex relative"
		>
			<div className="flex flex-col items-end">
				<PieceAdder
					key={4}
					position={4}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[4]}
					dimensions={outfitState.outfitDimensionGrid[4]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceAdder
					key={5}
					position={5}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[5]}
					dimensions={outfitState.outfitDimensionGrid[5]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceAdder
					key={6}
					position={6}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[6]}
					dimensions={outfitState.outfitDimensionGrid[6]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceSpacer noHeight={true} widthScale={40} />
			</div>
			<div className="flex flex-col items-center">
				<PieceAdder
					key={1}
					position={1}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[1]}
					dimensions={outfitState.outfitDimensionGrid[1]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceAdder
					key={2}
					position={2}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[2]}
					dimensions={outfitState.outfitDimensionGrid[2]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				{
					<PieceAdder
						key={3}
						position={3}
						positionState={outfitState.position}
						preview={outfitState.preview}
						piece={outfitState.outfitPieceGrid[3]}
						dimensions={outfitState.outfitDimensionGrid[3]}
						dispatch={dispatch}
						splitEnabled={true}
						zoom={outfitState.zoom}
					/>
				}
			</div>
			<div className="flex flex-col items-start">
				<PieceAdder
					key={7}
					position={7}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[7]}
					dimensions={outfitState.outfitDimensionGrid[7]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceAdder
					key={8}
					position={8}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[8]}
					dimensions={outfitState.outfitDimensionGrid[8]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceAdder
					key={9}
					position={9}
					positionState={outfitState.position}
					preview={outfitState.preview}
					piece={outfitState.outfitPieceGrid[9]}
					dimensions={outfitState.outfitDimensionGrid[9]}
					dispatch={dispatch}
					zoom={outfitState.zoom}
				/>
				<PieceSpacer noHeight={true} widthScale={40} />
			</div>
		</div>
	);
};
