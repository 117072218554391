import { useState, useContext } from "react";
import { MemoizedSlider } from "./Sliders";
import { AddOutfitContext } from "../../../contexts/Outfits/AddOutfit";
import { ColorButton } from "../../general/misc/ColorButton";

export const ZoomPositionSlider = () => {
	const [state, dispatch] = useContext(AddOutfitContext);

	const setZoom = (e) => {
		const zoom = e.target.value;
		dispatch({ type: "set_zoom", zoom });
	};

	const setTopScale = (e) => {
		const top = e.target.value;
		dispatch({ type: "set_topScale", top });
	};

	const setLeftScale = (e) => {
		const left = e.target.value;
		dispatch({ type: "set_leftScale", left });
	};

	return (
		<>
			<MemoizedSlider
				onChange={setZoom}
				min={-40}
				max={40}
				value={state.zoom}
				color={"black"}
				label="Zoom"
			/>
			<MemoizedSlider
				onChange={setTopScale}
				min={-40}
				max={40}
				value={state.topScale}
				color={"black"}
				label="Top"
			/>
			<MemoizedSlider
				onChange={setLeftScale}
				min={-40}
				max={40}
				value={state.leftScale}
				color={"black"}
				label="Left"
			/>
			<div className="flex h-10 gap-2 justify-center w-full">
				<ColorButton
					className="flex-grow"
					onClick={() => dispatch({ type: "reset_zoom" })}
					label="Reset Zoom"
					type="red"
				/>
				<ColorButton
					className="flex-grow"
					onClick={() => dispatch({ type: "reset_position" })}
					label="Reset Position"
					type="red"
				/>
			</div>
		</>
	);
};
