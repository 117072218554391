import React from "react";

export const YoutubeIframe = ({
	className = "",
	src = "https://www.youtube.com/embed/JWgIFRWwAQk",
	mobileSrc = "",
}) => {
	return (
		<>
			<iframe
				className="hidden lg:block"
				width="100%"
				height="100%"
				src={src}
				title="YouTube video player"
				frameBorder="0"
				allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
			<iframe
				className="block lg:hidden"
				width="100%"
				height="100%"
				src={mobileSrc}
				title="YouTube video player"
				frameBorder="0"
				allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
		</>
	);
};
