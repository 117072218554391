import React from "react";
import { ViewerReducer, initialState } from "./ViewerReducer";

export const ViewerContext = React.createContext({
	state: initialState,
	dispatch: () => null,
});

export const ViewerProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(ViewerReducer, initialState);

	return (
		<ViewerContext.Provider value={[state, dispatch]}>
			{children}
		</ViewerContext.Provider>
	);
};
