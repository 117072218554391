import React from "react";
import { Container } from "../components/general/universal/Container";
import { ClothingAnalyticsComponent } from "../components/clothingAnalytics/ClothingAnalyticsComponent";

const ClothingAnalytics = () => {
	return (
		<Container>
			<ClothingAnalyticsComponent />
		</Container>
	);
};

export default ClothingAnalytics;
