export const gridTypes = {
	clothing: {
		itemsPerPage: 12,
		gridRows: {
			default: 1,
		},
		gridCols: {
			default: 2,
			sm: 2,
			md: 3,
			lg: 3,
			xl: 4,
			"2xl": 6,
		},
		gridClass:
			"grid-rows-1 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6",
	},
	clothingPicker: {
		itemsPerPage: 9,
		gridRows: {
			default: 3,
		},
		gridCols: {
			default: 3,
		},
		gridClass: "grid-rows-3 grid-cols-3",
	},
	outfits: {
		itemsPerPage: 12,
		gridRows: {
			default: 1,
		},
		gridCols: {
			default: 1,
			sm: 1,
			md: 2,
			lg: 3,
			xl: 3,
			"2xl": 3,
		},
		gridClass:
			"grid-rows-1 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3",
	},
	outfitPicker: {
		itemsPerPage: 4,
		gridRows: {
			default: 2,
		},
		gridCols: {
			default: 2,
		},
		gridClass: "grid-rows-2 grid-cols-2",
	},
	lookbooks: {
		itemsPerPage: 12,
		gridRows: {
			default: 1,
		},
		gridCols: {
			default: 2,
			sm: 2,
			md: 3,
			lg: 3,
			xl: 4,
			"2xl": 6,
		},
		gridClass:
			"grid-rows-1 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6",
	},
	lookbookThumbnail: {
		itemsPerPage: 4,
		gridRows: {
			default: 2,
		},
		gridCols: {
			default: 2,
		},
		gridClass: "",
	},
	clothingAnalytics: {
		itemsPerPage: 24,
		gridClass: "grid-rows-1 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3",
	},
};

export const gridClasses = (type) => {
	const gridRows = gridTypes[type].gridRows;
	const gridCols = gridTypes[type].gridCols;
	var gridClass = `grid-cols-${gridCols.default} grid-rows-${gridRows.default}`;
	Object.entries(gridCols).forEach(([key, item]) => {
		if (key == "default") {
			gridClass = `${gridClass} grid-cols-${item}`;
		} else {
			gridClass = `${gridClass} ${key}:grid-cols-${item}`;
		}
	});
	Object.entries(gridRows).forEach(([key, item]) => {
		if (key == "default") {
			gridClass = `${gridClass} grid-rows-${item}`;
		} else {
			gridClass = `${gridClass} ${key}:grid-rows-${item}`;
		}
	});
	return gridClass;
};
