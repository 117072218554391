import React, { useState, useEffect } from "react";
import { ViewOutfitComponent } from "../outfits/viewOutfits/ViewOutfitsComponent";
import { useParams } from "react-router-dom";
import { requestHandler } from "../../helpers/requests";
import { useErrorHandler } from "react-error-boundary";
import { ViewClothingComponent } from "../clothing/ViewClothingComponent";
import { ClothingCard } from "../clothing/ClothingCard";
import { AdderCard } from "./AdderCard";
import { ConfirmationModal } from "../general/misc/ConfirmationModal";
import { IoCloseSharp } from "react-icons/io5";

export const PiecesComponent = ({
	pieceIds = [],
	handleOverlay = () => null,
	onCardClick = () => null,
}) => {
	const [error, setError] = useState(null);
	useErrorHandler(error);
	const [pieces, setPieces] = useState([]);
	useEffect(() => {
		const formData = new FormData();
		formData.append("pieceIds", JSON.stringify(pieceIds));
		requestHandler.getPiecesByIds(formData).then((response) => {
			if (response.ok) {
				response.json().then((data) => {
					setPieces(data.pieces);
				});
			} else {
				setError(response);
			}
		});
	}, [pieceIds]);

	const [modal, setModal] = useState(false);
	const [removalPiece, setRemovalPiece] = useState(null);

	const HoverComponent = () => {
		return (
			<div className="absolute z-10 flex w-full h-full items-center justify-center opacity-0 sm:group-hover:opacity-100 transition-opacity duration-75 bg-red-600 bg-opacity-30">
				<IoCloseSharp className="text-red-600" size={32} />
			</div>
		);
	};

	const widthClass = "w-1/4 md:w-1/5 2xl:w-1/8";
	return (
		<>
			<ConfirmationModal
				altText="Are you sure you want to remove this piece from the filter?"
				modalState={modal}
				setModalState={setModal}
				confirmFunc={() => onCardClick(removalPiece)}
			/>
			<div className="w-full flex flex-wrap justify-center mb-4">
				{pieces.map((piece) => (
					<ClothingCard
						onCardClick={(piece) => {
							setModal(true);
							setRemovalPiece(piece);
						}}
						key={piece.id}
						className={`${widthClass} sm:hover:border-red-600`}
						piece={piece}
						hoverOverlay={<HoverComponent piece={piece} />}
					/>
				))}
				<AdderCard onClick={handleOverlay} className={widthClass} />
			</div>
		</>
	);
};
