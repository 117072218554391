import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ViewClothing from "./pages/ViewClothing";
import ViewOutfits from "./pages/ViewOutfits";
import ViewLookbooks from "./pages/ViewLookbooks";
import AddOutfit from "./pages/AddOutfit";
import EditClothing from "./pages/EditClothing";
import ViewIndividualOutfit from "./pages/ViewIndividualOutfit";
import AddLookbook from "./pages/AddLookbook";
import ViewLookbook from "./pages/ViewLookbook";
import AccountEdit from "./pages/AccountEdit";
import ViewOutfitsByClothing from "./pages/ViewOutfitsByClothing";
import Privacy from "./pages/Privacy";
import NewUserTutorial from "./pages/NewUserTutorial";
import Admin from "./pages/Admin";
import ClothingAnalytics from "./pages/ClothingAnalytics";
import AddWears from "./pages/AddWears";

// Define different route list based on auth level
// TODO: more user roles to be added

// Accessible by any roles
export const NonAuthRoutes = [
	{
		path: "/home",
		element: <Home />,
		exact: true,
	},
	{
		path: "/login",
		element: <Login />,
		exact: true,
	},
	{
		path: "/signup",
		element: <Signup />,
		exact: true,
	},
	{
		path: "/privacy",
		element: <Privacy />,
		exact: true,
	},
	// Add new here...
];

export const ProtectedRoutes = [
	//Examples for routes that need authorization
	//To be replaced later
	{
		path: "/clothing",
		element: <ViewClothing />,
		exact: true,
	},
	{
		path: "/add",
		element: <EditClothing />,
		exact: true,
	},
	{
		path: "/outfits",
		element: <ViewOutfits />,
		exact: true,
	},
	{
		path: "/lookbooks",
		element: <ViewLookbooks />,
		exact: true,
	},
	{
		path: "/addOutfit",
		element: <AddOutfit />,
		exact: true,
	},
	{
		path: "/editOutfit/:outfitId",
		element: <AddOutfit />,
		exact: true,
	},
	{
		path: "/viewOutfit/:outfitId",
		element: <ViewIndividualOutfit />,
		exact: true,
	},
	{
		path: "/outfitsByClothing/:pieceId",
		element: <ViewOutfitsByClothing />,
		exact: true,
	},
	{
		path: "/outfitsByClothing/",
		element: <ViewOutfitsByClothing />,
		exact: true,
	},
	{
		path: "/editClothing/:clothingId",
		element: <EditClothing />,
		exact: true,
	},
	{
		path: "/addLookbook",
		element: <AddLookbook />,
		exact: true,
	},
	{
		path: "/lookbook/:lookbookId",
		element: <ViewLookbook />,
		exact: true,
	},
	{
		path: "/editLookbook/:lookbookId",
		element: <AddLookbook />,
		exact: true,
	},
	{
		path: "/account",
		element: <AccountEdit />,
		exact: true,
	},
	{
		path: "/welcome",
		element: <NewUserTutorial />,
		exact: true,
	},
	{
		path: "/admin",
		element: <Admin />,
		exact: true,
	},
	{
		path: "/analytics",
		element: <ClothingAnalytics />,
		exact: true,
	},
	{
		path: "/addWears",
		element: <AddWears />,
		exact: true,
	},
];
