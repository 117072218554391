import React from "react";
import { Outfit } from "../Outfit";
import { ClothingCard } from "../../clothing/ClothingCard";

export const IndividualOutfitPieceGrid = ({ Outfit = null }) => {
	return !Outfit ? (
		""
	) : (
		<div className="flex h-full w-full">
			<div className="flex w-full">
				<div
					className={`rounded-md justify-center w-full grid grid-cols-2 grid-rows-2
        sm:grid-cols-2 
        md:grid-cols-3 
        lg:grid-cols-3 
        xl:grid-cols-3 `}
				>
					{Outfit.OutfitPieces.map((OutfitPiece) => {
						if (OutfitPiece.Clothing) {
							return (
								<ClothingCard
									key={OutfitPiece.Clothing.id}
									piece={OutfitPiece.Clothing}
								/>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};
