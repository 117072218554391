import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ColorButton } from "../general/misc/ColorButton";
import { Cards } from "../home/Cards";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { TutorialComponent } from "./TutorialComponent";

export const NewUserTutorialComponent = () => {
	const [outfitModal, setOutfitModal] = useState(false);
	const [clothingModal, setClothingModal] = useState(false);
	const [lookbookModal, setLookbookModal] = useState(false);

	const [outfitComplete, setOutfitComplete] = useState(false);
	const [clothingComplete, setClothingComplete] = useState(false);
	const [lookbookComplete, setLookbookComplete] = useState(false);

	const TutorialCompletedOverlay = () => {
		return (
			<div className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-blue-700 bg-opacity-40">
				<span className="text-blue-700">
					<IoCheckmarkCircleSharp size={64} />
				</span>
			</div>
		);
	};

	const navigate = useNavigate();
	return (
		<div className="w-full h-full select-none flex flex-col flex-grow items-center mb-16 sm:mb-0">
			<TutorialComponent
				parentState={clothingModal}
				setParentState={setClothingModal}
				type="clothing"
			/>
			<TutorialComponent
				parentState={outfitModal}
				setParentState={setOutfitModal}
				type="outfits"
			/>
			<TutorialComponent
				parentState={lookbookModal}
				setParentState={setLookbookModal}
				type="lookbooks"
			/>

			<div className="font-bold text-4xl">Welcome to OutfitOrg!</div>

			<div className="flex flex-col font-light px-4 text-center my-8">
				<span>
					We notice you're new, so we've provided you with tutorials
					for the main functions of the app!
				</span>
				<span>
					If you don't want to read them now, don't worry, they're
					available in the app as well.
				</span>
			</div>

			<Cards
				clothingCaption={
					"Click this card to learn how to add clothing!"
				}
				outfitsCaption={"Click this card to learn how to add outfits!"}
				lookbooksCaption={
					"Click this card to learn how to add lookbooks!"
				}
				onClothingClick={() => {
					setClothingComplete(true);
					setClothingModal(true);
				}}
				onOutfitsClick={() => {
					setOutfitComplete(true);
					setOutfitModal(true);
				}}
				onLookbooksClick={() => {
					setLookbookComplete(true);
					setLookbookModal(true);
				}}
				cardOverlay={<TutorialCompletedOverlay />}
				outfitOverlay={outfitComplete}
				clothingOverlay={clothingComplete}
				lookbookOverlay={lookbookComplete}
				cardClassModifier="cursor-pointer"
			/>
			<div>
				{clothingComplete && outfitComplete && lookbookComplete ? (
					<div className="flex flex-col">
						<span className="font-light text-base mb-4">
							You've read all the tutorials!
						</span>
						<ColorButton
							className="w-auto text-base"
							type="blue"
							label="Get Started"
							onClick={() => navigate("/clothing")}
						/>
					</div>
				) : (
					<div>
						<ColorButton
							className="text-base"
							type="blue"
							label="Skip Tutorials"
							onClick={() => navigate("/clothing")}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
