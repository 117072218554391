import React, { useState, useEffect } from "react";
import "./Spinner.css";
import { CircularProgress, LinearProgress } from "@mui/material";

export const Spinner = ({ type = "absolute" }) => {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const id = setTimeout(() => {
			setLoading(true);
		}, 1000);
		return function cleanup() {
			clearTimeout(id);
		};
	}, []);
	return (
		<div
			className={`${type} top-0 left-0 flex items-center justify-center h-full w-full`}
		>
			{loading && <CircularProgress sx={{ color: "black" }} />}
			{/*<svg
        className="spinner"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          className="spinner-segment"
          d="M332.61,211.189a9.984,9.984,0,0,1,2.586-.35v-2a11.439,11.439,0,0,0-1.394.086c-.166.019-.328.049-.492.075-.337.054-.673.119-1,.2l-.212.055Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.66 }}
        ></path>
        <path
          className="spinner-segment"
          d="M330.2,212.186a9.925,9.925,0,0,1,2.41-1l-.517-1.93c-.048.013-.1.024-.144.037a11.43,11.43,0,0,0-1.156.388c-.077.03-.158.054-.234.086a11.446,11.446,0,0,0-1.265.629c-.031.018-.061.038-.092.056Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.6 }}
        ></path>
        <path
          className="spinner-segment"
          d="M330.2,212.185l-1-1.731c-.062.036-.122.075-.184.112-.3.181-.6.378-.883.586-.134.1-.269.2-.4.3a11.44,11.44,0,0,0-1.023.9l1.414,1.414A10.038,10.038,0,0,1,330.2,212.185Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.54 }}
        ></path>
        <path
          className="spinner-segment"
          d="M326.542,215.843a10.034,10.034,0,0,1,1.583-2.075l-1.414-1.414,0,0a11.43,11.43,0,0,0-.9,1.015c-.107.135-.208.274-.309.413-.2.28-.4.567-.573.864-.039.065-.08.129-.118.195Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.48 }}
        ></path>
        <path
          className="spinner-segment"
          d="M325.546,218.254a9.922,9.922,0,0,1,1-2.41l-1.731-1c-.019.032-.04.063-.058.1a11.448,11.448,0,0,0-.625,1.257c-.036.085-.062.175-.1.261a11.444,11.444,0,0,0-.378,1.125c-.014.051-.026.1-.04.154Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.42 }}
        ></path>
        <path
          className="spinner-segment"
          d="M323.615,217.737q-.031.115-.059.23c-.08.319-.142.642-.195.966-.028.174-.06.346-.08.523a11.434,11.434,0,0,0-.086,1.383h2a9.984,9.984,0,0,1,.35-2.585Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.36 }}
        ></path>
        <path
          className="spinner-segment"
          d="M325.546,223.425a9.984,9.984,0,0,1-.35-2.586h-2a11.437,11.437,0,0,0,.086,1.395c.019.165.049.327.075.49.054.338.119.674.2,1.006q.026.106.055.212Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.3 }}
        ></path>
        <path
          className="spinner-segment"
          d="M326.542,225.836a9.925,9.925,0,0,1-1-2.41l-1.93.517c.013.048.024.1.037.144a11.442,11.442,0,0,0,.389,1.157c.03.077.053.157.085.233a11.443,11.443,0,0,0,.629,1.266c.018.031.038.061.056.092Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.24 }}
        ></path>
        <path
          className="spinner-segment"
          d="M328.125,227.91a10.036,10.036,0,0,1-1.583-2.075l-1.731,1c.036.062.075.122.112.184.181.3.378.6.586.883.1.134.2.269.3.4a11.43,11.43,0,0,0,.9,1.023Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.18 }}
        ></path>
        <path
          className="spinner-segment"
          d="M330.2,229.492a10.034,10.034,0,0,1-2.075-1.583l-1.414,1.414,0,0a11.428,11.428,0,0,0,1.016.9c.134.107.274.208.413.309.28.2.566.4.864.573.065.039.129.08.2.118Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.12 }}
        ></path>
        <path
          className="spinner-segment"
          d="M332.61,230.489a9.925,9.925,0,0,1-2.41-1l-1,1.731c.032.019.063.04.1.058a11.449,11.449,0,0,0,1.257.625c.085.036.175.062.261.1a11.444,11.444,0,0,0,1.125.378c.051.014.1.026.154.04Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.06 }}
        ></path>
        <path
          className="spinner-segment"
          d="M335.2,230.839a9.984,9.984,0,0,1-2.585-.35l-.517,1.93.23.059c.319.08.642.142.966.195.174.028.346.06.523.08a11.434,11.434,0,0,0,1.383.086Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M337.781,230.489a9.983,9.983,0,0,1-2.585.35v2a11.436,11.436,0,0,0,1.395-.086c.165-.019.327-.049.49-.075.338-.054.674-.119,1.006-.2l.212-.054Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M340.192,229.492a9.926,9.926,0,0,1-2.41,1l.517,1.93c.048-.013.1-.024.144-.037a11.438,11.438,0,0,0,1.157-.388c.077-.03.157-.054.234-.086a11.446,11.446,0,0,0,1.266-.629c.031-.018.061-.038.092-.056Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M340.192,229.493l1,1.731c.062-.036.122-.074.183-.111.3-.181.6-.378.884-.586.134-.1.269-.195.4-.3a11.437,11.437,0,0,0,1.024-.9l-1.414-1.414A10.034,10.034,0,0,1,340.192,229.493Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M343.849,225.836a10.034,10.034,0,0,1-1.583,2.075l1.414,1.414h0a11.434,11.434,0,0,0,.9-1.018c.107-.134.207-.274.309-.413.2-.28.4-.567.574-.864.039-.065.08-.129.118-.195Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M344.846,223.425a9.922,9.922,0,0,1-1,2.41l1.731,1c.019-.032.04-.063.058-.1a11.442,11.442,0,0,0,.625-1.257c.036-.085.061-.174.1-.26a11.442,11.442,0,0,0,.378-1.126c.014-.051.026-.1.04-.154Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M345.2,220.839a9.982,9.982,0,0,1-.35,2.585l1.93.517q.03-.114.059-.229c.081-.32.143-.643.2-.967.028-.174.059-.346.08-.522a11.436,11.436,0,0,0,.086-1.383Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0 }}
        ></path>
        <path
          className="spinner-segment"
          d="M347.108,219.444c-.019-.165-.049-.327-.075-.49-.054-.338-.119-.674-.2-1.006q-.026-.106-.055-.212l-1.93.517a9.984,9.984,0,0,1,.35,2.586h2A11.437,11.437,0,0,0,347.108,219.444Z"
          transform="translate(-323.196 -208.839)"
        ></path>
        <path
          className="spinner-segment"
          d="M343.849,215.843a9.925,9.925,0,0,1,1,2.41l1.93-.517c-.013-.048-.024-.1-.037-.144a11.436,11.436,0,0,0-.388-1.155c-.031-.078-.054-.159-.086-.236a11.436,11.436,0,0,0-.629-1.265c-.018-.031-.038-.061-.056-.092Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.96 }}
        ></path>
        <path
          className="spinner-segment"
          d="M342.267,213.768a10.034,10.034,0,0,1,1.583,2.075l1.731-1c-.036-.062-.075-.122-.112-.184-.181-.3-.378-.6-.586-.884-.1-.134-.195-.269-.3-.4a11.43,11.43,0,0,0-.9-1.024Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.9 }}
        ></path>
        <path
          className="spinner-segment"
          d="M340.192,212.186a10.035,10.035,0,0,1,2.075,1.583l1.414-1.414h0a11.443,11.443,0,0,0-1.018-.9c-.134-.107-.274-.207-.413-.309-.28-.2-.567-.4-.864-.573-.065-.039-.129-.08-.195-.118Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.84 }}
        ></path>
        <path
          className="spinner-segment"
          d="M337.781,211.189a9.928,9.928,0,0,1,2.41,1l1-1.731c-.032-.019-.063-.04-.1-.058a11.449,11.449,0,0,0-1.257-.625c-.085-.036-.175-.062-.261-.1a11.457,11.457,0,0,0-1.125-.378c-.051-.014-.1-.026-.154-.04Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.78 }}
        ></path>
        <path
          className="spinner-segment"
          d="M335.2,210.839a9.984,9.984,0,0,1,2.585.35l.517-1.93-.23-.059c-.319-.08-.641-.142-.965-.195-.174-.028-.347-.06-.524-.08a11.436,11.436,0,0,0-1.383-.086Z"
          transform="translate(-323.196 -208.839)"
          style={{ opacity: 0.72 }}
        ></path>
      </svg>
  */}
		</div>
	);
};
